.order-menu{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 150px;
        flex: 1 1 150px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    margin-bottom: 65px;
}

.order-menu-item{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 3 1 150px;
        flex: 3 1 150px;
    -ms-flex-direction: column;
        flex-direction: column;
}

.order-menu-item-extreme{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 150px;
        flex: 1 1 150px;
    -ms-flex-direction: column;
        flex-direction: column;
}

.order-menu-title{
    padding-top: 2px;
    font-size: 18px;
    line-height: 24px;
    color: #333;
}

.order-menu-title.second-level-title{
    font-size: 14px;
    color: #8D98A6;
}

.order-menu-item .order-menu-title{
    text-align: center;
}

.order-menu-item-extreme:last-child .order-menu-title{
    text-align: end;
}

.order-menu-top{
    display: -ms-flexbox;
    display: flex;
}

.round {
    width: 22px;
    height: 22px;
    margin-top: 7px;
    background-image: url("../../assets/img/orders/marker-active.svg");
    background-repeat: no-repeat;
}

.document-modal > label {
    margin-left:auto;
}

.order-menu-item.disabled .round, .order-menu-item-extreme.disabled .round{
    background-image: url("../../assets/img/orders/marker-disabled.svg");
}

.accordion-icon-container.disabled .round {
    background-image: url("../../assets/img/orders/marker-disabled.svg");
}

.order-menu-item-left{
    -ms-flex-positive: 1;
        flex-grow: 1;
    padding-right: 2px;
    padding-left: 1px;
}

.order-menu-item-right{
    -ms-flex-positive: 1;
        flex-grow: 1;
    padding-left: 2px;
    padding-right: 1px;
}

.order-menu hr {
    border: 0;
    height: 2px;
    border-top: 3px dashed #62CFA8;
}

.order-menu-item.disabled hr, .order-menu-item-extreme.disabled hr{
    border-top: 3px dashed #8D98A6;
}

.cargo-point {
    width: 18px;
    height: 18px;
    margin-top: 7px;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba(98,202,168,0.2);
}

.cargo-point-active{
    width: 8px;
    height: 8px;
    position: relative;
    left: 5px;
    top: 5px;
    border-radius: 50%;
    overflow: hidden;
    background:  #62CFA8;
}

/* аккордион */
.accordion-row{
    display: -ms-flexbox;
    display: flex;
}

.accordion-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;

    margin-left: 13px;
    min-height: 72px;
    margin-bottom: 8px;

    padding: 14px 30px 17px;
    border: 1px solid #D3D8E0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 3px;
}

.accordion-header{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 94%;
        flex: 1 1 94%;
    -ms-flex-direction: column;
        flex-direction: column;
}

.accordion-item-action{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 1 40px;
        flex: 0 1 40px;
    -ms-flex-align: center;
        align-items: center;
}


.header-terms{
    font-size: 14px;
    line-height: 24px;
    color: #8D98A6;
}

.header-terms .elapsed{
    color: red;
}

.accordion-header > div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
}

.accordion-item-action .toggle{
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
    background: rgba(211, 216, 224, 0.4);
    border-radius: 40px;
    position: relative;
}

.accordion-item-action .toggle .arrow{
    width: 24px;
    height: 24px;
    background-image: url("../../../src/assets/img/arrow.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 9px;
    left: 6px;
}

.accordion-item-action .toggle .arrow.open{
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    top: -9px;
    left: -6px;
}

.order-navigation .toggle{
    display:none;
}

.accordion-icon-container{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
}

.icon-line-top{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 1 20px;
        flex: 0 1 20px;
    width: 1px;
    background-color: #D3D8E0;
}

.icon-line-bottom{
    width: 1px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 20px;
        flex: 1 1 20px;
    background-color: #D3D8E0;
}

.icon-line-top.transparent, .icon-line-bottom.transparent{
    background-color: transparent;
}

.icon-line-top.active, .icon-line-bottom.active{
    background-color: #62CFA8;
}

.accordion-icon-container .accordion-icon{
    width: 40px;
    height: 40px;
    background-color: #D3D8E0;
    position: relative;
}

.accordion-icon-container .accordion-icon.active{
    background-color: #62CFA8;
    position: relative;
}

.transportation-icon{
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    position: absolute;
}

.transportation-type-vehicle{
    background-image: url("../../../src/assets/img/transportation/vehicleLogo.svg");
}

.transportation-type-railway{
    background-image: url("../../../src/assets/img/transportation/railway.svg");
}


.transportation-type-air{
    background-image: url("../../../src/assets/img/transportation/air.svg");
}

.transportation-type-sea{
    background-image: url("../../assets/img/transportation/sea.svg");
}

.transportation-type-final{
    background-image: url("../../assets/img/transportation/done.svg");
    top: 15px;
    left: 15px;
}

/*  */
.accordion-row-content{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-right: 26px;
    margin-top: 17px;
    mix-height: 120px;
}

.step-item{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 100%;
        flex: 1 0 100%;
}

.step-item{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    -ms-flex-direction: column;
        flex-direction: column;
    margin-top: 30px;
    position: relative;
}

.step-point{
    position: absolute;
    left: -68px;
    top: 12px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background:  #D3D8E0;
}

.step-point.active{
    background:  #62CFA8;
}

.order-details-table .total-row{
    font-weight: bold;
    text-transform: uppercase;
}

.order-details-table th{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #8D98A6;
}

.order-details-table td{
    font-size: 14px;
    line-height: 24px;
    color: #333;
}

.order-details-table{
    width: 100%;
}

.order-details-table table{
    width: 100%;
}

.order-details-table table:not(:last-child) {
    margin-bottom: 45px;
}

.order-details-table th, .order-details-table td {
    padding-left: 12px;
}

.order-details-table tr:nth-child(even), .order-details-table thead tr{
    background: rgba(221, 216, 224, 0.2);
}

.order-details-table tr{
    height: 40px;
}

.accordion-row-content .order-details-table:not(:first-child) {
    margin-top: 45px;
}

/*  */
.order-card-panel{
    background-color: #FFF;
    width: 100%;
    padding: 46px 48px;
    margin-bottom: 16px;
}

.order-card-panel.relative-panel{
    position: relative;
 }

.order-card-panel > h2{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #333;
    margin-bottom: 28px;
}

.order-main-info p{
    font-size: 14px;
    line-height: 24px;
    color: #333;
    margin: 0;
}

.order-additional-blocks {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 6 1 300px;
        flex: 6 1 300px;
}

.order-main-info h5{
    font-size: 14px;
    line-height: 24px;
    color: #8D98A6;
    margin: 0;
}

.order-customer-info {
    margin-bottom: 12px;
}

.order-grid {
    cursor:pointer;
}

.order-main-info > div:not(.order-main-info-comment){
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.order-main-info .order-cargo-info{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 6 1 300px;
        flex: 6 1 300px;
}

.order-main-info .order-cargo-info > div{
    display: -ms-flexbox;
    display: flex;
}

.order-main-info .order-cargo-info > div > div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.order-main-info .pricing{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 4 1 300px;
        flex: 4 1 300px;
    -ms-flex-direction: column;
        flex-direction: column;
}

.order-main-info .pricing .price, .order-customer-info {
    display: -ms-flexbox;
    display: flex;
}


.order-customer-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 6 1 300px;
        flex: 6 1 300px;
}

.order-main-info-dates-column {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 4 1 300px;
        flex: 4 1 300px;
}

.order-customer-info a{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #4996FF;
}

.order-main-info .pricing .price > div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 50%;
        flex: 1 1 50%;
}

.path-point-marker {
    width: 24px;
    height: 40px;
    margin-right: 20px;
    background-image: url("../../assets/img/orders/pathPointMarker.svg");
    background-repeat: no-repeat;
}

.order-cargo-strong{
    font-size: 18px;
    line-height: 24px;
    color: #333;
    margin-bottom: 25px;
}

.order-cargo-title{
    font-size: 14px;
    line-height: 24px;
    color: #8D98A6;
}

.order-price-icon{
    width: 24px;
    height: 24px;
    margin-top: 5px;
    margin-right: 11px;
    background-image: url("../../assets/img/orders/order-price-icon.svg");
    background-repeat: no-repeat;
}

.order-user-icon{
    width: 24px;
    height: 24px;
    margin-top: 5px;
    margin-right: 11px;
    background-image: url("../../assets/img/orders/order-user-logo.svg");
    background-repeat: no-repeat;
}

.path-line-border{
    border: 1px dashed #2B34A4;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    width: 1px;
    margin-left: 11px;
}

/* Информация о грузе */
.oc-cargo-info-container{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.oc-cargo-info-container > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 300px;
        flex: 1 0 300px;
    margin-bottom: 23px;
}

.oc-cargo-info-container > div > table {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 24px;
    color: #333;
}

.oc-cargo-info-container > div > table th, .oc-cargo-info-container > div > table td{
    width: 50%;
}

.oc-cargo-info-container > div > table th{
    vertical-align: top;
}

.cargo-type-package-logo{
    width: 20px;
    height: 9px;
    margin-right: 15px;
    background-image: url("../../assets/img/orders/cargoType_package.svg");
    background-repeat: no-repeat;
    display: inline-block;
}

.cargo-type-box-logo{
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-bottom: -2px;
    background-image: url("../../assets/img/icons/icon-container.svg");
    background-repeat: no-repeat;
    display: inline-block;
}

/* Информация о заказе */
.order-cargo-info-table, .notifiable-person-table, .order-doc-table{
    width: 100%;
}

.order-cargo-info-table td{
    width: 33%;
}

.order-cargo-info-table {
    margin-bottom: 26px;
}

.striped-table tbody tr:nth-child(odd){
    background: rgba(221, 216, 224, 0.2);
}

.striped-table-with-head tr:nth-child(even), .striped-table-with-head th{
    background: rgba(221, 216, 224, 0.2);
}

.striped-table tr td, .striped-table-with-head tr td, .striped-table-with-head tr th{
    height: 50px;
    padding-left: 27px;
    padding-right: 27px;
}

.striped-table thead tr th{
    padding-left: 27px;
}

.pale-font{
    color: #8D98A6;
}

.notifiable-person-table td:nth-child(2){
    width: 66%;
}

.order-card-panel .order-card-info hr{
    margin: 40px 0;
}

.order-modal-buttons {
    margin-top: 16px;
}

.mobile-card > .agora-actions-btn {
    float:right;
}

.mobile-doc-actions {
    float:right;
}

.order-modal-buttons :last-child {
    float: right;
}

.order-card-panel .btns button, .btn-with-logo{
    margin-right: 16px;
    text-transform: uppercase;
    padding: 8px 24px;
    margin-top:16px;
    margin-left:auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #4996FF;
    border: 1px solid rgba(73, 150, 255, 0.5);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 3px;
    position: relative;
}

.order-card-panel .btns button.primary-btn{
    background-color: #4996FF;
    color: #FFF;
}

.btn-add-logo{
    display: inline-block;
    font-size: 24px;
    margin-right: 24px;
    position: absolute;
    top: 8px;
    left: 24px;
}

.order-card-panel .btns .btn-with-logo{
    padding-left: 60px;
    background-color: #FFF;
}

.notification-person-header{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    -ms-flex-pack: justify;
        justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 18px;

    font-size: 18px;
    line-height: 24px;
    color: #333;
}

.notification-person-header > div, .notification-person-header > div > div{
    display: -ms-flexbox;
    display: flex;
}

.notification-person-header > div > div{
    margin-left: 32px;
    font-size: 11px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.delete-btn-logo, .edit-btn-logo{
    width: 22px;
    height: 24px;
    margin-right: 20px;
    margin-top: -3px;
    background-repeat: no-repeat;
    display: inline-block;
}

.delete-btn-logo{
    background-image: url("../../assets/img/orders/delete-btn-logo.svg");
}

.edit-btn-logo{
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
}

.delete-btn-logo:hover, .edit-btn-logo:hover{
    cursor: pointer;
}

.delete-btn-logo:hover{
    background-image: url("../../assets/img/orders/delete-btn-logo-hover.svg");
}

.edit-btn-logo:hover{
    background-image: url("../../assets/img/orders/edit-btn-logo-hover.svg");
}

.edit-order-icon{
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
}

.edit-order-icon:hover{
    background-image: url("../../assets/img/orders/edit-btn-logo-hover.svg");
}

/* Документы */
.doc-amount-header{
    position: absolute;
    top: 47px;
    right: 48px;
    font-size: 14px;
    line-height: 24px;
}

.order-doc-table{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
}

.clip-icon{
    background-image: url("../../assets/img/orders/clipIcon.svg");
    width: 20px;
    height: 16px;
    margin-top: 7px;
    background-repeat: no-repeat;
    display: inline-block;
}

.order-doc-table th:first-child, .order-doc-table td:first-child{
    width: 40px;
    padding-left: 20px;
    padding-right: 0;
}

.agora-actions{
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    width: 210px;
    -ms-flex-direction: column;
        flex-direction: column;

    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #333;

    background: #FFF;
    -webkit-box-shadow: 0px 8px 40px rgba(51, 51, 51, 0.1);
            box-shadow: 0px 8px 40px rgba(51, 51, 51, 0.1);
    border-radius: 3px;

    position: absolute;
    right: 20px;
    z-index: 100;
}

.order-m-card .agora-actions, .minicard-actions .agora-actions{
    right: -80px;
}

.order-m-card :first-child{
    margin-right: 10px;
}

.agora-actions.with-toggle{
    width: 300px;
    z-index: 100;
}

.agora-actions.with-toggle button{
    margin-right: 0;
    margin-left: 12px;
}

.agora-actions > div, .agora-actions > label > div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    -ms-flex-align: center;
        align-items: center;
}

.agora-actions > div:not(:last-child){
    margin-bottom: 8px;
}

.agora-actions > div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    cursor: pointer;
}

.agora-actions .agora-action-logo{
    width: 23px;
    height: 20px;
    margin-right: 12px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.agora-actions-btn{
    position: relative;
    cursor: pointer;
}

.order-docs-upload {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url("../../assets/img/orders/icon_upload.svg");
}

.agora-actions-btn_icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url("../../assets/img/orders/order-actions.svg");
}

.agora-actions-btn_icon:hover {
    background-image: url("../../assets/img/orders/order-actions-hover.svg");
}

.agora-action-logo-download{
    background-image: url("../../assets/img/orders/downloadLogo.svg");
    margin-left: 4px;
}

.agora-action-logo-upload{
    background-image: url("../../assets/img/orders/uploadLogo.svg");
}

.agora-action-logo-send-email{
    background-image: url("../../assets/img/orders/mailLogo.svg");
}

.agora-action-logo-edit , .agora-action-logo-editLogistic{
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
}

.agora-action-logo-rename{
    background-image: url("../../assets/img/orders/rename-btn-logo.svg");
}

.agora-action-logo-edit:hover, .agora-action-logo-editLogistic:hover, .agora-action-logo-hover-edit{
    background-image: url("../../assets/img/orders/edit-btn-logo-hover.svg");
}

.agora-action-logo-remove{
    background-image: url("../../assets/img/orders/delete-btn-logo.svg");
    margin-left: 4px;
}

.agora-action-logo-details{
    background-image: url("../../assets/img/orders/details-btn-logo.svg");
}

.agora-action-logo-details:hover, .agora-action-logo-hover-details {
    background-image: url("../../assets/img/orders/details-btn-logo-hover.svg");
}

.agora-action-logo-subscribe{
    background-image: url("../../assets/img/orders/unsubscribedOrderLogo.svg");
}

.agora-action-logo-subscribe:hover, .agora-action-logo-hover-subscribe{
    background-image: url("../../assets/img/orders/unsubscribedOrderLogo-hover.svg");
}
.agora-action-logo-editOwner{
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
}

.agora-action-logo-moveToCalculation{
    background-image: url("../../assets/img/orders/details-btn-logo.svg");
}

.agora-action-logo-moveToCalculation:hover, .agora-action-logo-hover-moveToCalculation {
    background-image: url("../../assets/img/orders/details-btn-logo-hover.svg");
}

/*------ status actions --------------*/


.order-m-card .agora-actions.agora-status-action,
.minicard-actions .agora-actions.agora-status-action {
    right: revert;
}

.agora-status-action-item {
    padding: .25em;
}

.agora-status-action-item:hover {
    background-color: rgba(128,128,128,.3);
}

.agora-actions .agora-action-logo{
    width: 23px;
    height: 20px;
    margin-right: 12px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.agora-action-status-new {
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
 }
.agora-action-status-hover-new {
    background-image: url("../../assets/img/orders/edit-btn-logo-hover.svg");
 }

.agora-action-status-in_work {
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
 }
.agora-action-status-hover-in_work {
    background-image: url("../../assets/img/orders/edit-btn-logo-hover.svg");
 }

.agora-action-status-closed {
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
 }
.agora-action-status-hover-closed {
    background-image: url("../../assets/img/orders/edit-btn-logo-hover.svg");
 }

.agora-action-status-canceled {
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
 }
.agora-action-status-hover-canceled {

 }

.agora-action-status-standby {
    background-image: url("../../assets/img/orders/edit-btn-logo.svg");
}
.agora-action-status-hover-standby {
    background-image: url("../../assets/img/orders/edit-btn-logo-hover.svg");
}

/* мини-карточка заказа */
.order-m-card{
    width: 100%;
    padding: 20px 32px 32px;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}

.order-m-card-container{
    background-color: #FFF;
}

.order-m-card-container:nth-child(odd){
    background-color: transparent;
}

.minicard-title{
    padding-left: 30px;
    padding-top: 22px;
    font-size: 14px;
    line-height: 24px;
}

.minicard-mobile-title{
    padding-left: 12px;
    font-size: 14px;
    line-height: 24px;
}

.order-m-card > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 2 1 130px;
        flex: 2 1 130px;
    -ms-flex-direction: column;
        flex-direction: column;

    font-size: 14px;
    line-height: 24px;
}

.order-m-card-container {
    border: 1px dotted rgba(128,128,128,0);
    -webkit-transition: all ease-in 100ms;
    transition: all ease-in 100ms;
}

.order-m-card-container:hover {
    border: 1px dotted rgba(128,128,128,.5);
    -webkit-box-shadow: 0 0 2px rgba(128,128,128,.5);
            box-shadow: 0 0 2px rgba(128,128,128,.5);
}

.order-m-card .m-card-order-location,
.order-m-card-mobile .m-card-order-location{
    -ms-flex: 4 1 260px;
        flex: 4 1 260px;
}

.order-m-card > div:last-child{
    -ms-flex: 0 1 30px;
        flex: 0 1 30px;
}

.m-card-order-location > div:last-child > div:last-child{
    padding-top: 7px;
}

.cargo-status-label{
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 2px 8px;
    border-radius: 2px;
}

.status-label-in-transit{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-arrived_to_hub{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-arrived_to_departure_hub{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-arrived_to_destination_hub{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-customs_clearance{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-booking-received{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-booking-confirmed{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-picked-up{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-at_destination{
    color: #33AF83;
    background: rgba(51, 175, 133, 0.2);
}

.status-label-delivered{
    color: #33AF83;
    background: rgba(51, 175, 133, 0.2);
}

.status-label-planning{
    color: rgb(241, 164, 11);
    background: rgba(241, 164, 11, 0.2);;
}

.status-label-loading_coordination{
    color: rgb(241, 164, 11);
    background: rgba(241, 164, 11, 0.2);;
}

.status-label-waiting_cargo{
    color: rgb(241, 164, 11);
    background: rgba(241, 164, 11, 0.2);;
}

.status-label-collected_from_shipper{
    color: rgb(241, 164, 11);
    background: rgba(241, 164, 11, 0.2);;
}

.status-label-cargo_sent{
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-bill{
    color: #F5541D;
    background: rgba(245, 84, 29, 0.2);
}

.status-label-awaiting_payment {
    color: rgb(241, 164, 11);
    background: rgba(241, 164, 11, 0.2);;
}

.status-label-arrived_to_transit_hub {
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);
}

.status-label-completed {
    color: #33AF83;
    background: rgba(51, 175, 133, 0.2);
}

.status-label-archived {
    color: rgb(64, 64, 64);
    background: rgba(64, 64, 64, 0.2);;
}


.status-label-{
    color: rgb(241, 164, 11);
    background: rgba(241, 164, 11, 0.2);;
}



.status-label-cancelled{
    color: #F5541D;
    background: rgba(245, 84, 29, 0.2);
}


.status-label-not-delivered{
    color: #F5541D;
    background: rgba(245, 84, 29, 0.2);
}

/*-----------------------------*/



.status-label {
    padding: .5em;
    line-height: 1;
}

@media (max-width: 768px) {
    .status-label{
        padding: 1em;
    }
}

.status-label-new {
    color: rgb(241, 164, 11);
    background: rgba(241, 164, 11, 0.2);;

}

.status-label-confirmed {
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);

}

.status-label-in_transit {
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);

}

.status-label-picked_up {
    color: #4996FF;
    background: rgba(73, 150, 255, 0.2);

}

.status-label-delivered {
    color: #33AF83;
    background: rgba(51, 175, 133, 0.2);
}

.status-label-canceled {
}

.status-label-standby{
    color: #F5541D;
    background: rgba(245, 84, 29, 0.2);
}


/*-----------------------------*/



.minicard-right-item{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: end;
        align-self: flex-end;
    position: relative;
}

.minicard-actions{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-right: 24px;
}

.order-subscribed{
    width: 18px;
    height: 20px;
    background-image: url("../../assets/img/orders/subscribedOrderLogo.svg");
    background-repeat: no-repeat;
    cursor: pointer;
}

.order-without-subscribe{
    display: none;
    width: 24px;
    height: 24px;
    background-image: url("../../assets/img/orders/unsubscribedOrderLogo.svg");
    background-repeat: no-repeat;
    cursor: pointer;
}

.m-card-order-location .main-title{
    color: #333;
    font-size: 24px;
    line-height: 24px;
}

.m-card-order-location .main-title-pale-font{
    color: #8D98A6;
    font-size: 24px;
    line-height: 24px;
}

.m-card-order-location .subtitle{
    font-size: 14px;
    line-height: 24px;
    color: #8D98A6;
}

.location-title-first{
    margin-bottom: 7px;
}

.card-path-point-marker{
    width: 24px;
    height: 40px;
    margin-right: 20px;
    background-image: url("../../assets/img/orders/cardPathPointMarkerActive.svg");
    background-repeat: no-repeat;
}

.card-path-point-marker.disabled{
    background-image: url("../../assets/img/orders/cardPathPointMarkerDisabled.svg");
}

.card-path-point-marker.not-delivered{
    background-image: url("../../assets/img/orders/cardPathPointMarkerNotDelivered.svg");
}

.order-m-card .m-card-order-location > div,
.order-m-card-mobile .m-card-order-location > div {
    display: -ms-flexbox;
    display: flex;
}

.order-m-card .m-card-order-location > div:first-child,
.order-m-card-mobile .m-card-order-location > div:first-child{
    min-height: 60px;
}

.order-m-card .m-card-order-location > div > div,
.order-m-card-mobile .m-card-order-location > div > div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.order-m-card .m-card-order-location > div > div:first-child {
    width: 34px;
}

.order-m-card > div:not(.m-card-order-location) > div:last-child {
    padding-top: 7px;
}

.card-path-line-border{
    border: 1px dashed #8D98A6;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    width: 1px;
    margin-left: 11px;
}

.card-path-line-border-point-to{
    -ms-flex: 0 0 7px;
        flex: 0 0 7px;
}

.card-path-line-border.active{
    border: 1px dashed #33AF83;
}

.order-m-card > div:last-child{
    -ms-flex-pack: justify;
        justify-content: space-between;
}

.minicard-right-item{
    padding-bottom: 15px;
}

.shorten-order-table{
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    font-family: Roboto;
    color: #333333;
}

.shorten-order-table td{
    height: 72px;
}
.shorten-order-table tr:nth-child(even){
    background-color: #FFF;
}

.shorten-order-table tr td:first-child{
    padding-left: 24px;
    padding-right: 24px;
}

.order-m-card-location-info{
    display: -ms-flexbox;
    display: flex;
}

.order-m-card-logo{
    width: 24px;
    height: 24px;
    margin-top: 10px;
    margin-right: 23px;
    background-repeat: no-repeat;
}

.order-m-card-vehicle{
    background-image: url("../../assets/img/orders/minicardTransportTypeVehicle.svg");
}

.order-m-card-air{
    background-image: url("../../assets/img/orders/minicardTransportTypeAir.svg");
}

.order-m-card-railway{
    background-image: url("../../assets/img/orders/minicardTransportTypeRailway.svg");
    background-position: -8px -8px;
}

.order-m-card-sea{
    background-image: url("../../assets/img/orders/minicardTransportTypeSea.svg");
}

.order-m-card-location-info .subtitle, .shorten-order-table .subtitle{
    font-size: 12px;
    line-height: 16px;
    color: #8D98A6;
}

.order-card-header{
    margin-bottom: 40px;
    margin-top: 110px;
    /*position: revert;*/
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
}

.order-card-header .order-title-actions{
    display: -ms-flexbox;
    display: flex;
}

.order-card-header h2{
    font-family: PFDinDisplayPro-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 32px;
    color: #333333;
}

.order-card-header .actions{
    margin-left: 20px;
    text-transform: uppercase;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #333;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-align: center;
        align-items: center;
    cursor: pointer;
}

.order-card-header .actions span{
    margin-left: 16px;
}

.ordercard-navigation{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;

    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #333;

    background: #E9E9E9;
    -webkit-box-shadow: inset 0px 1px 0px #E9E9E9;
            box-shadow: inset 0px 1px 0px #E9E9E9;
    height: 56px;
}

.ordercard-navigation > div{
    cursor: pointer;
}

.adminDeliveryOrder {
    max-width: 335px;
}

.adminDeliveryOrder > .modal-content {
    width: 335px;
    height: 178px;
}

.adminDeliveryOrder > .modal-content > .modal-header {
    display: none;
}

.adminDeliveryOrder > .modal-content > .modal-body > .buttons {
    bottom: 20px;
    position: absolute;
    left: 42px;
}

.adminDeliveryOrder > .modal-content > .modal-body > h6 {
    margin-top: 29px;
    font-size: 18px;
    line-height: 24px;
}

.adminDeliveryOrder > .modal-content > .modal-body > .buttons > .btn.btn-primary{
    width: 128px;
    height: 40px;
    color: #F5541D;
    background-color: rgba(245, 84, 29, 0.05);
    border: 1px solid #F5541D;
    border-radius: 3px;
}

.adminDeliveryOrder > .modal-content > .modal-body > .buttons > .btn.btn-secondary {
    width: 119px;
    height: 40px;
    border: 1px solid #4996FF;
    border-radius: 3px;
}

.adminDeliveryOrder > .modal-content > .modal-body > hr {
    display:none;
}

.adminDeliveryOrder > .modal-content > .modal-body > img {
    position: absolute;
    right: 15px;
    top: 15px
}

/* мобильная версия */
.order-m-card-mobile{
    width: 100%;
    padding: 16px 9px;
    background-color: #FFF;
    font-family: Roboto;
    margin: 16px 0;
}

fa {
    color: #2B34A4;
}

.order-m-card-mobile .col-6 {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    text-align: left;
    color: #333333;
    margin-bottom: 8px;
}

.order-m-card-mobile .minicard-right-item{
    width:24px;
    margin-left: auto;
    margin-bottom:10px;
}

.order-m-card-mobile .col-2 {
    padding-right: 0;
}

.order-m-card-mobile .col-2 {
    padding-left: 0;
}

.row {
    margin: 0;
}

.search-bar-mobile {
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #D3D8E0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 3px;
    text-indent: 32px;
}

.search-bar-mobile::-webkit-input-placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #D3D8E0;
}

.search-bar-mobile::-moz-placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #D3D8E0;
}

.search-bar-mobile:-ms-input-placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #D3D8E0;
}

.search-bar-mobile::-ms-input-placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #D3D8E0;
}

.search-bar-mobile::placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #D3D8E0;
}

.crud-list-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #777777;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-align: baseline;
        align-items: baseline;
    margin-top: 32px;
}

.crud-list-title h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 32px;
    color: #333333;
}

.order-m-card-mobile-row{
    padding: 0;
}

.order-m-card-mobile-row .fa{
    color: #2B34A4;
    position: absolute;
    top: 71px;
    left: 14px;
    cursor: pointer;
}

.mobile-card{
    border: 1px solid rgba(211,216,224,0.35);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 8px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
}

.mobile-card-buttons,
.mobile-card-buttons > div{
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.mobile-card-buttons {
    margin-top: 16px;
}

.mobile-card > div.pale-font {
    margin-top: 7px;
}

.order-list {
    padding:0;
}

.order-navigation {
    z-index: 999;
    position: fixed;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    top:86px;
    max-width: 1140px;
    height:56px;
    background: #E9E9E9;
    -webkit-box-shadow: inset 0px 1px 0px #E9E9E9;
            box-shadow: inset 0px 1px 0px #E9E9E9;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #333333;
    -ms-flex-align: center;
        align-items: center;
}

.order-navigation > div:hover{
    color: #2B34A4;
}

.order-navigation > div:last-child:hover{
    color:#fff;
}

.order-navigation .edit-order-btn{
    display: -ms-flexbox;
    display: flex;
    background: #62CFA8;
    border-radius: 3px;
    height: 40px;
    line-height: 16px;
    color: white;
    text-align: center;
    -ms-flex-pack: center;
        justify-content: center;
}
.order-navigation .edit-order-btn span{
    margin-top: 13px;
    margin-left: 16px;
    text-align: center;
}
.edit-order-btn:hover{
    background: #51b08d;
    color: white;
}

.edit-order-btn .edit-order-icon:hover{
    background-image: url("../../assets/img/orders/edit-btn-logo-white.svg");
}

.edit-order-btn .edit-order-icon{
    background-image: url("../../assets/img/orders/edit-btn-logo-white.svg");
    margin-top: 9px;
    margin-bottom: 9px;
}
.order-navigation-menu {
    margin-bottom: 100px;
}

.order-navigation > div{
    width:calc((100% - 186px) / 6);
    cursor: pointer;
    padding-left: 15px;
}
.order-navigation > div:last-child{
    min-width: 186px;
}



.btn-label:hover{
    cursor: pointer;
}

.tracking-block-title{
    font-weight: bold;
    width: 120px;
}

.tracking-block-label {
   text-align: center;
   font-size: 80%;
}

.tracking-item-title{
    width: 120px;
    font-size: 90%;
}

.order-short-m-card .company-name{
    min-width: 110px;
    max-width: 170px;
}

.order-short-m-card .cargo-info{
    max-width: 230px;
}

.order-main-info-comment {
    margin-bottom: 16px;
}

.order-main-info-comment-column {
    display: -ms-flexbox;
    display: flex;
}

.order-main-info-comment-date {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 50%;
        flex: 1 1 50%;
    -ms-flex-direction: column;
        flex-direction: column;
}

.not-complete-order-fade{
    margin-top: 80px;
}

.not-complete-order-fade .warning-msg{
    padding: 0 25px 10px;
    line-height: 1.5;
}

.not-complete-order-fade .warning-link{
    color: #4996ff;
    line-height: 1.5;
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .order-main-info-comment-column, .order-main-info-comment-date {
		display:block;
        margin-bottom: 10px;
	}

    .not-complete-order-fade-open{
        margin-top: 80px;
    }

    .not-complete-order-fade{
        margin-top: 67px;
    }

    .order-navigation-menu{
        margin-bottom: 190px;
    }

    .order-card-header {
        display: block;
        /*margin-top: 180px;*/
    }

    .doc-amount-header{
        position: unset;
        right: unset;
        font-size: 14px;
        line-height: 24px;
        margin-top: 10px;
    }

    .order-title-actions{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
            justify-content: space-between;
        margin-top: 16px;
    }
    .order-title-actions .action-mobile-card{
        margin-top: -16px;
        margin-bottom: 16px;
        margin-left: 20px;
        text-transform: uppercase;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: #333;

        display: -ms-flexbox;

        display: flex;
        -ms-flex-align: center;
            align-items: center;
        cursor: pointer;
    }
    .order-title-actions .action-mobile-card span{
        margin-left: 16px;
    }

    .order-title-actions .actions{
        margin-left: 0;
    }

    .order-card-panel {
        padding: 30px 16px 24px 24px;
    }

    .order-card-panel > h2 {
        margin-bottom: 18px;
        font-family: PFDinDisplayPro-Regular;
    }

    .btns > button.primary-btn, .btn-with-logo {
        width: 100%;
        margin-top: 16px;
    }

    .delete-btn-logo {
        margin-left: 16px;
    }

    .order-docs-list-mobile {
        margin-top:25px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #8D98A6;
        background: #F9F9FB;
        width:100%;
    }

    .order-docs-list-mobile > thead{
        background: #D3D8E0;
        opacity: 0.35;
    }

    .order-docs-list-mobile tr {
        height: 40px;
    }

    .order-docs-list-mobile tr td, .order-docs-list-mobile tr th {
        padding-left: 16px;
    }

    .order-docs-list-mobile tr td:last-child, .order-docs-list-mobile tr th:last-child {
        text-align: center;
    }

    .order-docs-list-mobile tr td:last-child label{
        margin-bottom: 0;
        padding-top: 4px;
    }

    .order-navigation {
        display:block;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-left: -15px;
        background-color: #E9E9E9;
        padding-bottom:15px;
    }

    .order-navigation .edit-order-btn{
        color: white;
        padding-left: 10px;
        max-width: 160px;
    }

    .order-navigation > .row > .col-10 > div {
        text-align: start;
        color:#333333;
        margin-top: 10px;
    }

    .order-navigation > .row > .col-10 > div:last-child{
        color: #fff;
    }

    .order-navigation > .row > .col-10 > div:hover {
        color:#2B34A4;
    }
    .order-navigation > .row > .col-10 > div:last-child:hover {
        color:#fff;
    }
    .order-navigation > div {
        width:100%;
        margin-top: 10px;
    }

    .order-navigation  .toggle{
        /*transform: rotate(180deg);
        top: -9px;
        left: -6px;*/
        width: 24px;
        height: 24px;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        cursor: pointer;
        background: rgba(211, 216, 224, 0.4);
        border-radius: 40px;
        position: relative;
        margin-top: 6px;
    }
    .order-navigation  .toggle.open{
        /*transform: rotate(0deg);
        top: 9px;
        left: 6px;*/
    }


.order-navigation .toggle .arrow{

    -webkit-transform: rotate(0deg);

        -ms-transform: rotate(0deg);

            transform: rotate(0deg);
    top: 9px;
    left: 6px;


    width: 24px;
    height: 24px;
    background-image: url("../../../src/assets/img/arrow.svg");
    background-repeat: no-repeat;
    position: absolute;

}

.order-navigation .toggle .arrow.open{
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    top: -9px;
    left: -6px;
}

.order-navigation.close {
    height: 40px;
}

    .order-menu{
        display: block;
    }

    .order-menu-block {
        margin-left: 18px;
    }

    .doc-card{
        margin-top: 8px;
        padding: 23px 16px;
    }

    .modal-body .col-12{
        padding: 0;
    }

    .modal-body .tracking-item-title{
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
    }

    .modal-body .tracking-item-date{
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 0 1 90%;
            flex: 0 1 90%;
    }

    .order-doc-mobile {
        margin-top: 10px;
    }
}

.modal-body .tracking-item-date .input-group{
    width: 260px;
    display: block;
}

.order-doc-mobile, .order-docs-list-mobile {
    display:none;
}

.modal-dialog.edit-order-modal{
    min-width: 800px;
    max-width: 800px;
}

@media screen and (min-width: 1200px) {
    .modal-dialog.edit-order-modal{
        min-width: 800px;
        max-width: 900px;
    }
}

@media screen and (max-width: 1200px) {
    .modal-dialog.edit-order-modal{
        min-width: 800px;
        max-width: 800px;
    }
}


@media screen and (max-width: 991px) {
    .modal-dialog.edit-order-modal{
        min-width: 768px;
        max-width: 768px;
    }
}

@media screen and (max-width: 768px) {
    .modal-dialog.edit-order-modal{
        min-width: 600px;
        max-width: 600px;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog.edit-order-modal{
        min-width: auto;
        max-width: 500px;
    }
}
.nav-tabs .nav-item .nav-link{
    color: #333;
}

.nav-tabs .nav-link:hover{
    border-color: #fff #fff #4996FF #fff;
    border-bottom-width: 3px;
}

.nav-tabs .nav-link.active{
    color: #4996FF;
    border-color: #fff #fff #4996FF #fff;
    border-bottom-width: 3px;
}

.tab-content{
    margin-top: 30px;
}





