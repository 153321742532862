.container-list {
  margin-top: 20px;
}
.container-list .container-unit label {
  min-height: 25px;
  line-height: 13px;
  margin-bottom: 5px;
  vertical-align: middle;
  color: rgba(145, 152, 167, 0.5);
  font-size: 13px;
}
.container-list .container-unit .container-type-label {
  width: 98%;
  height: 42px;
  padding-left: 2px;
  padding-right: 2px;
}
