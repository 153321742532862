.tooltip-ui__wrapper {
  position: relative;
  -ms-flex: 25% 1 1;
      flex: 25% 1 1;
}

.tooltip-ui__wrapper .btn {
  width: 100%;
}

.tooltip-ui {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  visibility: hidden;
  padding: 5px;
  border-radius: 5px;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  opacity: 0;
  top: -80%;
  -webkit-transform: translateX(-25%) translateY(-50%);
      -ms-transform: translateX(-25%) translateY(-50%);
          transform: translateX(-25%) translateY(-50%);
  width: 200px;
}

.tooltip-ui__arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent;
}
