.header__buttons_language-switcher_select > .Select-control {
    background-color: #D3D8E0;
    border-radius: 20px !important;
    height: 40px !important;
}

.header__buttons_language-switcher_select > .is-focused {  
   background-color: #D3D8E0;
   color: white;
}

.header__buttons_language-switcher_select > .Select-option > .is-focused {  
    background-color: #D3D8E0;
    color: white;
 }

 .header__buttons_language-switcher_select > .Select-option > .is-selected {  
    background-color: #D3D8E0;
    color: white;
 }

 .header__buttons_language-switcher_select > .Select-option:hover {
    background-color: #D3D8E0;
    color: white;
 }

 .header__dropdown {
   font-family: PFDinDisplayPro-Regular;
 }
