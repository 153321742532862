@charset "UTF-8";
@import '../../../node_modules/font-awesome/css/font-awesome.css';
@import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.header {
  min-height: 86px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
}
.header_menu_close {
  margin-left: 60px;
}
.header_menu_open {
  margin-left: 255px;
}
.header .Select-menu {
  border-radius: 20px;
}
.header .Select .Select-option {
  background-color: #F0F0F0;
}
.header .Select .Select-option.is-selected {
  background-color: #F0F0F0;
}
.header .Select .Select-option.is-selected:hover {
  background-color: #F0F0F0;
}
.header .Select .Select-option.is-focused {
  background-color: #F0F0F0;
}
.header .Select .Select-option.is-focused:hover {
  background-color: #F0F0F0;
}
.header__logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.header__logo_image {
  display: block;
  min-width: 150px;
  width: 150px;
  height: 50px;
  background: var(--logoUrl) no-repeat center center;
  background-size: contain;
}
.header__logo_image:hover {
  cursor: pointer;
}
.header__logo_image_white {
  display: block;
  width: 150px;
  height: 55px;
  margin-left: 15px;
  background: var(--logoUrl) no-repeat center left;
  background-size: contain;
}
.header__logo_image_white:hover {
  cursor: pointer;
}
.header__lk-title {
  display: -ms-flexbox;
  display: flex;
  line-height: 42px;
  font-size: 24px;
  color: #8D98A6;
}
.header__contact-info {
  font-size: 16px;
  text-align: right;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.header__contact-info a {
  text-decoration: none;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.header__contact-info a:hover {
  color: #66a6ff;
  color: var(--primary-hover-color, #66a6ff);
  text-decoration: underline;
}
.header__buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.header__buttons_breadcrumbs {
  -ms-flex-align: center;
      align-items: center;
}
.header__buttons_breadcrumbs_main {
  color: #8d98a6;
}
.header__buttons_contact-us button.btn {
  width: 160px;
  height: 48px;
  border-radius: 6px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
  color: #000;
  font-size: 16px;
  padding: 10px;
  text-align: center;
}
.header__buttons_contact-us button.btn:hover {
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
  color: #fff;
}
.header__buttons_wizard_btn button.btn {
  border-radius: 3px;
  padding: 0px 40px;
  height: 48px;
  color: #fff;
  border-style: none;
  background-color: #3AB0D8;
}
.header__buttons_wizard_btn button.btn:hover {
  background-color: #42caff;
}
.header__buttons_language-switcher {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  border-radius: 36px;
}
.header__buttons_language-switcher_select {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.header__buttons_language-switcher_select .Select-menu-outer .Select-menu {
  border-radius: 6px !important;
}
.header__buttons_language-switcher_select .Select-control {
  width: 40px;
  height: 40px;
  font-size: 14px;
  text-align: left;
  border-radius: 4px;
  padding: 0 !important;
  cursor: pointer;
  border: none;
}
.header__buttons_language-switcher_select .Select-control:hover {
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
}
.header__buttons_language-switcher_select .Select-control .Select-value {
  padding: 0 !important;
  overflow: initial;
}
.header__buttons_language-switcher_select .Select-control .Select-value .values {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 3px;
  overflow: hidden;
}
.header__buttons_language-switcher_select .Select-control .Select-value .values .flag {
  width: 100%;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.header__buttons_language-switcher_select .Select-control .Select-value .values .flag-ru {
  background-image: url("../img/langs/russia.png");
}
.header__buttons_language-switcher_select .Select-control .Select-value .values .flag-en {
  background-image: url("../img/langs/united-kingdom.png");
}
.header__buttons_language-switcher_select .Select-control .Select-value .values .flag-zh {
  background-image: url("../img/langs/china.png");
}
.header__buttons_language-switcher_select .Select-control .Select-value-label {
  line-height: 1;
}
.header__buttons_language-switcher_select .Select-menu-outer {
  background-color: #f6f6f6 !important;
  border-radius: 20px;
  margin-left: -10px;
  min-width: 60px;
}
.header__buttons_language-switcher_select .Select-menu-outer .Select-option {
  padding: 8px 10px;
}
.header__buttons_language-switcher_select .Select-menu-outer .options {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 40px;
}
.header__buttons_language-switcher_select .Select-menu-outer .options .flag {
  width: auto;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 30px;
}
.header__buttons_language-switcher_select .Select-menu-outer .options .flag-ru {
  background-image: url("../img/langs/ru.svg");
  background-size: cover;
  width: 100%;
}
.header__buttons_language-switcher_select .Select-menu-outer .options .flag-en {
  background-image: url("../img/langs/uk.svg");
  background-size: cover;
  width: 100%;
}
.header__buttons_language-switcher_select .Select-menu-outer .options .flag-zh {
  background-image: url("../img/langs/zh.svg");
  background-size: cover;
  width: 100%;
}
.header__buttons_language-switcher_select .Select-arrow-zone {
  display: none;
}
.header__buttons_language-switcher_select_widget .Select-control {
  width: 40px;
  height: 40px;
}
.header__buttons_language-switcher_select_widget .Select-control .Select-value .values .flag {
  background-size: 40px 51px;
}
.header__buttons_language-switcher_select_widget .Select-menu-outer .options .flag {
  height: 28px;
}
.header__notification {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  min-width: 40px !important;
}
@media screen and (max-width: 576px) {
  .header__notification {
    margin-left: 15px;
  }
}
.header__notification_pull-right.dropdown .notification-btn {
  padding: 0;
  width: 42px;
  height: 42px;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window {
  min-height: 80px;
  max-height: 450px;
  min-width: 400px;
}
@media screen and (max-width: 576px) {
  .header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window {
    min-width: 93vw;
  }
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 15px;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .header-block .col-12 {
  padding: 0 !important;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .header-block .title-header {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #333;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .header-block .header-read-all-btn {
  cursor: pointer;
  margin-left: auto;
  font-size: 12px;
  line-height: 24px;
  color: #3AB0D8;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .header-block .header-read-all-btn:hover {
  text-decoration: underline;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .header-block hr {
  width: 100%;
  margin-bottom: 10px;
  color: rgba(51, 51, 51, 0.5);
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block::-webkit-scrollbar {
  width: 4px;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(51, 51, 51, 0.3);
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block::-webkit-scrollbar-thumb:window-inactive {
  background-color: rgba(51, 51, 51, 0.3);
  opacity: 0.8;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .row {
  margin-top: 10px;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .icon {
  background: url("../img/notifications/error-icon.svg") no-repeat center center;
  min-width: 24px;
  min-height: 24px;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .icon.info {
  background: url("../img/notifications/info-icon.svg") no-repeat center center;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .icon.warning {
  background: url("../img/notifications/warning-icon.svg") no-repeat center center;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .icon.error {
  background: url("../img/notifications/error-icon.svg") no-repeat center center;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .col-1 {
  min-width: 24px;
  padding-left: 0 !important;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .col-12, .header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .col-auto {
  padding: 0px !important;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .title {
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .title .date {
  margin-left: 5px;
  font-size: 11px;
  color: rgba(51, 51, 51, 0.5);
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .message {
  font-size: 12px;
  color: #333;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .action-read {
  cursor: pointer;
  margin-top: 6px;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-left: 7px;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .action-read.unread {
  background-color: #3AB0D8;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .notification-item .action-delete {
  display: none;
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: 5px;
  background: url("../img/notifications/notification-delete.svg") no-repeat center center;
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .notification-item .action-delete:hover {
  background: url("../img/notifications/notification-delete-hover.svg") no-repeat center center;
}
@media screen and (min-width: 576px) {
  .header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .notification-item:hover .action-delete {
    display: block;
  }
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .react-tooltip {
  padding: 4px 8px;
  opacity: 1;
  z-index: 999;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
@media screen and (max-width: 576px) {
  .header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .react-tooltip {
    display: none;
  }
}
.header__notification_pull-right.dropdown .dropdown-menu .notification-modal-window.container .body-block .react-tooltip .react-tooltip-arrow {
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}
.header__notification_pull-right.dropdown .dropdown-toggle {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-image: url("../img/notifications/notification.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.header__notification_pull-right.dropdown .dropdown-toggle:after {
  border: none;
}
.header__notification_pull-right.dropdown .dropdown-menu-right {
  width: 400px;
  -webkit-transform: translate(-10px, 45px) !important;
      -ms-transform: translate(-10px, 45px) !important;
          transform: translate(-10px, 45px) !important;
}
@media screen and (max-width: 576px) {
  .header__notification_pull-right.dropdown .dropdown-menu-right {
    width: 93vw;
    -webkit-transform: translate(60px, 45px) !important;
        -ms-transform: translate(60px, 45px) !important;
            transform: translate(60px, 45px) !important;
  }
}
.header__dropdown {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}
.header__dropdown_pull-right .user-info-btn {
  padding: 0;
  width: 42px;
  height: 42px;
}
.header__dropdown_pull-right .dropdown-toggle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url("../img/user.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
}
.header__dropdown_pull-right .dropdown-toggle:after {
  border: none;
}
.header__dropdown_pull-right .dropdown-menu-right {
  width: 305px;
  -webkit-transform: translate3d(-220px, 30px, 0px);
          transform: translate3d(-220px, 30px, 0px);
}
.header__dropdown_text-title {
  font-size: 18px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}
.header__dropdown_text-item {
  text-align: center;
  margin-bottom: 8px;
  font-size: 14px;
}
.header__dropdown .fa {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}

.header__notification_pull-right.dropdown .notification-btn {
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.header__notification_pull-right.dropdown .notification-btn.active {
  background-image: url("../img/notifications/notification-active.svg");
}
.header__notification_pull-right.dropdown .notification-btn.active:active {
  background-color: transparent !important;
  border-color: transparent !important;
}
.header__notification_pull-right.dropdown .notification-btn.active:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.header__notification_pull-right.dropdown .notification-btn.new-notification {
  background-image: url("../img/notifications/notification-new.svg");
}
.header__notification_pull-right.dropdown .notification-btn.new-notification:hover {
  background-image: url("../img/notifications/notification-active-new.svg");
}
.header__notification_pull-right.dropdown .notification-btn:active {
  background-color: transparent !important;
  border-color: transparent !important;
}
.header__notification_pull-right.dropdown .notification-btn:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.header__notification_pull-right.dropdown .notification-btn:hover {
  background-image: url("../img/notifications/notification-active.svg");
}
.header__notification_pull-right.dropdown .arrow {
  display: inline-block;
  margin-left: 45px;
  margin-top: 10px;
  min-width: 15px;
  background-image: url("../img/arrow.svg");
  background-repeat: no-repeat;
}

.header__dropdown_pull-right.dropdown .user-info-btn {
  background-color: #62CFA8;
  border-color: transparent;
}
.header__dropdown_pull-right.dropdown .arrow {
  display: inline-block;
  margin-left: 45px;
  margin-top: 10px;
  min-width: 15px;
  background-image: url("../img/arrow.svg");
  background-repeat: no-repeat;
}

.header__dropdown {
  margin-right: 18px;
}

.header-breadcrumb {
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  font-size: 12px;
  line-height: 48px;
  color: #333333;
}
.header-breadcrumb a {
  color: #333333;
}

.header-breadcrumb div {
  margin-bottom: 10px;
  height: 30px;
}

@media (max-width: 576px) {
  .header__buttons_breadcrumbs {
    display: none;
  }
  .header-breadcrumb {
    line-height: 20px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}
/*@media (min-width: 992px){
  .menu-open-breadcrumb{
    display: none;
  }
  .menu-close-breadcrumb{
    display: none;
  }
  .breadcrumb-order-details{
    display: none;
  }
}*/
.breadcrumb-order-details {
  margin-top: 44px;
}

.menu-open-breadcrumb {
  padding-top: 20px;
  padding-left: 255px;
}

.menu-close-breadcrumb {
  padding-top: 20px;
  padding-left: 60px;
}

@media (max-width: 992px) {
  /*.breadcrumb-order-details{
    margin-top: 44px;
  }
  .menu-open-breadcrumb{
    padding-top: 20px;
    padding-left: 255px;
  }
  .menu-close-breadcrumb{
    padding-top: 20px;
    padding-left: 60px;
  }*/
}
@media (max-width: 576px) {
  .menu-open-breadcrumb {
    padding-top: 20px;
    padding-left: 0px;
  }
  .menu-close-breadcrumb {
    padding-top: 20px;
    padding-left: 0px;
  }
}
.link-hover span:hover {
  text-decoration: underline;
}

/**
 * Переключаемая боковая панель навигации
 * выдвигающаяся по клику слева
 */
.left-menu.open {
  width: 255px;
}
.left-menu.open > .left-menu_nav {
  width: 255px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: left;
      justify-content: left;
  -ms-flex-align: start;
      align-items: flex-start;
  padding: 15px 16px;
}
.left-menu.open .left-menu_nav_btn_toggle:after {
  content: "✕";
}
@media screen and (max-width: 576px) {
  .left-menu.open > .left-menu_nav {
    width: 100%;
  }
}
.left-menu.hidden {
  width: 60px;
}
.left-menu.hidden .left-menu_nav_list {
  padding-right: 0;
}
.left-menu.hidden .left-menu_nav_list_common {
  margin-top: 200px;
}
.left-menu.hidden .left-menu_nav_list_copyright {
  display: none;
}
.left-menu.hidden .left-menu_nav_btn {
  width: auto;
  min-height: 55px;
}
.left-menu .header__right_buttons {
  display: -ms-flexbox;
  display: flex;
}
.left-menu .lang_switcher .Select .Select-option {
  background-color: #F0F0F0;
}
.left-menu .lang_switcher .Select .Select-option.is-selected {
  background-color: #F0F0F0;
}
.left-menu .lang_switcher .Select .Select-option.is-selected:hover {
  background-color: #F0F0F0;
}
.left-menu .lang_switcher .Select .Select-option.is-focused {
  background-color: #F0F0F0;
}
.left-menu .lang_switcher .Select .Select-option.is-focused:hover {
  background-color: #F0F0F0;
}
@media screen and (max-width: 576px) {
  .left-menu .header__right_buttons {
    width: 100%;
  }
  .left-menu .lang_switcher {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    height: 40px;
  }
}
@media screen and (min-width: 576px) {
  .left-menu .lang_switcher {
    display: none;
  }
}
.left-menu_nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
  width: 60px;
  /* фиксируем и выставляем высоту панели на максимум */
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 15px 0;
  /* плавный переход смещения панели */
  /* определяем цвет фона панели */
  background-color: #276A9B;
  background-color: var(--primary-bg-color, #276A9B);
  z-index: 20;
  /**
   * Кнопка переключения панели
   * тег <label>
   */
  /* Let's get this party started */
  /* Track */
  /* Handle */
}
.left-menu_nav_btn {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  height: 55px;
}
.left-menu_nav_btn_logo {
  width: 100px;
  height: 45px;
  margin-left: 35px;
  background: url("../img/logo_menu.png") no-repeat top left;
}
.left-menu_nav_btn_toggle {
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 23px;
  padding: 0 5px;
  width: 34px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 28px;
  margin-bottom: 0;
  /* определяем текст кнопки
    * символ Unicode (TRIGRAM FOR HEAVEN)
   */
  /* цвет текста при наведении */
}
.left-menu_nav_btn_toggle:after {
  content: "☰";
}
.left-menu_nav_btn_toggle:hover {
  color: #f4f4f4;
}
.left-menu_nav ::-webkit-scrollbar {
  width: 2px;
}
.left-menu_nav ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.left-menu_nav ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #3AB0D8;
  background-color: var(--primary-active-color, #3AB0D8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.left-menu_nav ::-webkit-scrollbar-thumb:window-inactive {
  opacity: 0.8;
}
.left-menu_nav_list {
  margin-top: 32px;
  list-style: none;
  font-size: 14px;
  padding-left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 5px;
}
.left-menu_nav_list_other-link {
  margin-top: 20px;
}
.left-menu_nav_list_copyright {
  font-size: 14px;
  color: rgba(241, 242, 244, 0.5);
  margin-top: 20px;
}
.left-menu_nav_list_only-image {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.left-menu_nav_list_only-image div {
  width: 32px;
  height: 32px;
  margin: 8px 0;
}
.left-menu_nav_list_only-image .bc-dark {
  background-color: #20266F;
}
.left-menu_nav_list_only-image .modal-menu {
  left: 60px;
  background-color: #20266F;
  position: absolute;
  padding: 12px 28px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
}
.left-menu_nav_list_only-image .modal-menu div {
  width: auto;
  height: auto;
  margin: 8px 0;
}
.left-menu_nav_list_only-image .modal-menu .header-modal {
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.5;
}
.left-menu_nav_list_only-image .modal-menu li a {
  display: -ms-flexbox;
  display: flex;
}
.left-menu_nav_list_only-image .modal-menu li span {
  opacity: 1;
}
.left-menu_nav_list_only-image .modal-menu.show-m {
  display: block;
}
.left-menu_nav_list_only-image .modal-menu.close-m {
  display: none;
}
.left-menu_nav_list_image-item a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.left-menu_nav_list_image-item a div {
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
}
.left-menu_nav_list_title {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.left-menu_nav_list .order_menu {
  background: url("../img/menu/v2/order_menu.svg") no-repeat center;
}
.left-menu_nav_list .request_menu {
  background: url("../img/menu/request_menu.png") no-repeat center;
}
.left-menu_nav_list .delivery_menu {
  background: url("../img/menu/v2/delivery_menu.svg") no-repeat center;
}
.left-menu_nav_list .customs_menu {
  background: url("../img/menu/v2/customs_menu.svg") no-repeat center;
}
.left-menu_nav_list .rate_menu {
  background: url("../img/menu/v2/rate_menu.svg") no-repeat center;
}
.left-menu_nav_list .users_menu {
  background: url("../img/menu/v2/users_menu.svg") no-repeat center;
}
.left-menu_nav_list .subscription {
  background: url("../img/menu/v2/subscription.svg") no-repeat center;
}
.left-menu_nav_list .subscriptionp {
  background: url("../img/menu/v2/subscription_menu.svg") no-repeat center;
}
.left-menu_nav_list .sales_rules_menu {
  background: url("../img/menu/v2/sales_rules_menu.svg") no-repeat center;
}
.left-menu_nav_list .geography_menu {
  background: url("../img/menu/v2/geography_menu.svg") no-repeat center;
}
.left-menu_nav_list .air_menu {
  background: url("../img/menu/air_menu.png") no-repeat center;
}
.left-menu_nav_list .sea_menu {
  background: url("../img/menu/sea_menu.png") no-repeat center;
}
.left-menu_nav_list .truck_menu {
  background: url("../img/menu/truck_menu.png") no-repeat center;
}
.left-menu_nav_list .train_menu {
  background: url("../img/menu/train_menu.png") no-repeat center;
}
.left-menu_nav_list .complex_menu {
  background: url("../img/menu/v2/complex-icon-bluemenu.svg") no-repeat center;
}
.left-menu_nav_list .common1_menu {
  background: url("../img/menu/common1_menu.png") no-repeat center;
}
.left-menu_nav_list .common2_menu {
  background: url("../img/menu/common2_menu.png") no-repeat center;
}
.left-menu_nav_list .organizationprofiles_menu {
  background: url("../img/menu/v2/organization_menu.svg") no-repeat center;
}
.left-menu_nav_list .api_menu {
  background: url("../img/menu/v2/api_menu.svg") no-repeat center;
}
.left-menu_nav_list .audit_menu {
  background: url("../img/menu/v2/audit_menu.svg") no-repeat center;
}
.left-menu_nav_list .chatbot_menu {
  background: url("../img/menu/v2/chatbot_menu.svg") no-repeat center;
}
.left-menu_nav_list .incompletecalculation_menu {
  background: url("../img/menu/v2/incompletecalc_menu.svg") no-repeat center;
}
.left-menu_nav_list .insurancerates_menu {
  background: url("../img/menu/v2/insurancerates_menu.svg") no-repeat center;
}
.left-menu_nav_list .organization_menu {
  background: url("../img/menu/v2/organizationprofiles_menu.svg") no-repeat center;
}
.left-menu_nav_list .reference_menu {
  background: url("../img/menu/v2/reference_menu.svg") no-repeat center;
}
.left-menu_nav_list .reports_menu {
  background: url("../img/menu/v2/reports_menu.svg") no-repeat center;
}
.left-menu_nav_list .rulestransportation_menu {
  background: url("../img/menu/v2/rulestransporation_menu.svg") no-repeat center;
}
.left-menu_nav_list .subscriptionpartner_menu {
  background: url("../img/menu/v2/subscription.svg") no-repeat center;
}
.left-menu_nav_list hr {
  border-color: white;
  border-width: 1px;
}
.left-menu_nav_list li {
  margin-bottom: 5px;
}
.left-menu_nav_list li a {
  color: #fff;
}
.left-menu_nav_list li a > span.label {
  padding-left: 5px;
}
.left-menu_nav_list li a:hover {
  text-decoration: none;
}
.left-menu_nav_list li a:hover > span {
  text-decoration: underline;
}
.left-menu_nav_list li a:hover > span.label {
  text-decoration: none;
}
.left-menu_nav_list li a.active {
  color: #fff;
}
.left-menu_nav_list li a.active > span {
  text-decoration: underline;
}
.left-menu_nav_list li a.active > span.label {
  text-decoration: none;
}
.left-menu_nav_list li .contactBtn {
  font-size: 14px;
  color: white !important;
  padding: 0;
  width: 100%;
  /* opacity: 0.3; */
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  height: 48px;
}
.left-menu_nav_list li .contactBtn:hover {
  /*background-color: #092767;*/
  opacity: 0.7;
}
.left-menu_nav_list li .contactBtn div span {
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.left-menu_nav_list_btn-wizard button a {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-weight: bold;
}
.left-menu_nav_list_btn-wizard button a.active {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-weight: bold;
}
.left-menu_nav_list .parent-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}
.left-menu_nav_list .parent-item_icon {
  color: #ffffff;
  cursor: pointer;
}
.left-menu_nav_list .child li {
  list-style: none;
  font-size: 14px;
  line-height: 1.7;
}
.left-menu_nav_list .child.show {
  display: block;
  padding-left: 40px;
}
.left-menu_nav_list .child.show.with-icons {
  padding-left: 12px;
  padding-top: 10px;
}
.left-menu_nav_list .child.hidden {
  display: none;
}
.left-menu_nav_list .nav-left-menu a.active {
  color: white;
}

/* */
.left-menu_nav_list > li > a .left-menu_nav_list_title {
  max-width: 164px;
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.03em;
}
.left-menu_nav_list .parent-item .parent-link {
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.03em;
}
.left-menu_nav_list span:not(.fa):not(.label-danger) {
  max-width: 164px;
  opacity: 0.8;
}

@media screen and (max-width: 576px) {
  .left-menu_nav_list span:not(.fa):not(.label-danger) {
    max-width: 100%;
    opacity: 0.8;
  }
}
.footer {
  margin: 0;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 1px 3px 2px rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 2px rgba(0, 5, 68, 0.25);
}
.footer__logo {
  -ms-flex-align: center;
      align-items: center;
}
.footer__logo_image {
  display: block;
  min-width: 100px;
  height: 45px;
  background: url("../img/cargobar-logo-1.svg") no-repeat top left;
}
.footer__logo_image:hover {
  cursor: pointer;
}
.footer__copyright {
  font-size: 14px;
  color: rgba(85, 85, 85, 0.5);
  padding-top: 16px;
}
.footer__copyright a {
  color: #3AB0D8;
}
.footer__menu ul {
  padding-left: 0;
}
.footer__menu li {
  list-style: none;
}
.footer__menu li a {
  font-size: 20px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.footer__menu li a:hover {
  text-decoration: none;
  color: #66a6ff;
  color: var(--primary-hover-color, #66a6ff);
}
.footer__subscribe_title {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 16px;
  line-height: 1.37;
}
.footer .subscribe__form {
  position: relative;
  z-index: 7;
}
.footer .subscribe__input {
  position: relative;
  z-index: 9;
  display: block;
  height: 48px;
  width: 100%;
  padding-left: 17px;
  padding-right: 73px;
  border: 1px solid #e6e6e8;
  border-radius: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.footer .subscribe__input::-webkit-input-placeholder {
  color: #d4d4d4;
}
.footer .subscribe__input::-moz-placeholder {
  color: #d4d4d4;
}
.footer .subscribe__input:-ms-input-placeholder {
  color: #d4d4d4;
}
.footer .subscribe__input::-ms-input-placeholder {
  color: #d4d4d4;
}
.footer .subscribe__input::placeholder {
  color: #d4d4d4;
}
.footer .subscribe__submit {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 48px;
  width: 56px;
  margin: 0;
  padding-top: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: 0 none;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../img/icons/mail.svg");
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.footer .subscribe__submit:hover {
  background-color: var(--primary-hover-color, #66a6ff);
  cursor: pointer;
}
.footer__developer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
      align-items: baseline;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.footer__developer > span {
  color: rgba(85, 85, 85, 0.5);
  margin-right: 12px;
}
.footer__developer_img {
  height: 18px;
  width: 87px;
  background: url("../img/reksoft.png") no-repeat center;
  background-size: contain;
}

@media screen and (max-width: 991px) {
  .wizard-modal .modal-body .col-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.wizard .container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.wizard .row {
  margin: 0;
}
.wizard .wizard__direction-block_first {
  padding: 0 !important;
}
.wizard .f-element-number input:focus, .wizard .f-element-number textarea:focus, .wizard .f-element-text input:focus, .wizard .f-element-text textarea:focus {
  border-color: #4996FF;
}
.wizard .f-element-number input, .wizard .f-element-number textarea, .wizard .f-element-text input, .wizard .f-element-text textarea {
  border-radius: 3px;
}
.wizard .f-element-number input :hover, .wizard .f-element-number input :active, .wizard .f-element-number input :focus, .wizard .f-element-number textarea :hover, .wizard .f-element-number textarea :active, .wizard .f-element-number textarea :focus, .wizard .f-element-text input :hover, .wizard .f-element-text input :active, .wizard .f-element-text input :focus, .wizard .f-element-text textarea :hover, .wizard .f-element-text textarea :active, .wizard .f-element-text textarea :focus {
  border-color: #4996FF;
}
.wizard .f-element-number input:focus + b, .wizard .f-element-number input:focus + b + b, .wizard .f-element-number textarea:focus + b, .wizard .f-element-number textarea:focus + b + b, .wizard .f-element-text input:focus + b, .wizard .f-element-text input:focus + b + b, .wizard .f-element-text textarea:focus + b, .wizard .f-element-text textarea:focus + b + b {
  border-color: #4996FF;
}
.wizard .f-element-number input:focus + b:hover, .wizard .f-element-number input:focus + b + b:hover, .wizard .f-element-number textarea:focus + b:hover, .wizard .f-element-number textarea:focus + b + b:hover, .wizard .f-element-text input:focus + b:hover, .wizard .f-element-text input:focus + b + b:hover, .wizard .f-element-text textarea:focus + b:hover, .wizard .f-element-text textarea:focus + b + b:hover {
  background-color: #4996FF;
}
.wizard .f-element-number input:focus + b:hover i, .wizard .f-element-number input:focus + b + b:hover i, .wizard .f-element-number textarea:focus + b:hover i, .wizard .f-element-number textarea:focus + b + b:hover i, .wizard .f-element-text input:focus + b:hover i, .wizard .f-element-text input:focus + b + b:hover i, .wizard .f-element-text textarea:focus + b:hover i, .wizard .f-element-text textarea:focus + b + b:hover i {
  border-bottom-color: white;
  border-top-color: white;
}
.wizard .f-element-number .react-numeric-input :hover, .wizard .f-element-number .react-numeric-input :active, .wizard .f-element-number .react-numeric-input :focus, .wizard .f-element-text .react-numeric-input :hover, .wizard .f-element-text .react-numeric-input :active, .wizard .f-element-text .react-numeric-input :focus {
  border-color: #4996FF;
}
.wizard .btn.add-container-btn, .wizard .btn.add-container-btn:hover {
  width: 109px;
  height: 32px;
  margin-bottom: 20px;
  margin-left: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.wizard .btn.btn-container-box {
  border-radius: 3px;
  font-family: Roboto;
}
.wizard .btn.btn-container-box:hover {
  border-color: #4996FF;
}
.wizard .btn.btn-container-box.active {
  border-color: #4996FF;
  background-color: #4996FF;
}
.wizard .btn.add-container-btn {
  margin-top: 24px;
  border: 1px solid #4996FF;
  border-radius: 3px;
  color: #4996FF;
}
.wizard .btn.add-container-btn:hover {
  border: 1px solid #4996FF;
  color: white;
  background-color: #4996FF;
}
.wizard .rc-switch {
  border-color: #D3D8E0;
  min-width: 29px;
  width: 29px;
  height: 16px;
  background: #D3D8E0;
  border-radius: 40px;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
      -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
}
.wizard .rc-switch:after {
  width: 10px;
  height: 10px;
  left: 14px;
}
.wizard .rc-switch-checked {
  background: #4996FF;
  border-color: #4996FF;
}
.wizard .rc-switch-checked:after {
  width: 10px;
  height: 10px;
  left: 0px;
}
.wizard .toggleDropdown {
  border-radius: 3px;
  -webkit-box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.05);
          box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.05);
  padding-left: 16px;
}
.wizard .toggleDropdown__label {
  color: #333333;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
}
.wizard .toggleDropdown__body {
  border: none;
}
.wizard__direction-block {
  padding: 18px 15px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.05);
          box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.05);
}
.wizard__direction-block.first {
  margin-right: 15px;
}
.wizard__direction-block.last {
  margin-right: -15px;
}
.wizard__direction-block_header {
  padding: 0 7px;
}
.wizard__direction-block_header_title {
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  color: #333333;
  line-height: 24px;
  font-weight: 400;
}
.wizard__direction-block_header_subtitle {
  margin-left: auto;
  font-size: 14px;
  color: #D3D8E0;
  line-height: 24px;
  font-weight: 400;
}
.wizard__direction-block_country {
  margin-bottom: 25px;
}
.wizard__direction-block_country_error {
  height: 35px;
  line-height: 1;
}
.wizard__direction-block_location {
  font-size: 15px;
  /*display: flex;*/
  -ms-flex-pack: left;
      justify-content: left;
  margin: 10px 0;
}
.wizard__direction-block_location .btn.btn-secondary {
  height: auto !important;
  min-height: 40px;
}
.wizard__direction-block_location-title {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 16px;
}
.wizard__direction-block_location .form-check {
  margin-right: 15px;
}
.wizard__direction-block_location .form-check-label {
  -ms-flex-align: start;
      align-items: flex-start;
}
.wizard__direction-block_info-hubs label {
  height: 40px;
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 5px;
}
.wizard__direction-block_info-hubs label::after {
  content: "";
  display: inline-block;
  height: 100%;
}
.wizard__direction-block_info-hubs label {
  height: 40px;
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 5px;
}
.wizard__direction-block_info-hubs label::after {
  content: "";
  display: inline-block;
  height: 100%;
}
.wizard__cargo-from {
  border-radius: 3px;
  background-color: #fff;
  /*box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.05);*/
  padding: 18px 0 0;
  margin: 0;
}
.wizard__cargo-from .containerPromptBtn {
  color: #3AB0D8;
  text-decoration: underline;
  cursor: pointer;
}
.wizard__cargo-from .inv_show_pc {
  display: none;
}
@media (min-width: 768px) {
  .wizard__cargo-from .inv_show_pc {
    display: block;
  }
}
.wizard__cargo-from .btn.btn-remove {
  background: url("../img/icons/icon-remove.svg") center no-repeat;
}
.wizard__cargo-from .btn.btn-remove:hover {
  background: url("../img/icons/icon-remove-hover.svg") center no-repeat;
}
.wizard__cargo-from_header {
  padding: 0 39px 26px 8px;
}
.wizard__cargo-from_header_summary {
  display: none;
}
.wizard__cargo-from_header_summary .btn-collapse-cargo, .wizard__cargo-from_header_summary .btn.btn-collapse-cargo {
  border: none;
  width: 24px;
  height: 24px;
}
.wizard__cargo-from_header_summary .btn-collapse-cargo.down, .wizard__cargo-from_header_summary .btn.btn-collapse-cargo.down {
  background: url("../img/icons/icon-arrow-up.svg") center no-repeat;
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.wizard__cargo-from_header_summary .btn-collapse-cargo.up, .wizard__cargo-from_header_summary .btn.btn-collapse-cargo.up {
  background: url("../img/icons/icon-arrow-up.svg") center no-repeat;
}
.wizard__cargo-from_header_title {
  font-size: 24px;
  color: #333333;
  line-height: 24px;
  font-weight: 400;
}
.wizard__cargo-from_header_location {
  font-size: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
}
.wizard__cargo-from_header_location .form-check {
  margin-right: 15px;
}
.wizard__cargo-from_header_location .btn-cargo-type {
  height: 100%;
}
.wizard__cargo-from_header_location button span {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}
.wizard__cargo-from_header_summary-cargo-info {
  font-family: Roboto;
  color: #8D98A6;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.wizard__cargo-from_header_button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;
}
.wizard__cargo-from_summary_info_mobile {
  display: none;
  background-color: #fff;
  color: #8D98A6;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}
.wizard__cargo-from_summary_info_mobile .containerPromptBtn {
  color: #3AB0D8;
  text-decoration: underline;
  cursor: pointer;
}
.wizard__cargo-from_summary_info_mobile :last-child {
  padding-bottom: 16px;
}
.wizard__cargo-from hr {
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
  border-width: 1px;
  width: 100%;
  display: none;
}
.wizard__cargo-from .total-weight {
  font-size: 16px;
  color: rgba(145, 152, 167, 0.9);
  -ms-flex-pack: right;
      justify-content: right;
}
.wizard__cargo-from .title {
  font-size: 20px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.wizard__cargo-general-block .inv_show {
  display: block;
}
@media (max-width: 768px) {
  .wizard__cargo-general-block .inv_show {
    display: block;
  }
}
.wizard__cargo-general-block .card {
  border: none;
}
.wizard__cargo-general-block .card .card-body {
  padding: 0;
}
.wizard__cargo-general-block .cargo-general-size .form-group {
  width: 20%;
}
.wizard__cargo-general-block .cargo-general-size .form-group:nth-child(2), .wizard__cargo-general-block .cargo-general-size .form-group:nth-child(3) {
  display: block;
}
.wizard__cargo-general-block .cargo-general-size .form-group:nth-child(2) .Select-value-label, .wizard__cargo-general-block .cargo-general-size .form-group:nth-child(3) .Select-value-label {
  font-size: 1rem;
}
.wizard__cargo-general-block .cargo-general-size .form-group:nth-child(2) span.Select-arrow-zone, .wizard__cargo-general-block .cargo-general-size .form-group:nth-child(3) span.Select-arrow-zone {
  display: table-cell !important;
}
.wizard__cargo-general-block .cargo-general-size .form-group:nth-child(2) span.Select-arrow, .wizard__cargo-general-block .cargo-general-size .form-group:nth-child(3) span.Select-arrow {
  display: inline-block !important;
}
.wizard__cargo-general-block .cargo-general-size .form-group:nth-child(2) .react-numeric-input, .wizard__cargo-general-block .cargo-general-size .form-group:nth-child(2) span, .wizard__cargo-general-block .cargo-general-size .form-group:nth-child(3) .react-numeric-input, .wizard__cargo-general-block .cargo-general-size .form-group:nth-child(3) span {
  display: block;
}
.wizard__cargo-general-block .cargo-general-size .form-group .Select-value-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.wizard__cargo-container-block .f-element-select-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.wizard__cargo-vehicle-block {
  margin-bottom: 24px;
  padding-left: 24px;
}
.wizard__cargo-vehicle-block .form-group {
  margin-bottom: 0;
}
.wizard__common-block {
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
  padding: 18px 0;
}
.wizard__common-block_header {
  padding: 0 7px;
}
.wizard__common-block_title {
  font-size: 20px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.wizard__common-block_body {
  display: none;
  border-top: 1px solid #c6cad2;
  padding-top: 18px;
}
.wizard__common-block_body.isShown {
  display: block;
}
.wizard .swap-button-block {
  position: absolute;
  z-index: 100;
  width: 78px;
  height: 78px;
  margin-left: -78px;
  margin-top: 64px;
  border: none;
  cursor: pointer;
  background: url("../img/btn-swap.svg") no-repeat center transparent;
}
.wizard .swap-button-block:hover {
  background: url("../img/btn-swap-dark.svg") no-repeat center transparent;
}
.wizard .wizard__cargo-from_header_location, .wizard .wizard__cargo-from_header_location_row {
  width: 100%;
  padding-left: 24px;
}
.wizard .wizard__cargo-from_header_location .btn-group, .wizard .wizard__cargo-from_header_location_row .btn-group {
  width: 100%;
}
.wizard .wizard__cargo-from_header_location .btn-group .btn.btn-secondary, .wizard .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary {
  height: 40px;
  border: 1px solid #D3D8E0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.wizard .wizard__cargo-from_header_location .btn-group .btn.btn-secondary.disabled, .wizard .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary.disabled {
  color: #D3D8E0;
  background: rgba(214, 214, 214, 0.1);
}
.wizard .wizard__cargo-from_header_location .btn-group .btn.btn-secondary:first-child, .wizard .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary:first-child {
  width: 25%;
  border-radius: 3px 0 0 3px;
}
.wizard .wizard__cargo-from_header_location .btn-group .btn.btn-secondary:last-child, .wizard .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary:last-child {
  border-radius: 0 3px 3px 0;
}
.wizard .wizard__cargo-from_header_location .btn-group .btn.btn-secondary:nth-child(2), .wizard .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary:nth-child(2) {
  width: 25%;
}
.wizard .wizard__cargo-from_header_location .btn-group .btn.btn-secondary:nth-child(3), .wizard .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary:nth-child(3) {
  width: 25%;
}
.wizard .wizard__cargo-from_header_location .btn-group .btn.btn-secondary.isSelected, .wizard .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary.isSelected {
  background: #4996FF;
  border-color: #4996FF;
  color: white;
}
.wizard .wizard__cargo-from_header_location_row {
  padding-left: 12px;
}
.wizard .wizard__cargo-from_header_location_row .wizard__cargo-from_header_location {
  padding-left: 12px !important;
}
.wizard .wizard__direction-block_location {
  width: 100%;
}
.wizard .wizard__direction-block_location .btn-group {
  width: 100%;
}
.wizard .wizard__direction-block_location .btn-group .btn {
  height: 100% !important;
  min-height: 40px;
  border-radius: 0;
  padding: 5px;
  -ms-flex: 25% 1 1;
      flex: 25% 1 1;
}
.wizard .wizard__direction-block_location .btn-group .btn.disabled {
  color: #D3D8E0;
  background: rgba(214, 214, 214, 0.1);
}
.wizard .wizard__direction-block_location .btn-group .btn:first-child {
  border-radius: 3px 0 0 3px;
}
.wizard .wizard__direction-block_location .btn-group .btn:last-child {
  border-radius: 0 3px 3px 0;
}
.wizard .wizard__direction-block_location .btn {
  border: 1px solid #D3D8E0;
  background: transparent;
  border-radius: 3px;
}
.wizard .wizard__direction-block_location .btn:hover, .wizard .wizard__direction-block_location .btn:active, .wizard .wizard__direction-block_location .btn:focus {
  background: transparent;
}
.wizard .wizard__direction-block_location .btn.isSelected {
  background: #4996FF;
  border-color: #4996FF;
  color: white;
}
.wizard .wizard__direction-block_location-placeholder {
  color: #8D98A6;
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 9px;
}
.wizard .block-margin-top {
  margin-top: 28px;
}
.wizard__select-100 .f-control {
  width: 100%;
}
.wizard__select-size-unit {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.wizard__select-size-unit .f-control {
  width: 70px;
}
.wizard__select-weight-unit {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.wizard__select-weight-unit .f-control {
  width: 70px;
}
.wizard__select-custom .f-control {
  width: 100%;
}
.wizard__insurance-form_header {
  padding: 0 7px;
}
.wizard__insurance-form_header .checkbox {
  width: 18px;
  height: 18px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  border: solid 1px;
}
.wizard__insurance-form_title {
  font-size: 20px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.wizard__insurance-form_body {
  display: none;
  padding-top: 18px;
}
.wizard__insurance-form_body.isShown {
  display: block;
}
.wizard .btn.btn-remove {
  background: url("../img/icons/icon-remove.svg") center no-repeat;
}
.wizard .btn.btn-remove:hover, .wizard .btn.btn-remove:focus, .wizard .btn.btn-remove:active {
  background: url("../img/icons/icon-remove-hover.svg") center no-repeat;
}
.wizard .btn-reset, .wizard .btn-search {
  height: 48px;
  border-radius: 3px;
  border: 1px solid;
}
.wizard .btn.btn-reset {
  border-color: #F5541D;
  color: #F5541D;
}
.wizard .btn.btn-reset:hover, .wizard .btn.btn-reset:active, .wizard .btn.btn-reset:focus {
  background-color: rgba(245, 84, 29, 0.05);
  border-color: #F5541D;
}
.wizard .btn.btn-search {
  border: #62CFA8;
  background-color: #62CFA8;
}
.wizard .btn.btn-search:hover, .wizard .btn.btn-search:active, .wizard .btn.btn-search:focus {
  background-color: #40C595;
  border: #40C595;
}
.wizard .customsClearance__price {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 20px;
}
.wizard .customsClearance__desc {
  color: #8D98A6;
  font-size: 14px;
  white-space: pre-wrap;
}
.wizard .f-element-number .react-numeric-input input {
  height: 48px;
  border-radius: 3px;
}
.wizard .f-element-address .geosuggest__input-wrapper input {
  height: 48px;
}
.wizard .f-element-address .geosuggest__input-wrapper input:disabled {
  background-color: #f9f9f9;
}
.wizard .f-element-date .react-datepicker__input-container input {
  height: 38px;
}
.wizard .f-element-date .react-datepicker__input-container input:focus {
  border-color: #4996FF;
}
.wizard .f-element-select .Select-control {
  height: 48px;
  border-radius: 3px;
  border: 1px solid #D3D8E0;
}
.wizard .checkmark {
  min-width: 18px;
}
.wizard .errorMessage {
  color: #dc3545;
  font-size: 80%;
}
.wizard input {
  font-size: 16px;
  color: #000;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.wizard input::-webkit-input-placeholder {
  color: rgba(145, 152, 167, 0.5);
}
.wizard input::-moz-placeholder {
  color: rgba(145, 152, 167, 0.5);
}
.wizard input:-moz-placeholder {
  color: rgba(145, 152, 167, 0.5);
}
.wizard input:-ms-input-placeholder {
  color: rgba(145, 152, 167, 0.5);
}
.wizard .Select-placeholder,
.wizard .Select--single > .Select-control .Select-value {
  padding: 0.5rem 1.5rem 0.5rem 0.75rem;
  line-height: 28px;
}
.wizard .Select {
  scrollbar-width: thin;
  scrollbar-color: #D3D8E0 white;
}
.wizard .Select .Select-menu-outer {
  border-radius: 3px;
}
.wizard .Select *::-webkit-scrollbar {
  width: 6px;
  height: 22px;
}
.wizard .Select ::-webkit-scrollbar-thumb {
  background-color: #D3D8E0;
}
.wizard .Select .Select-arrow-zone {
  background: url("../img/icons/select-arrow-new.svg") center no-repeat;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding-left: 5px;
}
.wizard .Select .Select-arrow-zone .Select-arrow {
  border-width: 0 !important;
}
.wizard .Select.is-open > .Select-control {
  border-radius: 3px;
}
.wizard .Select.is-focused .Select-control {
  border-color: #4996FF !important;
}
.wizard .Select.is-focused .Select-arrow-zone {
  background-image: url("../img/icons/select-arrow-down-new.svg");
}
.wizard .Select-option:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.wizard .Select-option.is-focused {
  background-color: #4996FF !important;
  background-color: var(--#4996FF, );
}
.wizard .Select-option.is-selected {
  background-color: #4996FF !important;
}
.wizard .Select-option.is-selected:hover {
  background-color: #4996FF !important;
}
.wizard .details-order-param {
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.05);
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.05);
  padding: 18px 0 0;
  margin: 0;
  padding-left: 9px !important;
}
@media screen and (min-width: 991px) {
  .wizard .details-order-param {
    padding-left: 23px !important;
    padding-right: 23px;
  }
}
.wizard .details-order-param h1 {
  font-size: 24px;
}
.wizard .details-order-param .f-element-number .react-numeric-input input {
  height: 38px;
}
.wizard .details-order-param .f-element-select .Select-control {
  height: 38px;
  padding: 0;
}
.wizard .details-order-param .Select-placeholder, .wizard .details-order-param .Select--single > .Select-control .Select-value {
  line-height: 22px;
}

.alert-warning-wizard {
  display: -ms-flexbox;
  display: flex;
  background: rgba(248, 175, 36, 0.05);
  border: 1px solid #F8AF24;
  border-left-width: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  color: #333333;
}
.alert-warning-wizard .close {
  background: url("../img/icon-alert-close.svg") no-repeat center transparent;
}
.alert-warning-wizard .warning {
  background: url("../img/icon-warning.svg") no-repeat center transparent;
  width: 24px;
  height: 24px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 30px;
      flex: 0 0 30px;
}
.alert-warning-wizard > button {
  -ms-flex: 0 0 30px;
      flex: 0 0 30px;
}

.wizard_title {
  margin-top: 48px;
  margin-bottom: 32px;
  font-size: 32px;
  color: #333333;
  line-height: 32px;
  font-weight: 400;
}

.wizard_title h1 {
  font-size: 32px;
  color: #333333;
  line-height: 32px;
  font-weight: 400;
}

.wizard_btn_calculate {
  float: right;
  border-radius: 3px;
}

.ui-highlight {
  -webkit-animation: highlight-animation 2s infinite;
          animation: highlight-animation 2s infinite;
}

@-webkit-keyframes highlight-animation {
  0% {
    -webkit-box-shadow: 0 0 5px rgba(228, 96, 109, 0.5);
            box-shadow: 0 0 5px rgba(228, 96, 109, 0.5);
  }
  50% {
    -webkit-box-shadow: 0 0 20px #e4606d;
            box-shadow: 0 0 20px #e4606d;
  }
  100% {
    -webkit-box-shadow: 0 0 5px rgba(228, 96, 109, 0.5);
            box-shadow: 0 0 5px rgba(228, 96, 109, 0.5);
  }
}

@keyframes highlight-animation {
  0% {
    -webkit-box-shadow: 0 0 5px rgba(228, 96, 109, 0.5);
            box-shadow: 0 0 5px rgba(228, 96, 109, 0.5);
  }
  50% {
    -webkit-box-shadow: 0 0 20px #e4606d;
            box-shadow: 0 0 20px #e4606d;
  }
  100% {
    -webkit-box-shadow: 0 0 5px rgba(228, 96, 109, 0.5);
            box-shadow: 0 0 5px rgba(228, 96, 109, 0.5);
  }
}
.toggleDropdown {
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
}
.toggleDropdown__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 18px 22px;
}
.toggleDropdown__label {
  font-size: 20px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.toggleDropdown__body {
  border-top: 1px solid #c6cad2;
  padding: 18px 22px;
}

.calculations-list {
  padding-top: 18px;
  /* Let's get this party started */
  /* Track */
  /* Handle */
}
@media screen and (min-width: 1330px) {
  .calculations-list .pr-1330px-0 {
    padding-right: 0px;
  }
}
.calculations-list .scroll-block {
  max-height: 700px;
  min-height: 500px;
  overflow-y: auto;
  margin-top: 28px;
  margin-bottom: 28px;
}
.calculations-list ::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
.calculations-list ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.calculations-list ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #3AB0D8;
  background-color: var(--primary-active-color, #3AB0D8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.calculations-list ::-webkit-scrollbar-thumb:window-inactive {
  background-color: #3AB0D8;
  background-color: var(--primary-active-color, #3AB0D8);
  opacity: 0.8;
}
.calculations-list .scrollBtn {
  position: absolute;
  z-index: 990;
  display: -ms-flexbox;
  display: flex;
  right: -40px;
  bottom: 80px;
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 38, 115, 0.25);
          box-shadow: 0 0 10px 0 rgba(0, 38, 115, 0.25);
}
.calculations-list .scrollBtn__wrapper {
  position: absolute;
  right: 15px;
  bottom: 0;
  z-index: 100;
}
.calculations-list .scrollBtn__bot {
  bottom: 40px;
}
.calculations-list .scrollBtn-mobile {
  position: fixed;
  bottom: 80px;
}
.calculations-list .scrollBtn-mobile .scrollBtn {
  left: 0;
}
.calculations-list .scrollBtn .fa {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  margin: auto;
  font-size: 20px;
}
.calculations-list .scrollBtn:hover {
  cursor: pointer;
}
.calculations-list__loader {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.calculations-list .legsPreloader {
  padding: 20px 0 5px;
  z-index: 100;
}
.calculations-list .legsPreloader_position_fixed, .calculations-list .legsPreloader_position_absolute {
  bottom: 0;
  background: #fff;
}
.calculations-list .legsPreloader_position_fixed {
  position: fixed;
}
.calculations-list .legsPreloader_position_absolute {
  position: absolute;
}
.calculations-list .legsPreloader__text {
  color: #3AB0D8;
}
.calculations-list .legsPreloader__animation {
  margin-bottom: 15px;
}
.calculations-list .legsPreloader__animation .location-wr {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 15px;
}
.calculations-list .legsPreloader__animation .location-wr:before {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
  -webkit-animation: circlePulse 4s ease-in-out infinite backwards;
  animation: circlePulse 4s ease-in-out infinite backwards;
}
.calculations-list .legsPreloader__animation .location-wr:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.calculations-list .legsPreloader__animation .location-wr.truck:before {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.calculations-list .legsPreloader__animation .location-wr.truck:after {
  background-image: url("../img/menu/truck_menu.png");
  background-size: 20px 24px;
}
.calculations-list .legsPreloader__animation .location-wr.sea:before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.calculations-list .legsPreloader__animation .location-wr.sea:after {
  background-image: url("../img/menu/sea_menu.png");
  background-size: 20px 24px;
}
.calculations-list .legsPreloader__animation .location-wr.air:before {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.calculations-list .legsPreloader__animation .location-wr.air:after {
  background-image: url("../img/menu/air_menu.png");
  background-size: 30px 20px;
}
.calculations-list .legsPreloader__animation .path {
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--primary-color, #3AB0D8)), color-stop(0%, transparent));
  background-image: linear-gradient(to right, var(--primary-color, #3AB0D8) 50%, transparent 0%);
  background-size: 4px 2px;
  background-repeat: repeat-x;
  background-position: center;
  -webkit-animation: lineWidth 4s ease-in-out infinite backwards;
  animation: lineWidth 4s ease-in-out infinite backwards;
}
.calculations-list .legsPreloader__animation .path-wr {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.calculations-list .legsPreloader__animation .path-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.calculations-list .legsPreloader__animation .path-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.calculations-list .legsPreloader__animation .path-4 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@-webkit-keyframes lineWidth {
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
}
@keyframes lineWidth {
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
}
@-webkit-keyframes circlePulse {
  0% {
    width: 30px;
    height: 30px;
    opacity: 0;
  }
  25% {
    width: 40px;
    height: 40px;
    opacity: 1;
  }
}
@keyframes circlePulse {
  0% {
    width: 30px;
    height: 30px;
    opacity: 0;
  }
  25% {
    width: 40px;
    height: 40px;
    opacity: 1;
  }
}
.calculations-list__order-info {
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
}
.calculations-list__order-info .container-px-0 {
  padding-right: 0px;
  padding-left: 0px;
}
@media screen and (max-width: 991px) {
  .calculations-list__order-info .container-px-0 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.calculations-list__order-info.block-margin-top {
  margin-top: 28px;
}
.calculations-list__order-info_header {
  padding: 38px 32px 37px;
  background-color: var(--white, );
  border-radius: 6px 6px 0 0;
}
.calculations-list__order-info_header.block-margin-top {
  margin-top: 28px;
}
.calculations-list__order-info_header p {
  font-size: 24px;
  line-height: 24px;
  color: #333;
  margin: 0;
}
.calculations-list__order-info_header div.hr {
  background-color: rgba(211, 216, 224, 0.5);
  height: 1px;
  margin-top: 26px;
}
.calculations-list__order-info_header p.title {
  font-size: 24px;
  line-height: 24px;
  color: #333;
  margin: 0;
}
.calculations-list__order-info_header p.date {
  font-size: 18px;
  line-height: 24px;
  color: #8d98a6;
  margin-top: 8px;
}
.calculations-list__order-info_header_mobile {
  background-color: #fff;
}
.calculations-list__order-info_header_mobile .line {
  width: 100%;
  height: 1px;
  background-color: #8d98a6;
}
.calculations-list__order-info_header_mobile .title-mobile {
  font-size: 18px;
  color: #313131;
  padding: 24px 10px 0 10px;
}
.calculations-list__order-info_header_mobile .title-mobile .list-location .main-block {
  margin-left: 10px;
  -ms-flex-align: start;
      align-items: flex-start;
  margin-top: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.calculations-list__order-info_header_mobile .title-mobile .list-location .item-block {
  padding-left: 30px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 16px;
}
.calculations-list__order-info_header_mobile .title-mobile .list-location .item-graph {
  position: absolute;
  top: 3px;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 9px;
  height: 100%;
}
.calculations-list__order-info_header_mobile .title-mobile .list-location .item-graph-pointer {
  position: absolute;
  top: 0px;
  width: 30px;
  height: 30px;
  min-height: 9px;
  background: url("../img/icons/blue/pointer-icon-mobile.svg") no-repeat center center;
}
.calculations-list__order-info_header_mobile .title-mobile .list-location .item-graph-doter {
  position: absolute;
  top: 33px;
  height: calc(100% - 6px - 15px);
  width: 1px;
  border: 1px dashed #8d98a6;
}
.calculations-list__order-info_header_mobile .title-mobile .list-location .item-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.calculations-list__order-info_header_mobile .title-mobile .header-mobile {
  font-size: 24px;
}
.calculations-list__order-info_header_mobile .title-mobile .text-size-18 {
  font-size: 18px;
}
.calculations-list__order-info_header_mobile .title-mobile .text-size-14 {
  font-size: 14px;
}
.calculations-list__order-info_header_mobile .title-mobile .text-size-12 {
  font-size: 12px;
}
.calculations-list__order-info_header_mobile .title-mobile .text-color-lg {
  color: #8d98a6;
}
.calculations-list__order-info_header_mobile .title-mobile div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.calculations-list__order-info_header_mobile .title-mobile div .icon-mobile-arrow {
  background: url("../img/icons/mobile-arrow.png") no-repeat center;
  background-size: cover;
  width: 11px;
  height: 11px;
  margin: 0 10px;
}
.calculations-list__order-info_header_mobile .title-mobile div .cargo-general-mobile-icon {
  background: url("../img/icons/general-mobile-icon.png") no-repeat center;
  background-size: cover;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.calculations-list__order-info_header_mobile .title-mobile div .cargo-container-mobile-icon {
  background: url("../img/icons/container-mobile-icon.png") no-repeat center;
  background-size: cover;
  width: 19px;
  height: 18px;
  margin-right: 10px;
}
.calculations-list__order-info_header_mobile .title-mobile div .cargo-vehicle-mobile-icon {
  background: url("../img/icons/vehicle-mobile-icon.png") no-repeat center;
  background-size: cover;
  width: 23px;
  height: 23px;
  margin-right: 10px;
}
.calculations-list__order-info_header_mobile .date {
  margin-top: 10px;
  font-size: 18px;
  color: #313131;
}
.calculations-list__order-info_body {
  padding-left: 32px;
  padding-right: 32px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
}
.calculations-list__order-info_body p {
  font-size: 16px;
  color: #000;
  margin: 4px 0;
}
.calculations-list__order-info_body p.title {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: #8d98a6;
}
.calculations-list__order-info_body_cargo .my-40px {
  margin-top: 20px;
  margin-bottom: 20px;
}
.calculations-list__order-info_body_cargo .mb-35px {
  margin-bottom: calc(35px - 1.5rem);
}
.calculations-list__order-info_body_cargo .container-px-0 {
  padding-right: 0px;
  padding-left: 0px;
}
@media screen and (max-width: 991px) {
  .calculations-list__order-info_body_cargo .container-px-0 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.calculations-list__order-info_body_cargo .no-line {
  border-bottom: none;
  padding: 5px 0 0;
}
.calculations-list__order-info_body_cargo .short-view {
  display: none;
}
.calculations-list__order-info_body_cargo .short-view div {
  display: inline-block;
}
.calculations-list__order-info_body_cargo .wide-view {
  display: block;
}
@media screen and (max-width: 1304px) {
  .calculations-list__order-info_body_cargo .wide-view {
    display: none;
  }
  .calculations-list__order-info_body_cargo .short-view {
    display: block;
  }
}
.calculations-list__order-info_body_cargo div.hr {
  background-color: rgba(211, 216, 224, 0.5);
  height: 1px;
  width: 100%;
  margin-top: 0px;
}
.calculations-list__order-info_body_cargo .line {
  border-bottom: 1px solid #c6cad2;
  padding: 5px 0;
}
.calculations-list__order-info_body_cargo .text-size-18 {
  font-size: 18px;
}
.calculations-list__order-info_body_cargo .text-size-14 {
  font-size: 14px;
}
.calculations-list__order-info_body_cargo .text-size-12 {
  font-size: 12px;
}
.calculations-list__order-info_body_cargo .text-color-lg {
  color: #8d98a6;
}
.calculations-list__order-info_body_cargo div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.calculations-list__order-info_body_cargo div .icon-mobile-arrow {
  background: url("../img/icons/mobile-arrow.png") no-repeat center;
  background-size: cover;
  width: 11px;
  height: 11px;
  margin: 0 10px;
}
.calculations-list__order-info_body_cargo div .cargo-general-mobile-icon {
  background: url("../img/icons/general-mobile-icon.png") no-repeat center;
  background-size: cover;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.calculations-list__order-info_body_cargo div .cargo-container-mobile-icon {
  background: url("../img/icons/container-mobile-icon.png") no-repeat center;
  background-size: cover;
  width: 19px;
  height: 18px;
  margin-right: 10px;
}
.calculations-list__order-info_body_cargo div .cargo-vehicle-mobile-icon {
  background: url("../img/icons/vehicle-mobile-icon.png") no-repeat center;
  background-size: cover;
  width: 23px;
  height: 23px;
  margin-right: 10px;
}
.calculations-list__order-info_body_cargo .list-location {
  margin-bottom: 17px;
}
.calculations-list__order-info_body_cargo .list-location .text-size-18 {
  font-size: 18px;
}
.calculations-list__order-info_body_cargo .list-location .text-size-14 {
  font-size: 14px;
}
.calculations-list__order-info_body_cargo .list-location .text-size-12 {
  font-size: 12px;
}
.calculations-list__order-info_body_cargo .list-location .text-color-lg {
  color: #8d98a6;
}
.calculations-list__order-info_body_cargo .list-location .main-block {
  -ms-flex-align: start;
      align-items: flex-start;
  margin-top: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.calculations-list__order-info_body_cargo .list-location .item-block {
  padding-left: 30px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 16px;
}
.calculations-list__order-info_body_cargo .list-location .item-graph {
  position: absolute;
  top: 3px;
  left: 7px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 9px;
  height: 100%;
}
.calculations-list__order-info_body_cargo .list-location .item-graph-pointer {
  position: absolute;
  top: 0px;
  width: 30px;
  height: 30px;
  min-height: 9px;
  background: url("../img/icons/blue/pointer-icon-mobile.svg") no-repeat center center;
}
.calculations-list__order-info_body_cargo .list-location .item-graph-doter {
  position: absolute;
  top: 33px;
  height: calc(100% - 6px - 15px);
  width: 1px;
  border: 1px dashed #8d98a6;
}
.calculations-list__order-info_body_cargo .list-location .item-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.calculations-list__order-info_body_cargo .line .cargo-info,
.calculations-list__order-info_body_cargo .no-line .cargo-info {
  -ms-flex-align: center;
      align-items: center;
}
.calculations-list__order-info_body_cargo .line .cargo-info p,
.calculations-list__order-info_body_cargo .no-line .cargo-info p {
  font-size: 14px;
}
.calculations-list__order-info_body_cargo .line .cargo-info p span,
.calculations-list__order-info_body_cargo .no-line .cargo-info p span {
  max-width: 85px;
}
.calculations-list__order-info_body_cargo .line p,
.calculations-list__order-info_body_cargo .no-line p {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.calculations-list__order-info_body_cargo .line p .from-location-icon,
.calculations-list__order-info_body_cargo .no-line p .from-location-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/arrowLocation-up.svg");
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
.calculations-list__order-info_body_cargo .line p .from-location-icon.color-yellow,
.calculations-list__order-info_body_cargo .no-line p .from-location-icon.color-yellow {
  background-image: url("../img/icons/yellow/arrowLocation-up.svg");
}
.calculations-list__order-info_body_cargo .line p .to-location-icon,
.calculations-list__order-info_body_cargo .no-line p .to-location-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/arrowLocation-down.svg");
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
.calculations-list__order-info_body_cargo .line p .to-location-icon.color-yellow,
.calculations-list__order-info_body_cargo .no-line p .to-location-icon.color-yellow {
  background-image: url("../img/icons/yellow/arrowLocation-down.svg");
}
.calculations-list__order-info_body_cargo .line p .cargo-container-icon,
.calculations-list__order-info_body_cargo .no-line p .cargo-container-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/containerCargo.svg");
  min-width: 23px;
  height: 21px;
  margin-right: 12px;
}
.calculations-list__order-info_body_cargo .line p .cargo-container-icon.color-yellow,
.calculations-list__order-info_body_cargo .no-line p .cargo-container-icon.color-yellow {
  background-image: url("../img/icons/yellow/containerCargo.svg");
}
.calculations-list__order-info_body_cargo .line p .cargo-general-icon,
.calculations-list__order-info_body_cargo .no-line p .cargo-general-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/generalCargo.svg");
  width: 20px;
  height: 18px;
  margin-right: 12px;
}
.calculations-list__order-info_body_cargo .line p .cargo-general-icon.color-yellow,
.calculations-list__order-info_body_cargo .no-line p .cargo-general-icon.color-yellow {
  background-image: url("../img/icons/yellow/generalCargo.svg");
}
.calculations-list__order-info_body_cargo .line p .cargo-vehicle-icon,
.calculations-list__order-info_body_cargo .no-line p .cargo-vehicle-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/vehicleCargo.svg");
  width: 25px;
  height: 25px;
  margin-right: 12px;
}
.calculations-list__order-info_body_cargo .line p .cargo-vehicle-icon.color-yellow,
.calculations-list__order-info_body_cargo .no-line p .cargo-vehicle-icon.color-yellow {
  background-image: url("../img/icons/yellow/vehicleCargo.svg");
}
.calculations-list__order-info_body_cargo .btn {
  width: 205px;
}
.calculations-list__order-info_body_cargo .customs .value {
  text-align: right;
}
.calculations-list__order-info_body .custom_tooltip {
  position: relative;
  display: inline-block;
  /* cursor: pointer;*/
}
.calculations-list__order-info_body .custom_tooltip__text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.calculations-list__order-info_body .custom_tooltip:hover .custom_tooltip__text {
  /*visibility: visible;*/
}
.calculations-list__filter {
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
}
.calculations-list__filter.block-margin-top {
  margin-top: 28px;
}
.calculations-list__filter p {
  margin: 0;
}
.calculations-list__filter_mobile {
  background-color: transparent;
}
.calculations-list__filter_mobile_body {
  font-size: 16px;
  color: #9198a7;
}
.calculations-list__filter_mobile_body .form-group {
  margin-bottom: 0px;
}
.calculations-list__filter_mobile_body .total-count {
  margin-left: auto;
}
.calculations-list__filter_mobile_body .total-count p {
  color: #8d98a6;
  line-height: 42px;
}
.calculations-list__filter_mobile_body .total-count p .number {
  color: #000;
  margin-left: 7px;
}
@media screen and (max-width: 1065px) {
  .calculations-list__filter_mobile_body .total-count {
    margin-left: 0px;
  }
  .calculations-list__filter_mobile_body .total-count p {
    line-height: 30px;
  }
}
.calculations-list__filter_mobile_body p {
  margin-bottom: 0px;
}
.calculations-list__filter_mobile_body .params {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.calculations-list__filter_mobile_body .params p {
  margin-right: 5px;
}
.calculations-list__filter_mobile_body .params .btn.btn-filter {
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  -ms-flex-align: center;
      align-items: center;
  line-height: 1;
}
.calculations-list__filter_mobile_body .params .btn.btn-filter .filter-arrow-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/filter-arrow.svg");
  height: 17px;
  width: 11px;
  margin-left: 5px;
}
.calculations-list__filter_mobile_body .params .btn.btn-filter .filter-arrow-icon.color-yellow {
  background-image: url("../img/icons/yellow/filter-arrow.svg");
}
.calculations-list__filter_mobile_body .params .currency-select {
  width: 120px;
  margin-bottom: 0;
}
.calculations-list__filter_mobile_body .params .currency-select .Select-control {
  padding-right: 0.2rem;
}
.calculations-list__filter_mobile_body .params .currency-select .Select-arrow-zone {
  padding-right: 0;
}
.calculations-list__filter_list .btn.btn-filter.disabled {
  opacity: 0.4;
}
.calculations-list__filter_list .btn.btn-filter.disabled:hover, .calculations-list__filter_list .btn.btn-filter.disabled.active {
  opacity: 0.4;
}
.calculations-list__calculation {
  position: relative;
  margin-bottom: 28px;
  padding-bottom: 1em;
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
}
.calculations-list__calculation .loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(51, 51, 51, 0.3);
  height: 100%;
  width: 100%;
  z-index: 9999;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  border-radius: 3px;
}
.calculations-list__calculation .loader .fail {
  max-width: 350px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  color: #333;
}
.calculations-list__calculation .loader .fail .row:nth-child(2) {
  margin-top: 10px;
}
.calculations-list__calculation .loader .fail .row:nth-child(2) .col-6:nth-child(1) {
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
@media screen and (max-width: 1199px) {
  .calculations-list__calculation .loader .fail {
    margin: 20px;
    text-align: center;
  }
}
.calculations-list__calculation .loader .fail .update_all {
  color: #3AB0D8;
  cursor: pointer;
}
.calculations-list__calculation.block-margin-top {
  margin-top: 28px;
}
.calculations-list__calculation:last-child {
  margin-bottom: 0;
}
.calculations-list__calculation_alert {
  border-bottom: 1px solid #c6cad2;
  color: #ffa500;
  font-size: 16px;
  padding: 10px 0;
}
.calculations-list__calculation_alert p {
  margin: 0;
  padding-right: 20px;
}
.calculations-list__calculation .pl-38px {
  padding-left: 21px;
}
.calculations-list__calculation .d-none-1 {
  display: none;
}
.calculations-list__calculation .d-block-1 {
  display: block;
}
.calculations-list__calculation .d-lg-block-1 {
  display: block;
}
.calculations-list__calculation .d-lg-none-1 {
  display: none;
}
.calculations-list__calculation .d-none-2 {
  display: none;
}
.calculations-list__calculation .d-block-2 {
  display: block;
}
.calculations-list__calculation .d-lg-block-2 {
  display: block;
}
.calculations-list__calculation .d-lg-none-2 {
  display: none;
}
@media screen and (max-width: 1330px) {
  .calculations-list__calculation .d-block-2 {
    display: block;
  }
  .calculations-list__calculation .d-lg-block-2 {
    display: none;
  }
}
@media screen and (min-width: 1330px) {
  .calculations-list__calculation .d-block-2 {
    display: none;
  }
  .calculations-list__calculation .d-lg-block-2 {
    display: block;
  }
}
@media screen and (max-width: 1199px) {
  .calculations-list__calculation .d-block-1 {
    display: block;
  }
  .calculations-list__calculation .d-lg-block-1 {
    display: none;
  }
}
@media screen and (min-width: 1199px) {
  .calculations-list__calculation .d-block-1 {
    display: none;
  }
  .calculations-list__calculation .d-lg-block-1 {
    display: block;
  }
}
.calculations-list__calculation_path {
  padding-top: 24px;
  padding-bottom: 24px;
  /* border: 1px solid $elementGray-color;*/
  margin-top: 15px;
}
.calculations-list__calculation_path_location-block {
  font-size: 16px;
  color: #9198a7;
}
.calculations-list__calculation_path_location-block .location-list-legs .main-block {
  margin-left: 10px;
  -ms-flex-align: start;
      align-items: flex-start;
  margin-top: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-block {
  padding-left: 15px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 16px;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-graph {
  position: absolute;
  top: 3px;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 9px;
  height: 100%;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-graph-pointer {
  position: absolute;
  top: 0px;
  width: 30px;
  height: 30px;
  min-height: 9px;
  /*background: url("../img/icons/blue/pointer-icon-mobile.svg") no-repeat center center;*/
}
.calculations-list__calculation_path_location-block .location-list-legs .item-graph-doter {
  position: absolute;
  top: 33px;
  left: 15px;
  height: calc(100% - 6px - 15px);
  width: 1px;
  border: 1px dashed #8d98a6;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content {
  width: 100%;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content .location-name {
  font-size: 18px;
  /*text-overflow:ellipsis;*/
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content .location-name .integrator_title {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  max-height: 22px;
  margin-top: auto;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content .location-name .date {
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 2px;
  color: #3AB0D8;
  border: 1px solid #3AB0D8;
  padding: 2px;
  font-size: 12px;
  line-height: 16px;
  max-width: 70px;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content .location-name .country {
  color: #8d98a6;
  font-size: 14px;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content .location-name p {
  overflow: hidden;
  margin-bottom: 0;
  color: #000;
}
@media screen and (max-width: 1199px) {
  .calculations-list__calculation_path_location-block .location-list-legs .item-content .location-name p {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content .show-pc {
  display: none;
}
@media screen and (min-width: 991px) and (max-width: 1199px) {
  .calculations-list__calculation_path_location-block .location-list-legs .item-content .hide-pc {
    display: none;
  }
  .calculations-list__calculation_path_location-block .location-list-legs .item-content .show-pc {
    display: block;
  }
}
@media screen and (min-width: 1199px) {
  .calculations-list__calculation_path_location-block .location-list-legs .item-content .d-lg-block-1 {
    display: block;
  }
}
@media screen and (max-width: 1199px) {
  .calculations-list__calculation_path_location-block .location-list-legs .item-content .d-lg-none-1 {
    display: block;
  }
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content .show-only-pc {
  display: block;
}
.calculations-list__calculation_path_location-block .location-list-legs .item-content .show-only-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .calculations-list__calculation_path_location-block .location-list-legs .item-content .show-only-pc {
    display: none;
  }
  .calculations-list__calculation_path_location-block .location-list-legs .item-content .show-only-mobile {
    display: block;
  }
}
@media screen and (min-width: 1199px) {
  .calculations-list__calculation_path_location-block .location-list-legs .item-content .location-name {
    font-size: 18px;
    line-height: 24px;
  }
}
.calculations-list__calculation_path_location-block .location-list-legs .location-dot-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/dot.svg");
  min-width: 6px;
  height: 6px;
  margin: 0 9px;
}
.calculations-list__calculation_path_location-block .location-list-legs .location-dot-icon.color-yellow {
  background-image: url("../img/icons/yellow/dot.svg");
}
.calculations-list__calculation_path_location-block .location-list-legs .location-arrow-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/icon-arrow-left.svg");
  min-width: 16px;
  height: 8px;
}
.calculations-list__calculation_path_location-block .location-list-legs .location-arrow-icon.color-yellow {
  background-image: url("../img/icons/yellow/icon-arrow-left.svg");
}
.calculations-list__calculation_path_location-block .location-list-legs .location-arrow-icon-down {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/icon-arrow-left.svg");
  background-size: cover;
  min-width: 14px;
  width: 14px;
  height: 10px;
  margin: 0 9px;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.calculations-list__calculation_path_location-block .location-list-legs .location-arrow-icon-down.color-yellow {
  background-image: url("../img/icons/yellow/icon-arrow-left.svg");
}
.calculations-list__calculation_path_location-block .location-list-legs .sea-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/sea-new-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-left: 4px;
}
.calculations-list__calculation_path_location-block .location-list-legs .sea-icon.color-yellow {
  background-image: url("../img/icons/yellow/sea-new-icon.svg");
}
.calculations-list__calculation_path_location-block .location-list-legs .truck-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/truck-new-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-left: 4px;
}
.calculations-list__calculation_path_location-block .location-list-legs .truck-icon.color-yellow {
  background-image: url("../img/icons/yellow/truck-new-icon.svg");
}
.calculations-list__calculation_path_location-block .location-list-legs .complex-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/complex-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-left: 4px;
}
.calculations-list__calculation_path_location-block .location-list-legs .complex-icon.color-yellow {
  background-image: url("../img/icons/yellow/complex-icon.svg");
}
.calculations-list__calculation_path_location-block .location-list-legs .air-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/air-new-icon.svg");
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-left: 4px;
}
.calculations-list__calculation_path_location-block .location-list-legs .air-icon.color-yellow {
  background-image: url("../img/icons/yellow/air-new-icon.svg");
}
.calculations-list__calculation_path_location-block .location-list-legs .train-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/train-new-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-left: 4px;
}
.calculations-list__calculation_path_location-block .location-list-legs .train-icon.color-yellow {
  background-image: url("../img/icons/yellow/train-new-icon.svg");
}
.calculations-list__calculation_path_location-block .location-list-legs .pointer-icon-mobile {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/pointer-icon-mobile.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-left: 4px;
}
.calculations-list__calculation_path_location-block .location-list-legs .pointer-icon-mobile.color-yellow {
  background-image: url("../img/icons/yellow/pointer-icon-mobile.svg");
}
.calculations-list__calculation_path_location-block .location {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 18px;
  color: #000;
  margin-bottom: 8px;
}
.calculations-list__calculation_path_location-block .location .location-dot-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/dot.svg");
  min-width: 6px;
  height: 6px;
  margin: 0 9px;
}
.calculations-list__calculation_path_location-block .location .location-dot-icon.color-yellow {
  background-image: url("../img/icons/yellow/dot.svg");
}
.calculations-list__calculation_path_location-block .location .sea-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/sea-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_path_location-block .location .sea-icon.color-yellow {
  background-image: url("../img/icons/yellow/sea-icon.svg");
}
.calculations-list__calculation_path_location-block .location .truck-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/truck-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_path_location-block .location .truck-icon.color-yellow {
  background-image: url("../img/icons/yellow/truck-icon.svg");
}
.calculations-list__calculation_path_location-block .location .complex-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/complex-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_path_location-block .location .complex-icon.color-yellow {
  background-image: url("../img/icons/yellow/complex-icon.svg");
}
.calculations-list__calculation_path_location-block .location .air-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/air-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_path_location-block .location .air-icon.color-yellow {
  background-image: url("../img/icons/yellow/air-icon.svg");
}
.calculations-list__calculation_path_location-block .location .train-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/train-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_path_location-block .location .train-icon.color-yellow {
  background-image: url("../img/icons/yellow/train-icon.svg");
}
.calculations-list__calculation_path_location-block .integrator {
  font-size: 14px;
}
.calculations-list__calculation_path_location-block .integrator p {
  margin: 0;
}
.calculations-list__calculation_path .needToKnow {
  color: #ffa500;
  margin-bottom: 10px;
}
.calculations-list__calculation .d-none-1 {
  display: none;
}
.calculations-list__calculation .d-block-1 {
  display: block;
}
.calculations-list__calculation .d-lg-block-1 {
  display: block;
}
.calculations-list__calculation .d-lg-none-1 {
  display: none;
}
@media screen and (max-width: 1199px) {
  .calculations-list__calculation .d-block-1 {
    display: block;
  }
  .calculations-list__calculation .d-lg-block-1 {
    display: none;
  }
}
@media screen and (min-width: 1199px) {
  .calculations-list__calculation .d-block-1 {
    display: none;
  }
  .calculations-list__calculation .d-lg-block-1 {
    display: block;
  }
}
.calculations-list__calculation_price_btn .details-btn {
  border: 1px solid #4996FF;
  border-radius: 3px;
  background: rgba(73, 150, 255, 0.1);
  opacity: 0.8;
  color: #3AB0D8;
  width: 100%;
}
.calculations-list__calculation_price_btn .details-btn:hover {
  opacity: 1;
}
.calculations-list__calculation_price {
  margin-top: 32px;
  padding: 4px 17px 12px 21px;
}
@media screen and (min-width: 1199px) {
  .calculations-list__calculation_price {
    border-left: 1px solid rgba(211, 216, 224, 0.5);
  }
}
.calculations-list__calculation_price .copy-link {
  font-size: 14px;
  line-height: 24px;
  color: #3AB0D8;
  text-decoration: none;
  padding: 0;
  vertical-align: middle;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #3AB0D8;
  border-radius: 0;
}
.calculations-list__calculation_price .copy-link:hover {
  border-bottom-color: transparent;
}
.calculations-list__calculation_price p {
  margin: 0;
}
.calculations-list__calculation_price .cost-label {
  color: #8d98a6;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 7px;
}
.calculations-list__calculation_price .cost {
  cursor: default;
  font-size: 24px;
  line-height: 24px;
  color: #333;
  margin-bottom: 20px;
}
.calculations-list__calculation_price .cost.blur {
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.calculations-list__calculation_price .cost .blur {
  cursor: pointer;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.calculations-list__calculation_price .cost .nowrap {
  white-space: nowrap;
}
@media screen and (min-width: 1199px) {
  .calculations-list__calculation .d-block-1 {
    display: none;
  }
  .calculations-list__calculation .d-lg-block-1 {
    display: block;
  }
}
.calculations-list__calculation_price_btn .details-btn {
  border: 1px solid #4996FF;
  border-radius: 3px;
  background: rgba(73, 150, 255, 0.1);
  opacity: 0.8;
  color: #3AB0D8;
  width: 100%;
}
.calculations-list__calculation_price_btn .details-btn:hover {
  opacity: 1;
}
.calculations-list__calculation_price {
  margin-top: 32px;
  padding: 4px 17px 12px 21px;
}
@media screen and (min-width: 1199px) {
  .calculations-list__calculation_price {
    border-left: 1px solid rgba(211, 216, 224, 0.5);
  }
}
.calculations-list__calculation_price .copy-link {
  font-size: 14px;
  line-height: 24px;
  color: #3AB0D8;
  text-decoration: none;
  padding: 0;
  vertical-align: middle;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #3AB0D8;
  border-radius: 0;
}
.calculations-list__calculation_price .copy-link:hover {
  border-bottom-color: transparent;
}
.calculations-list__calculation_price p {
  margin: 0;
}
.calculations-list__calculation_price .cost-label {
  color: #8d98a6;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 7px;
}
.calculations-list__calculation_price .cost {
  cursor: default;
  font-size: 24px;
  line-height: 24px;
  color: #333;
  margin-bottom: 20px;
}
.calculations-list__calculation_price .cost .blur {
  cursor: pointer;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.calculations-list__calculation_price .cost.blur {
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.calculations-list__calculation_price .cost .nowrap {
  white-space: nowrap;
}
.calculations-list__calculation_price .cost-mobile {
  font-size: 24px;
  color: #000;
  margin-bottom: 20px;
}
.calculations-list__calculation_price .cost-mobile .nowrap {
  white-space: nowrap;
}
.calculations-list__calculation_price .integrator {
  color: #000;
  font-size: 14px;
}
.calculations-list__calculation_price .integrator .name-field {
  color: #8d98a6;
}
.calculations-list__calculation_price .details-btn {
  border: 1px solid #4996FF;
  border-radius: 3px;
  background: rgba(73, 150, 255, 0.1);
  opacity: 0.8;
  color: #3AB0D8;
  width: 100%;
}
.calculations-list__calculation_price .details-btn:hover {
  opacity: 1;
}
.calculations-list__calculation_price .btn-save {
  background-color: transparent;
  border: 1px solid #3AB0D8;
  border-radius: 3px;
  color: #3AB0D8;
  width: 100%;
  text-align: center;
}
.calculations-list__calculation_price .btn-save:hover {
  background-color: #3AB0D8;
  color: #fff;
}
.calculations-list__calculation_price .btn-create-order {
  border-radius: 3px;
}
.calculations-list__calculation_price .tooltip-btn-order-outer {
  z-index: 1059;
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.calculations-list__calculation_price .tooltip-btn-order {
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  margin-top: calc(0.5rem + 1px);
}
.calculations-list__calculation_price .tooltip-btn-order .inner .body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.calculations-list__calculation_price .tooltip-btn-order .arrow {
  top: calc(-0.5rem - 1px);
}
.calculations-list__calculation_price .tooltip-btn-order .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.calculations-list__calculation_price .tooltip-btn-order .arrow:after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.calculations-list__calculation_price .btn-mail {
  background: url("../img/icons/mail-send.svg") no-repeat center center;
  height: 18px;
  width: 22px;
}
.calculations-list__calculation_price .btn-mail-text {
  vertical-align: center;
  line-height: 20px;
  margin-left: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  color: #313131;
}
.calculations-list__calculation_price .btn-copy {
  background: url("../img/orders/copy-icon.svg") no-repeat center center;
  height: 18px;
  width: 22px;
}
.calculations-list__calculation_price .btn-discussPrice {
  background: url("../img/orders/order-discuss.svg") no-repeat center center;
  height: 24px;
  width: 24px;
}
.calculations-list__calculation_price .btn-copy-text {
  vertical-align: center;
  line-height: 20px;
  margin-left: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  color: #3AB0D8;
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #3AB0D8;
  border-radius: 0;
}
.calculations-list__calculation_price .btn-copy-text:hover {
  border-bottom-color: transparent;
}
.calculations-list__calculation_collapse-title {
  padding: 15px 7px 0;
  font-size: 20px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.calculations-list__calculation_collapse-title p {
  margin: 0;
}
.calculations-list__calculation .table_full {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px solid #F8AF24;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
}
.calculations-list__calculation .table_full .background-with-yellow {
  background-color: rgba(248, 175, 36, 0.2);
  padding-bottom: 12px;
  padding-top: 12px;
}
.calculations-list__calculation .table_full .label-title {
  color: #F8AF24;
}
.calculations-list__calculation .table_full .background-without-yellow {
  padding-bottom: 12px;
  padding-top: 12px;
}
.calculations-list__calculation .new-table {
  padding-left: 3px;
  margin-right: 20px;
}
.calculations-list__calculation .new-table .sea-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/sheep-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation .new-table .sea-icon.color-yellow {
  background-image: url("../img/icons/yellow/sheep-green-icon.svg");
}
.calculations-list__calculation .new-table .truck-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/truck-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation .new-table .truck-icon.color-yellow {
  background-image: url("../img/icons/yellow/truck-green-icon.svg");
}
.calculations-list__calculation .new-table .complex-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/complex-green.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation .new-table .complex-icon.color-yellow {
  background-image: url("../img/icons/yellow/complex-green.svg");
}
.calculations-list__calculation .new-table .air-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/air-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation .new-table .air-icon.color-yellow {
  background-image: url("../img/icons/yellow/air-green-icon.svg");
}
.calculations-list__calculation .new-table .train-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/train-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation .new-table .train-icon.color-yellow {
  background-image: url("../img/icons/yellow/train-green-icon.svg");
}
.calculations-list__calculation .new-table .item-block {
  padding-left: 15px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 16px;
}
.calculations-list__calculation .new-table .item-graph {
  position: absolute;
  top: 28px;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 9px;
  height: 100%;
}
.calculations-list__calculation .new-table .item-graph-pointer {
  position: absolute;
  top: 0px;
  width: 40px;
  height: 40px;
  min-height: 40px;
  /*background: url("../img/icons/blue/pointer-icon-mobile.svg") no-repeat center center;*/
}
.calculations-list__calculation .new-table .item-graph-doter {
  position: absolute;
  top: 33px;
  left: 19px;
  height: 100%;
  width: 1px;
  border: 1px solid #62CFA8;
}
.calculations-list__calculation_table_summary {
  border-radius: 3px;
}
.calculations-list__calculation_table_summary table {
  border: 1px solid #F8AF24;
  width: 100%;
}
.calculations-list__calculation_table_summary table th {
  vertical-align: middle;
  padding-left: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: none;
  color: #F1A618;
  font-size: 12px;
  font-weight: normal;
  background-color: rgba(248, 175, 36, 0.1);
}
.calculations-list__calculation_table_summary table th:nth-last-child(1) {
  padding-right: 6px;
}
.calculations-list__calculation_table_summary table td:nth-last-child(1) {
  padding-right: 6px;
}
.calculations-list__calculation_table_summary table td {
  vertical-align: middle;
  border: none;
  padding-left: 6px;
  line-height: 24px;
  /*padding-top: 9px;
  padding-bottom: 7px;*/
  font-size: 14px;
  color: #333;
}
.calculations-list__calculation_table_summary table td.price {
  color: #ff0000;
}
.calculations-list__calculation_table_summary table td .price {
  color: #ff0000;
}
.calculations-list__calculation_table_summary table tr:nth-of-type(even) {
  background-color: rgba(248, 175, 36, 0.1);
}
.calculations-list__calculation_table_summary table tr.total-cost td {
  color: #333;
  font-size: 16px;
  vertical-align: middle;
  padding-top: 9px;
  padding-bottom: 7px;
  line-height: 24px;
  font-weight: 700;
}
.calculations-list__calculation_table_summary table tr.total-cost .priceOverTime {
  color: #000;
  font-size: 13px;
}
.calculations-list__calculation .js-calculation-report-part .document-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/customs-clearance-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 3px;
}
.calculations-list__calculation .js-calculation-report-part .document-icon.color-yellow {
  background-image: url("../img/icons/yellow/customs-clearance-green-icon.svg");
}
.calculations-list__calculation .js-calculation-report-part .item-block {
  padding-left: 15px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 16px;
}
.calculations-list__calculation .js-calculation-report-part .item-graph {
  position: absolute;
  top: 28px;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 9px;
  height: 100%;
}
.calculations-list__calculation .js-calculation-report-part .item-graph-pointer {
  position: absolute;
  top: 0px;
  width: 40px;
  height: 40px;
  min-height: 40px;
  /*background: url("../img/icons/blue/pointer-icon-mobile.svg") no-repeat center center;*/
}
.calculations-list__calculation .js-calculation-report-part .item-graph-doter {
  position: absolute;
  top: 33px;
  left: 19px;
  height: 100%;
  width: 1px;
  border: 1px solid #62CFA8;
}
.calculations-list__calculation_table_total .js-integrator-reward-title,
.calculations-list__calculation_table_total .js-integrator-customs-title,
.calculations-list__calculation_table_total .js-integrator-insurance-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.calculations-list__calculation_table_total .js-integrator-reward-title .ico-wrap,
.calculations-list__calculation_table_total .js-integrator-customs-title .ico-wrap,
.calculations-list__calculation_table_total .js-integrator-insurance-title .ico-wrap {
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_table_total .js-integrator-reward-title .fa,
.calculations-list__calculation_table_total .js-integrator-customs-title .fa,
.calculations-list__calculation_table_total .js-integrator-insurance-title .fa {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 1.2rem;
  margin: 0 auto;
}
.calculations-list__calculation_table_total .total-price {
  border-radius: 3px;
  border: 1px solid #62CFA8;
  background: rgba(98, 207, 168, 0.2);
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  padding: 24px;
}
.calculations-list__calculation_table_total .total-price .condition-btn {
  color: #3AB0D8;
  font-size: 14px;
  line-height: 24px;
  border-bottom-color: #3AB0D8;
}
.calculations-list__calculation_table_total .total-price .btn-save {
  background-color: transparent;
  border: 1px solid #3AB0D8;
  border-radius: 3px;
  color: #3AB0D8;
  width: 100%;
  height: 40px;
  text-align: center;
}
.calculations-list__calculation_table_total .total-price .btn-save:hover {
  background-color: #3AB0D8;
  color: #fff;
}
.calculations-list__calculation_table_total .total-price .btn-create-order {
  min-height: 40px;
  border-radius: 3px;
}
.calculations-list__calculation_table_total .total-price .tooltip-btn-order {
  background-color: white;
  border-radius: 3px;
  border: 1px solid #333;
}
.calculations-list__calculation_table_total .total-price p {
  margin: 0;
}
.calculations-list__calculation_table_total .total-price .js-integrator-reward-label,
.calculations-list__calculation_table_total .total-price .js-total-cost-label {
  font-size: 14px;
  line-height: 24px;
  color: #8d98a6;
}
.calculations-list__calculation_table_total .total-price .js-integrator-reward,
.calculations-list__calculation_table_total .total-price .js-total-cost {
  font-size: 20px;
  line-height: 24px;
  color: #333;
}
.calculations-list__calculation_table_total .total-price .js-integrator-reward .nds,
.calculations-list__calculation_table_total .total-price .js-total-cost .nds {
  margin-top: 7px;
  font-size: 14px;
  line-height: 24px;
}
.calculations-list__calculation_table_total .total-price .price {
  color: #ff0000;
}
.calculations-list__calculation_table {
  padding: 28px 24px 24px;
  /* @media screen and (min-width: 1330px){
     border: 1px solid #D3D8E0;
   }*/
  /* border: 1px solid #D3D8E0;*/
  border-radius: 3px;
}
.calculations-list__calculation_table .document-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/customs-clearance-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 3px;
}
.calculations-list__calculation_table .document-icon.color-yellow {
  background-image: url("../img/icons/yellow/customs-clearance-green-icon.svg");
}
.calculations-list__calculation_table .border-mobile {
  border: 1px solid #D3D8E0;
}
.calculations-list__calculation_table_title {
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 15px;
  display: block;
}
.calculations-list__calculation_table_title .cell-with-icon {
  -ms-flex-align: center;
      align-items: center;
  font-size: 18px;
  color: #333;
}
.calculations-list__calculation_table_title .cell-with-icon .location-name .integrator_title {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  max-height: 22px;
  margin-top: auto;
}
.calculations-list__calculation_table_title .cell-with-icon .location-name .date {
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 2px;
  color: #3AB0D8;
  border: 1px solid #3AB0D8;
  padding: 2px;
  font-size: 12px;
  line-height: 16px;
  max-width: 70px;
}
.calculations-list__calculation_table_title .cell-with-icon .location-name .country {
  color: #8d98a6;
  font-size: 14px;
}
.calculations-list__calculation_table_title .cell-with-icon .location-name p {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  color: #000;
}
.calculations-list__calculation_table_title .cell-with-icon .location-arrow-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/icon-arrow-left.svg");
  background-size: cover;
  min-width: 14px;
  width: 14px;
  height: 10px;
  margin: 0 9px;
}
.calculations-list__calculation_table_title .cell-with-icon .location-arrow-icon.color-yellow {
  background-image: url("../img/icons/yellow/icon-arrow-left.svg");
}
@media screen and (max-width: 991px) {
  .calculations-list__calculation_table_title .cell-with-icon .location-arrow-icon {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.calculations-list__calculation_table_title .cell-with-icon .location-arrow-icon-down {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/icon-arrow-left.svg");
  background-size: cover;
  min-width: 14px;
  width: 14px;
  height: 10px;
  margin: 0 9px;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.calculations-list__calculation_table_title .cell-with-icon .location-arrow-icon-down.color-yellow {
  background-image: url("../img/icons/yellow/icon-arrow-left.svg");
}
@media screen and (max-width: 991px) {
  .calculations-list__calculation_table_title .cell-with-icon .location-arrow-icon {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.calculations-list__calculation_table_title .cell-with-icon .location-arrow-icon-down {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/icon-arrow-left.svg");
  background-size: cover;
  min-width: 14px;
  width: 14px;
  height: 10px;
  margin: 0 9px;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.calculations-list__calculation_table_title .cell-with-icon .location-arrow-icon-down.color-yellow {
  background-image: url("../img/icons/yellow/icon-arrow-left.svg");
}
.calculations-list__calculation_table_title .sea-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/sea-icon.svg");
  background-size: cover;
  width: 20px;
  height: 24px;
  margin-right: 10px;
}
.calculations-list__calculation_table_title .sea-icon.color-yellow {
  background-image: url("../img/icons/yellow/sea-icon.svg");
}
.calculations-list__calculation_table_title .truck-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/truck-icon.svg");
  background-size: cover;
  width: 20px;
  height: 24px;
  margin-right: 10px;
}
.calculations-list__calculation_table_title .truck-icon.color-yellow {
  background-image: url("../img/icons/yellow/truck-icon.svg");
}
.calculations-list__calculation_table_title .complex-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/complex-icon.svg");
  width: 20px;
  height: 24px;
  margin-right: 10px;
}
.calculations-list__calculation_table_title .complex-icon.color-yellow {
  background-image: url("../img/icons/yellow/complex-icon.svg");
}
.calculations-list__calculation_table_title .air-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/air-icon.svg");
  background-size: cover;
  width: 30px;
  height: 20px;
  margin-right: 10px;
}
.calculations-list__calculation_table_title .air-icon.color-yellow {
  background-image: url("../img/icons/yellow/air-icon.svg");
}
.calculations-list__calculation_table_title .train-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/train-icon.svg");
  background-size: cover;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_table_title .train-icon.color-yellow {
  background-image: url("../img/icons/yellow/train-icon.svg");
}
.calculations-list__calculation_table .js-integrator-reward-title,
.calculations-list__calculation_table .js-integrator-customs-title,
.calculations-list__calculation_table .js-integrator-insurance-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.calculations-list__calculation_table .js-integrator-reward-title .ico-wrap,
.calculations-list__calculation_table .js-integrator-customs-title .ico-wrap,
.calculations-list__calculation_table .js-integrator-insurance-title .ico-wrap {
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_table .js-integrator-reward-title .fa,
.calculations-list__calculation_table .js-integrator-customs-title .fa,
.calculations-list__calculation_table .js-integrator-insurance-title .fa {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 1.2rem;
  margin: 0 auto;
}
.calculations-list__calculation_table .total-price {
  background-color: #e5ffe5;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  padding: 26px 0 26px;
}
.calculations-list__calculation_table .total-price p {
  margin: 0;
}
.calculations-list__calculation_table .total-price .js-integrator-reward-label,
.calculations-list__calculation_table .total-price .js-total-cost-label {
  font-size: 13px;
}
.calculations-list__calculation_table .total-price .price {
  color: #ff0000;
}
.calculations-list__calculation_table .table-wrap {
  overflow-x: auto;
}
.calculations-list__calculation_table table {
  border: none;
  width: 100%;
}
.calculations-list__calculation_table table th {
  vertical-align: middle;
  padding-left: 6px;
  padding-top: 6px;
  padding-bottom: 12px;
  border: none;
  color: #8d98a6;
  font-size: 12px;
  font-weight: normal;
  background-color: rgba(211, 216, 224, 0.2);
}
.calculations-list__calculation_table table th:nth-last-child(1) {
  padding-right: 12px;
}
.calculations-list__calculation_table table td:nth-last-child(1) {
  padding-right: 12px;
}
.calculations-list__calculation_table table td {
  vertical-align: middle;
  border: none;
  padding-left: 6px;
  line-height: 16px;
  font-size: 14px;
  color: #333;
}
.calculations-list__calculation_table table td.price {
  color: #ff0000;
}
.calculations-list__calculation_table table td .price {
  color: #ff0000;
}
.calculations-list__calculation_table table tr:nth-of-type(even) {
  background-color: rgba(211, 216, 224, 0.2);
}
.calculations-list__calculation_table table tr.total-cost td {
  color: #333;
  font-size: 16px;
  vertical-align: middle;
  padding-top: 9px;
  padding-bottom: 7px;
  line-height: 24px;
  font-weight: 700;
}
.calculations-list__calculation_table table tr.total-cost .priceOverTime {
  color: #000;
  font-size: 13px;
}
.calculations-list__calculation_table .table-wrap::-webkit-scrollbar {
  width: 2px;
}
.calculations-list__calculation_table .table-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.calculations-list__calculation_table .table-wrap::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #66a6ff;
}
.calculations-list__calculation_table .table-wrap::-webkit-scrollbar-thumb:window-inactive {
  background-color: #66a6ff;
  opacity: 0.8;
}
.calculations-list__calculation_table .loading .checkbox,
.calculations-list__calculation_table .loading .checkmark {
  display: none;
}
.calculations-list__calculation_table .loading::after {
  content: "";
  display: block;
  border-top-color: #3AB0D8;
  border-top-color: var(--primary-color, #3AB0D8);
  border-top-width: 3px;
  border-top-style: solid;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.calculations-list__calculation_table .tariffBtn {
  font-size: 14px;
  line-height: 24px;
  color: #3AB0D8;
  cursor: pointer;
}
.calculations-list__calculation_table .tariffBtn:hover {
  color: #42caff;
}
.calculations-list__calculation_table .checkBox-center label {
  -ms-flex-pack: center;
      justify-content: center;
}
.calculations-list__calculation_table .checkBox-center span.checkmark {
  margin: 0;
}
.calculations-list__calculation_table-mobile {
  padding: 24px 7px 0;
  border: 1px solid #D3D8E0;
  border-radius: 3px;
  margin-top: 20px;
}
.calculations-list__calculation_table-mobile_title {
  display: block;
  -ms-flex-align: start;
      align-items: flex-start;
  margin-bottom: 15px;
}
.calculations-list__calculation_table-mobile_title .cell-with-icon {
  -ms-flex-align: center;
      align-items: center;
  font-size: 18px;
  color: #333;
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-name {
  text-overflow: ellipsis;
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-name .integrator_title {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  max-height: 22px;
  margin-top: auto;
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-name .date {
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 2px;
  color: #3AB0D8;
  border: 1px solid #3AB0D8;
  padding: 2px;
  font-size: 12px;
  line-height: 16px;
  max-width: 70px;
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-name .country {
  color: #8d98a6;
  font-size: 14px;
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-name p {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  color: #000;
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-arrow-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/icon-arrow-left.svg");
  background-size: cover;
  min-width: 14px;
  width: 14px;
  height: 10px;
  margin: 0 9px;
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-arrow-icon.color-yellow {
  background-image: url("../img/icons/yellow/icon-arrow-left.svg");
}
@media screen and (max-width: 768px) {
  .calculations-list__calculation_table-mobile_title .cell-with-icon .location-arrow-icon {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-arrow-icon-down {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/icon-arrow-left.svg");
  background-size: cover;
  min-width: 14px;
  width: 14px;
  height: 10px;
  margin: 0 9px;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.calculations-list__calculation_table-mobile_title .cell-with-icon .location-arrow-icon-down.color-yellow {
  background-image: url("../img/icons/yellow/icon-arrow-left.svg");
}
.calculations-list__calculation_table-mobile_title .sea-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/sheep-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation_table-mobile_title .sea-icon.color-yellow {
  background-image: url("../img/icons/yellow/sheep-green-icon.svg");
}
.calculations-list__calculation_table-mobile_title .truck-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/truck-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation_table-mobile_title .truck-icon.color-yellow {
  background-image: url("../img/icons/yellow/truck-green-icon.svg");
}
.calculations-list__calculation_table-mobile_title .complex-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/complex-green.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation_table-mobile_title .complex-icon.color-yellow {
  background-image: url("../img/icons/yellow/complex-green.svg");
}
.calculations-list__calculation_table-mobile_title .air-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/air-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.calculations-list__calculation_table-mobile_title .air-icon.color-yellow {
  background-image: url("../img/icons/yellow/air-green-icon.svg");
}
.calculations-list__calculation_table-mobile_title .train-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/train-green-icon.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}
.calculations-list__calculation_table-mobile_title .train-icon.color-yellow {
  background-image: url("../img/icons/yellow/train-green-icon.svg");
}
.calculations-list__calculation_table-mobile .js-integrator-reward-title,
.calculations-list__calculation_table-mobile .js-integrator-customs-title,
.calculations-list__calculation_table-mobile .js-integrator-insurance-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.calculations-list__calculation_table-mobile .js-integrator-reward-title .ico-wrap,
.calculations-list__calculation_table-mobile .js-integrator-customs-title .ico-wrap,
.calculations-list__calculation_table-mobile .js-integrator-insurance-title .ico-wrap {
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  margin-right: 5px;
}
.calculations-list__calculation_table-mobile .js-integrator-reward-title .fa,
.calculations-list__calculation_table-mobile .js-integrator-customs-title .fa,
.calculations-list__calculation_table-mobile .js-integrator-insurance-title .fa {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 1.2rem;
  margin: 0 auto;
}
.calculations-list__calculation_table-mobile .total-price {
  background-color: #e5ffe5;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  padding: 26px 0 26px;
}
.calculations-list__calculation_table-mobile .total-price p {
  margin: 0;
}
.calculations-list__calculation_table-mobile .total-price .js-integrator-reward-label,
.calculations-list__calculation_table-mobile .total-price .js-total-cost-label {
  font-size: 13px;
}
.calculations-list__calculation_table-mobile .total-price .price {
  color: #ff0000;
}
.calculations-list__calculation_table-mobile table {
  border: none;
}
.calculations-list__calculation_table-mobile table th {
  border: none;
  color: #9198a7;
  font-size: 13px;
  font-weight: normal;
  background-color: #fffdf2;
}
.calculations-list__calculation_table-mobile table td {
  border: none;
  font-size: 13px;
  color: #000;
}
.calculations-list__calculation_table-mobile table td.price {
  color: #ff0000;
}
.calculations-list__calculation_table-mobile table td .price {
  color: #ff0000;
}
.calculations-list__calculation_table-mobile table tr {
  border-bottom: 1px solid #fff1cc;
  background-color: #fffdf2;
}
.calculations-list__calculation_table-mobile table tr.total-cost {
  background-color: #fff9db;
}
.calculations-list__calculation_table-mobile table tr.total-cost td {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 16px;
}
.calculations-list__calculation_table-mobile table tr.total-cost .priceOverTime {
  color: #000;
  font-size: 13px;
}
.calculations-list__calculation_table-mobile .loading .checkbox,
.calculations-list__calculation_table-mobile .loading .checkmark {
  display: none;
}
.calculations-list__calculation_table-mobile .loading::after {
  content: "";
  display: block;
  border-top-color: #3AB0D8;
  border-top-color: var(--primary-color, #3AB0D8);
  border-top-width: 3px;
  border-top-style: solid;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.calculations-list__calculation_table-mobile .tariffBtn {
  font-size: 13px;
}
.calculations-list__calculation_table-mobile .checkBox-center label {
  -ms-flex-pack: center;
      justify-content: center;
}
.calculations-list__calculation_table-mobile .checkBox-center span.checkmark {
  margin: 0;
}
.calculations-list__calculation_mobile-card {
  margin-bottom: 10px;
  padding-bottom: 10px;
  /*padding: 8px;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);*/
}
.calculations-list__calculation_mobile-card .tariffBtn {
  font-size: 14px;
  line-height: 24px;
  color: #3AB0D8;
  cursor: pointer;
}
.calculations-list__calculation_mobile-card .tariffBtn:hover {
  color: #42caff;
}
.calculations-list__calculation_mobile-card .line {
  height: 1px;
  width: 100%;
  background-color: rgba(141, 152, 166, 0.5);
  margin-bottom: 12px;
  margin-top: 12px;
}
.calculations-list__calculation_mobile-card .background-with-gray {
  background-color: rgba(211, 216, 224, 0.2);
  padding-bottom: 12px;
  padding-top: 12px;
}
.calculations-list__calculation_mobile-card .background-without-gray {
  padding-bottom: 12px;
  padding-top: 12px;
}
.calculations-list__calculation_mobile-card div span.label-title {
  color: #8d98a6;
}
.calculations-list__calculation_mobile-card div span.text {
  color: #333;
}
.calculations-list__calculation_mobile-card div span.text.price {
  color: #ff0000;
}
.calculations-list__calculation_mobile-card div span.price {
  color: #ff0000;
}
.calculations-list__calculation_mobile-card div span.checkbox {
  display: inline-block;
  vertical-align: middle;
}
.calculations-list__calculation_mobile-card div:hover .text {
  color: #000;
}
.calculations-list__calculation_mobile-card .priceOverTime {
  font-size: 14px;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.calculations-list__calculation.fadein {
  -webkit-animation: fadein 3s;
          animation: fadein 3s;
}
.calculations-list__customs-desc {
  line-height: 18px;
}
.calculations-list__empty_msg {
  text-align: center;
  padding: 0 20%;
}
.calculations-list.offsetScroll .calculations-list__filter,
.calculations-list.offsetScroll .scroll-block {
  margin-right: 0px;
}
.calculations-list.offsetScroll .scrollBtn {
  right: 0;
}

.delivery.block-margin-top {
  margin-top: 28px;
}
.delivery p {
  margin: 0;
}
.delivery__header {
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
  padding: 24px 21px;
  border-radius: 6px 6px 0 0;
  height: 112px;
}
.delivery__header p.title {
  font-size: 28px;
  color: #fff;
}
.delivery__body {
  padding: 24px 21px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
}
.delivery__body .title {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  margin: 5px 0;
  font-weight: bold;
}
.delivery__body .label span {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.delivery__body .label_status span {
  color: red;
}

.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}

.PresetDateRangePicker_button:active {
  outline: 0;
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699;
}

.SingleDatePicker {
  position: relative;
  display: inline-block;
}

.SingleDatePicker__block {
  display: block;
}

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}

.SingleDatePicker_picker__rtl {
  direction: rtl;
}

.SingleDatePicker_picker__directionLeft {
  left: 0;
}

.SingleDatePicker_picker__directionRight {
  right: 0;
}

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none;
}

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.SingleDatePickerInput {
  display: inline-block;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #dbdbdb;
}

.SingleDatePickerInput__rtl {
  direction: rtl;
}

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}

.SingleDatePickerInput__block {
  display: block;
}

.SingleDatePickerInput__showClearDate {
  padding-right: 30px;
}

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.DateRangePicker {
  position: relative;
  display: inline-block;
}

.DateRangePicker__block {
  display: block;
}

.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}

.DateRangePicker_picker__rtl {
  direction: rtl;
}

.DateRangePicker_picker__directionLeft {
  left: 0;
}

.DateRangePicker_picker__directionRight {
  right: 0;
}

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none;
}

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}

.DayPicker__horizontal {
  background: #fff;
}

.DayPicker__verticalScrollable {
  height: 100%;
}

.DayPicker__hidden {
  visibility: hidden;
}

.DayPicker__withBorder {
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}

.DayPicker_portal__horizontal {
  -webkit-box-shadow: none;
          box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}

.DayPicker_portal__vertical {
  position: initial;
}

.DayPicker_focusRegion {
  outline: 0;
}

.DayPicker_weekHeaders {
  position: relative;
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}

.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  padding: 0 13px;
  text-align: left;
}

.DayPicker_weekHeader__vertical {
  left: 50%;
}

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
}

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}

.DayPicker_transitionContainer__vertical {
  width: 100%;
}

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_show {
  width: 22px;
  position: absolute;
  z-index: 2;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_show__topRight {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:hover {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_show__topLeft {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:hover {
  border-left: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute;
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: -28px;
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: -28px;
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: -28px;
}

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
}

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2;
}

.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}

.KeyboardShortcutRow {
  list-style: none;
  margin: 6px 0;
}

.KeyboardShortcutRow__block {
  margin-bottom: 16px;
}

.KeyboardShortcutRow_keyContainer {
  display: inline-block;
  white-space: nowrap;
  text-align: right;
  margin-right: 6px;
}

.KeyboardShortcutRow_keyContainer__block {
  text-align: left;
  display: inline;
}

.KeyboardShortcutRow_key {
  font-family: monospace;
  font-size: 12px;
  text-transform: uppercase;
  background: #f2f2f2;
  padding: 2px 6px;
}

.KeyboardShortcutRow_action {
  display: inline;
  word-break: break-word;
  margin-left: 8px;
}

.DayPickerNavigation_container {
  position: relative;
  z-index: 2;
}

.DayPickerNavigation_container__vertical {
  background: #fff;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100%;
}

.DayPickerNavigation_container__verticalScrollable {
  position: relative;
}

.DayPickerNavigation_button {
  cursor: pointer;
  line-height: 0.78;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575;
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4;
}

.DayPickerNavigation_button__default:active {
  background: #f2f2f2;
}

.DayPickerNavigation_button__horizontal {
  border-radius: 3px;
  padding: 6px 9px;
  top: 18px;
  position: absolute;
}

.DayPickerNavigation_leftButton__horizontal {
  left: 22px;
}

.DayPickerNavigation_rightButton__horizontal {
  right: 22px;
}

.DayPickerNavigation_button__vertical {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%;
}

.DayPickerNavigation_button__vertical__default {
  padding: 5px;
}

.DayPickerNavigation_nextButton__vertical__default {
  border-left: 0;
}

.DayPickerNavigation_nextButton__verticalScrollable {
  width: 100%;
}

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
}

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #565a5c;
}

.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0;
}

.CalendarMonthGrid__animating {
  z-index: 1;
}

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px;
}

.CalendarMonthGrid__vertical {
  margin: 0 auto;
}

.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.CalendarMonth {
  background: #fff;
  text-align: center;
  padding: 0 13px;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}

.CalendarMonth_caption {
  color: #565a5c;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial;
}
.CalendarMonth_caption strong {
  text-transform: capitalize;
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}

.CalendarDay {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.CalendarDay:active {
  outline: 0;
}

.CalendarDay__defaultCursor {
  cursor: default;
}

.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #565a5c;
  background: #fff;
}

.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px double #e4e7e7;
  color: inherit;
}

.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #565a5c;
}

.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd;
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd;
}

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #565a5c;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #565a5c;
}

.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px solid #33dacd;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px solid #33dacd;
  color: #fff;
}

.CalendarDay__last_in_range {
  border-right: #00a699;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px solid #00a699;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px solid #80e8e0;
  color: #007a87;
}

.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px solid #80e8e0;
  color: #007a87;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd;
}

.DateRangePickerInput {
  display: inline-block;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #cacccd;
}

.DateRangePickerInput__rtl {
  direction: rtl;
}

.DateRangePickerInput__block {
  display: block;
}

.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: initial;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #565a5c;
  height: 24px;
  width: 24px;
}

.DateRangePickerInput_arrow_svg__small {
  height: 19px;
  width: 19px;
}

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.DateRangePickerInput_clearDates__small {
  padding: 6px;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  border-radius: 50%;
}

.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: initial;
  padding: 2px;
  margin: 0 2px 0 2px;
}

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.DateInput {
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle;
}

.DateInput__small {
  width: 70px;
}

.DateInput__disabled {
  color: #dbdbdb;
}

.DateInput_input {
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #565a5c;
  width: 100%;
  padding: 13px 12px 11px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
}

.DateInput_input__small {
  font-size: 14px;
  line-height: 18px;
  padding: 8px 2px 6px;
}

.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DateInput_input__focused {
  outline: 0;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0;
}

.DateInput_input__disabled {
  background: none;
  font-style: italic;
}

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2;
}

.DateInput_fangShape {
  fill: #fff;
}

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent;
}

.DateRangePicker {
  border-radius: 3px;
}

.DateRangePicker input[type=text] {
  background-color: unset;
  border: 0;
  border-radius: 0;
  text-align: center;
  font-style: normal;
}

.crud-list__inner {
  padding-bottom: calc(1rem + 15px);
}
.crud-list__inner_pagination {
  color: #9198a7;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
}
.crud-list__inner_pagination_label .total {
  font-weight: bold;
  color: #333;
}
.crud-list__inner_pagination .pagination {
  margin: 0;
}
.crud-list__inner_pagination .pagination .page-item {
  margin-right: 4px;
  font-size: 14px;
  line-height: 24px;
  color: #394861;
}
.crud-list__inner_pagination .pagination .page-item:last-child {
  margin-right: 0;
  margin-left: 20px;
}
.crud-list__inner_pagination .pagination .page-item:first-child {
  margin-right: 20px;
}
.crud-list__inner_pagination .pagination .page-item > .page-link {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.crud-list__inner_pagination .pagination .page-item.active > .page-link {
  color: #fff;
  background-color: #4996FF;
}
.crud-list__inner_table {
  margin-top: 20px;
  background-color: #fff;
}
.crud-list__inner_table > thead > tr {
  background-color: #fff;
}
.crud-list__inner_table > thead > tr th.cell-dateRange {
  width: 190px;
}
.crud-list__inner_table > thead > tr > th {
  border-top: none;
  border-bottom: 1px solid #dee2e6;
  padding: 14px 4px;
  color: rgb(162, 170, 187);
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}
.crud-list__inner_table > thead > tr > th span.title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  word-break: keep-all;
}
.crud-list__inner_table > thead > tr > th span.title .fa {
  margin-left: 5px;
}
.crud-list__inner_table > tbody > tr:nth-child(n) {
  background-color: #fff;
}
.crud-list__inner_table > tbody > tr:nth-child(2n) {
  background-color: #f9fafb;
}
.crud-list__inner_table > tbody > tr:hover {
  background-color: #fff;
  -webkit-box-shadow: 8px 0 white, -8px 0 white, 0 12px 32px 0 rgba(0, 72, 191, 0.13);
          box-shadow: 8px 0 white, -8px 0 white, 0 12px 32px 0 rgba(0, 72, 191, 0.13);
}
.crud-list__inner_table > tbody > tr:hover > td {
  color: #000;
}
.crud-list__inner_table > tbody > tr.expired {
  background-color: #ffe6e6;
}
.crud-list__inner_table > tbody > tr.expired:hover {
  background-color: #ffe6e6;
  -webkit-box-shadow: 8px 0 #ffe6e6, -8px 0 #ffe6e6, 0 12px 32px 0 rgba(0, 72, 191, 0.13);
          box-shadow: 8px 0 #ffe6e6, -8px 0 #ffe6e6, 0 12px 32px 0 rgba(0, 72, 191, 0.13);
}
.crud-list__inner_table > tbody > tr > td {
  border: none;
  padding: 8px 4px;
  color: rgb(162, 170, 187);
  font-size: 13px;
  vertical-align: middle;
}
.crud-list__inner_table > tbody > tr > td p {
  margin: 0;
}
.crud-list__inner_table > tbody > tr > td span.cell-with-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
  -ms-flex-align: center;
      align-items: center;
  text-align: left;
}
.crud-list__inner_table > tbody > tr > td span.cell-with-icon .from-location-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/arrowLocation-up.svg");
  min-width: 16px;
  height: 16px;
  margin-right: 10px;
}
.crud-list__inner_table > tbody > tr > td span.cell-with-icon .from-location-icon.color-yellow {
  background-image: url("../img/icons/yellow/arrowLocation-up.svg");
}
.crud-list__inner_table > tbody > tr > td span.cell-with-icon .to-location-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/arrowLocation-down.svg");
  min-width: 16px;
  height: 16px;
  margin-right: 10px;
}
.crud-list__inner_table > tbody > tr > td span.cell-with-icon .to-location-icon.color-yellow {
  background-image: url("../img/icons/yellow/arrowLocation-down.svg");
}
.crud-list__inner_table > tbody > tr > td p.cell-with-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  text-align: left;
}
.crud-list__inner_table > tbody > tr > td p.cell-with-icon .cargo-general-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/generalCargo.svg");
  min-width: 16px;
  height: 16px;
}
.crud-list__inner_table > tbody > tr > td p.cell-with-icon .cargo-general-icon.color-yellow {
  background-image: url("../img/icons/yellow/generalCargo.svg");
}
.crud-list__inner_table > tbody > tr > td p.cell-with-icon .cargo-container-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/containerCargo.svg");
  min-width: 23px;
  height: 21px;
}
.crud-list__inner_table > tbody > tr > td p.cell-with-icon .cargo-container-icon.color-yellow {
  background-image: url("../img/icons/yellow/containerCargo.svg");
}
.crud-list__inner_table > tbody > tr > td p.cell-with-icon .cargo-vehicle-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/vehicleCargo.svg");
  min-width: 25px;
  height: 25px;
}
.crud-list__inner_table > tbody > tr > td p.cell-with-icon .cargo-vehicle-icon.color-yellow {
  background-image: url("../img/icons/yellow/vehicleCargo.svg");
}
.crud-list__inner_table > tbody > tr > td .custom_tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.crud-list__inner_table > tbody > tr > td .custom_tooltip__text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.crud-list__inner_table > tbody > tr > td .custom_tooltip:hover .custom_tooltip__text {
  visibility: visible;
}
.crud-list__inner_table > tbody > tr > td.cell-port .cell-with-icon {
  text-align: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.crud-list__inner_table .sea-icon {
  display: inline-block;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/sea-icon.svg");
  width: 20px;
  min-width: 20px;
  height: 24px;
}
.crud-list__inner_table .sea-icon.color-yellow {
  background-image: url("../img/icons/yellow/sea-icon.svg");
}
.crud-list__inner_table .truck-icon {
  display: inline-block;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/truck-icon.svg");
  width: 22px;
  min-width: 22px;
  height: 25px;
}
.crud-list__inner_table .truck-icon.color-yellow {
  background-image: url("../img/icons/yellow/truck-icon.svg");
}
.crud-list__inner_table .air-icon {
  display: inline-block;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/air-icon.svg");
  width: 30px;
  min-width: 30px;
  height: 20px;
}
.crud-list__inner_table .air-icon.color-yellow {
  background-image: url("../img/icons/yellow/air-icon.svg");
}
.crud-list__inner_table .train-icon {
  display: inline-block;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/train-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
}
.crud-list__inner_table .train-icon.color-yellow {
  background-image: url("../img/icons/yellow/train-icon.svg");
}
.crud-list__inner_table .cell-with-icon {
  display: -ms-flexbox;
  display: flex;
}
.crud-list__inner_table .cell-with-icon .sea-icon,
.crud-list__inner_table .cell-with-icon .truck-icon,
.crud-list__inner_table .cell-with-icon .air-icon,
.crud-list__inner_table .cell-with-icon .train-icon {
  margin-right: 5px;
}
.crud-list__inner_empty {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 17px;
  text-align: center;
}

.tooltip-load {
  z-index: 9999;
  position: fixed;
  width: auto;
  max-width: 440px;
  background-color: #fff;
  padding: 10px;
  color: #000;
  border: 1px solid #888;
  border-radius: 7px;
}

.tooltip-load::before, .tooltip-load::after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: -20px;
  border: 10px solid transparent;
  border-top: 10px solid #888;
}

.tooltip-load::after {
  border-top: 10px solid white;
  bottom: -19px;
}

.order-short-m-card .crud-list__inner_table > thead > tr > th {
  text-align: left;
}
.order-short-m-card .crud-list__inner_table > thead > tr > th span.title {
  -ms-flex-pack: left;
      justify-content: left;
}

.crud-list-mobile__inner .container {
  padding-left: 0;
  padding-right: 0;
}
.crud-list-mobile__inner_card {
  margin-top: 28px;
  padding: 8px;
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
}
.crud-list-mobile__inner_card.row {
  margin-top: 28px;
}
.crud-list-mobile__inner_card.expired {
  background-color: #ffe6e6;
}
.crud-list-mobile__inner_card div span.label-title {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.crud-list-mobile__inner_card div span.text {
  color: #333;
}
.crud-list-mobile__inner_card div:hover .text {
  /*color: $black;*/
}
.crud-list-mobile__inner_card .row-action {
  margin-top: 16px;
  text-align: center;
}
.crud-list-mobile__inner_card .row-action .btn {
  width: 35px;
  height: 35px;
  background-size: contain;
}

.b-popup-wrapper.show {
  overflow-x: hidden;
  overflow-y: auto;
}

.crud-modal {
  max-width: 770px;
}
.crud-modal .has-danger input, .crud-modal .has-danger .Select-control {
  border-color: #d9534f !important;
}
.crud-modal .form-group {
  width: 100%;
  margin-bottom: 5px;
}
.crud-modal .form-group.minimal-weight-unit-select {
  width: 90px;
}
.crud-modal .form-group .input-group-addon {
  min-width: 245px;
}
.crud-modal .form-group .form-control {
  border-radius: 0.25rem;
}
.crud-modal .form-group .react-datepicker-wrapper input {
  min-width: 100px;
  width: 100%;
}
.crud-modal .form-group .calendar-icon {
  min-width: 0;
  position: relative;
  left: -40px;
  pointer-events: none;
  background-color: transparent;
}
.crud-modal .form-group .crud-select .Select-control {
  width: 100%;
}
.crud-modal .tab-content {
  margin-top: 10px;
}

.translateArea textarea {
  height: 150px;
}

.filter {
  font-size: 13px;
}
.filter .table {
  background: white;
}
.filter .table td {
  border-top: 0;
}
.filter .form-group {
  margin-bottom: 0;
}
.filter .react-numeric-input {
  display: block;
}
.filter__list {
  background-color: #fff;
}
.filter__item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.filter__item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.filter__item .label {
  min-width: 150px;
}
.filter__item .select {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.filter__item .simple-input {
  font-size: 13px;
}
.filter__item .simple-input::-webkit-input-placeholder {
  color: #aaa;
}
.filter__item .simple-input::-moz-placeholder {
  color: #aaa;
}
.filter__item .simple-input:-ms-input-placeholder {
  color: #aaa;
}
.filter__item .simple-input::-ms-input-placeholder {
  color: #aaa;
}
.filter__item .simple-input::placeholder {
  color: #aaa;
}

.cookieInfo {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  text-align: center;
  background: #f5ecce;
}
.cookieInfo__close {
  position: absolute;
  top: 5px;
  right: 10px;
}
.cookieInfo__close:hover {
  cursor: pointer;
}

.orderModal {
  max-width: 680px;
}
.orderModal .form-group .input-group-text {
  min-width: 240px !important;
}
.orderModal .date-block {
  max-width: 200px;
}
.orderModal .label span {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.orderModal .has-danger textarea {
  border-color: red;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.orderModal .has-danger textarea::-webkit-input-placeholder {
  color: #f45d5d;
}
.orderModal .has-danger textarea::-moz-placeholder {
  color: #f45d5d;
}
.orderModal .has-danger textarea:-moz-placeholder {
  color: #f45d5d;
}
.orderModal .has-danger textarea:-ms-input-placeholder {
  color: #f45d5d;
}
.orderModal textarea {
  height: 160px;
}
.orderModal P {
  margin-bottom: 5px;
}
.orderModal .nav-tabs {
  display: -ms-flexbox;
  display: flex;
}
.orderModal .nav-tabs .nav-item {
  display: -ms-flexbox;
  display: flex;
}
.orderModal .nav-tabs .nav-item .nav-link {
  max-width: 158px;
  text-align: center;
}
.orderModal .nav-tabs .nav-item .nav-link .badge-danger {
  display: none;
}
.orderModal .nav-tabs .nav-item .nav-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.customsHouseModal {
  max-width: 800px;
}

.change-password .input-group .input-group-text {
  min-width: 185px;
}

.password-recovery-wr.pane {
  width: 450px;
}
.password-recovery-form .input-group .input-group-text {
  min-width: 185px;
}

.registerModal {
  max-width: 550px;
}

.modal-table-tariffs {
  min-width: 95%;
}
.modal-table-tariffs .header-table {
  /*&_image {
    display: block;
    width: 100px;
    height: 45px;
    background: url('../img/logo.svg') no-repeat top left;
    &:hover {
      cursor: pointer;
    }
  }*/
}
.modal-table-tariffs .header-table_title {
  margin-top: 5px;
}

.confirm-cancel-modal {
  margin-top: 250px;
}

#customsHouse .f-label {
  color: #313131;
  font-size: 1rem;
}
#customsHouse .nav-item {
  color: #313131;
}
#customsHouse .nav-item .nav-link {
  position: relative;
  padding-right: 30px;
}
#customsHouse .btn-link {
  height: 23px;
  font-size: 0.875rem;
}
#customsHouse .incoterms textarea {
  height: 120px;
}
#customsHouse .remark {
  font-size: 14px;
}
#customsHouse .btnClose {
  position: absolute;
  right: 5px;
  top: 10px;
  height: 21px;
}
#customsHouse .b-price .value {
  font-size: 20px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
#customsHouse .b-price .totalCost {
  font-size: 20px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-weight: bold;
}

.tracking-tree {
  padding: 0;
}
.tracking-tree__item {
  border-left: solid 1px #c6cad2;
  position: relative;
  padding-left: 20px;
  padding-bottom: 1rem;
  list-style: none;
}
.tracking-tree__item::before {
  content: "";
  position: absolute;
  left: -4px;
  top: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #c6cad2;
}
.tracking-tree__item:first-child::before {
  background-color: #3AB0D8;
  background-color: var(--primary-active-color, #3AB0D8);
}
.tracking-tree__item-event {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.tracking-tree__item-date {
  font-size: 13px;
  margin-bottom: 0;
}

.b-partner {
  padding-bottom: calc(1rem + 15px);
}

.widgetHeader {
  min-height: 62px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
  padding: 10px 15px;
  margin-bottom: 28px;
}
.widgetHeader__logo {
  height: 42px;
  width: auto;
  margin-right: 20px;
}
.widgetHeader__title {
  font-size: 19px;
  color: #313131;
}
.widgetHeader__closeButton {
  border: unset;
  background: unset;
  padding: unset;
  display: block;
  width: 24px;
  height: 24px;
  background: url("../img/icons/close-btn@2x.png") no-repeat center;
  background-size: contain;
  outline: none !important;
}

.widgetFooter {
  cursor: pointer;
  padding: 1em 2em;
}
.widgetFooter__text {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  color: #9198a7;
  font-size: 14px;
}
.widgetFooter__link {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.widgetFooter__logo {
  padding: 0 10px;
  margin-bottom: 9px;
}
.widgetFooter__logoImage {
  cursor: pointer;
  margin-left: 12px;
  width: 160px;
  height: 25px;
  background: url("../img/cargobar_logo_gray.svg") no-repeat top left;
  background-size: contain;
}

.page .modal-input-fields .form-group .input-group-text {
  min-width: 100px;
}
.page .d-none-filter {
  display: none;
}
.page .settings-btn {
  cursor: pointer;
  color: rgba(51, 51, 51, 0.9);
}
.page .settings-btn .fa-16px {
  font-size: 25px;
}
.page .settings-btn .text-btn {
  line-height: 25px;
  text-align: center;
  margin-left: 10px;
}
.page .settings-btn:hover {
  color: #333;
}
.page .d-lg-block-filter {
  display: none;
}
@media screen and (min-width: 1199px) {
  .page .d-lg-block-filter {
    display: block;
  }
  .page .modal-content {
    min-width: 640px;
  }
}
.page .d-filter {
  display: block;
}
@media screen and (min-width: 1199px) {
  .page .d-lg-none-filter {
    display: none;
  }
}
.page .filter {
  margin-bottom: 50px;
  border-bottom: 1px solid rgba(211, 216, 224, 0.5);
  padding-bottom: 20px;
}
@media screen and (max-width: 1199px) {
  .page .filter .f-element {
    width: 100%;
  }
  .page .filter .fa-arrow-right {
    margin-bottom: 16px;
  }
}
.page .filter .input-group-text {
  font-size: 13px;
  background-color: transparent;
  border: 0;
  text-align: left;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}
.page .filter .form-group .input-group-text {
  min-width: 174px;
}
.page .filter .row {
  margin-left: 15px;
}
.page .filter .row .col-1,
.page .filter .row .col-5,
.page .filter .row .col-lg-3 {
  padding-left: 0;
  padding-right: 12px;
  padding-bottom: 5px;
  padding-top: 5px;
}
@media screen and (min-width: 1199px) {
  .page .filter .row:nth-child(2n) .col-1,
  .page .filter .row:nth-child(2n) .col-5,
  .page .filter .row:nth-child(2n) .col-lg-3 {
    background-color: rgba(211, 216, 224, 0.5);
  }
}
.page .custom-tooltip {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 3px;
  border: 1px solid #D3D8E0;
}
.page .reports__charts_title {
  padding-left: 40px;
  font-size: 16px;
  color: #3AB0D8;
}
.page .reports__charts_body {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(211, 216, 224, 0.5);
  margin-bottom: 50px;
}

.order-templates .table-data-template .row {
  padding-bottom: 3px;
  padding-top: 3px;
}
.order-templates .table-data-template .row .col-6:nth-child(odd) {
  color: #8d98a6;
}
.order-templates .table-data-template .row:hover {
  background-color: rgba(221, 216, 224, 0.2);
}
.order-templates .table-data-template .row:nth-child(2n) {
  background-color: rgba(221, 216, 224, 0.2);
}

@media screen and (min-width: 767px) {
  .import-translates-modal-result {
    min-width: 500px;
  }
}
@media screen and (min-width: 1199px) {
  .import-translates-modal-result {
    min-width: 700px;
  }
}

@media screen and (min-width: 767px) {
  .import-translates-modal {
    min-width: 600px;
  }
}
@media screen and (min-width: 1199px) {
  .import-translates-modal {
    min-width: 1000px;
  }
}
.import-translates-modal .input-file-label {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.import-translates-modal .btn-attach-file {
  margin-top: 20px;
  margin-bottom: 20px;
}
.import-translates-modal table {
  max-height: 300px;
}
.import-translates-modal table tbody {
  display: block;
  height: 300px;
  overflow-y: auto;
  overflow-x: auto;
}
@media screen and (min-width: 767px) {
  .import-translates-modal table tbody {
    max-width: 538px;
  }
}
@media screen and (min-width: 1199px) {
  .import-translates-modal table tbody {
    max-width: 938px;
  }
}
.import-translates-modal table tbody tr td {
  border: 1px solid black;
}

.history-subscription label {
  margin-top: 5px;
}
.history-subscription .history-table {
  width: 100%;
  border: 1px solid rgba(105, 105, 105, 0.6);
  padding: 5px;
  border-radius: 3px;
}
.history-subscription .history-table .row:first-child {
  font-weight: bold;
}
.history-subscription .history-table .content-table {
  max-height: 500px;
  overflow-y: auto;
}
.history-subscription .history-table .content-table .row:first-child {
  font-weight: normal;
}
.history-subscription .history-table .content-table .row:nth-child(2n) {
  background-color: #f9fafb;
}
.history-subscription .history-table .content-table.inverse .row:nth-child(2n) {
  background-color: #fff;
}
.history-subscription .history-table .content-table .row {
  border-bottom: none !important;
}
.history-subscription .history-table .content-table .row span {
  font-weight: bold;
}
.history-subscription .history-table .content-table .col-12, .history-subscription .history-table .content-table .col-lg-4 {
  padding-top: 3px;
  padding-bottom: 3px;
}
@media screen and (min-width: 991px) {
  .history-subscription .history-table .row:first-child {
    border-bottom: 1px solid rgba(105, 105, 105, 0.6);
  }
}

@media screen and (min-width: 768px) {
  .select-subscription-tariff-modal {
    max-width: 1000px;
    min-width: 65%;
  }
}
.select-subscription-tariff-modal .col-md-6, .select-subscription-tariff-modal .col-lg-4, .select-subscription-tariff-modal .col-12 {
  margin-bottom: 30px;
}
.select-subscription-tariff-modal .card {
  border: solid 1px #e8ebf0;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(34, 46, 58, 0.15);
  box-shadow: 0 1px 4px 0 rgba(34, 46, 58, 0.15);
  background: #fff;
  height: 100%;
}
.select-subscription-tariff-modal .title-card {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-bottom: 40px;
}
.select-subscription-tariff-modal .description-card {
  font-size: 16px;
  text-align: center;
  color: rgba(34, 46, 58, 0.6);
  margin-bottom: 30px;
}
.select-subscription-tariff-modal .price-card {
  color: #3AB0D8;
  text-align: center;
  margin-bottom: 20px;
}
.select-subscription-tariff-modal .actions-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.select-subscription-tariff-modal .actions-card .btn-outline-primary-new:hover {
  background-color: #fff;
  color: #3AB0D8;
  border-color: #3AB0D8;
}

.page-manage-subscription h2 {
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}
.page-manage-subscription h2 span {
  font-size: 1.3rem;
}
.page-manage-subscription .subscription-info-section {
  margin-top: 10px;
}
.page-manage-subscription .subscription-info-section .col-12:first-child, .page-manage-subscription .subscription-info-section .col-lg-auto:first-child {
  font-weight: bold;
}
@media screen and (min-width: 991px) {
  .page-manage-subscription .subscription-info-section .col-12:first-child, .page-manage-subscription .subscription-info-section .col-lg-auto:first-child {
    padding-right: 0;
  }
}
.page-manage-subscription div.subscription-info-section.row:first-child {
  margin-top: 0;
}
@media screen and (max-width: 991px) {
  .page-manage-subscription .actions-btn button {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .page-manage-subscription .actions-btn div:nth-child(2) {
    margin-top: 10px;
  }
}

.page-resource-report h2 {
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}
.page-resource-report h2 span {
  font-size: 1.3rem;
}
.page-resource-report .report-info-section {
  margin-top: 10px;
}
.page-resource-report .report-info-section .col-12:first-child, .page-resource-report .report-info-section .col-lg-auto:first-child {
  font-weight: bold;
}
@media screen and (min-width: 991px) {
  .page-resource-report .report-info-section .col-12:first-child, .page-resource-report .report-info-section .col-lg-auto:first-child {
    padding-right: 0;
  }
}
.page-resource-report div.report-info-section.row:first-child {
  margin-top: 0;
}
@media screen and (max-width: 991px) {
  .page-resource-report .actions-btn button {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .page-resource-report .actions-btn div:nth-child(2) {
    margin-top: 10px;
  }
}

@media screen and (min-width: 576px) {
  .modal-resource-report-crud {
    width: 70%;
    max-width: 70%;
  }
}

.table-vehicle-rates {
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 700px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #E5E5E5;
  height: 100%;
  overflow-y: auto;
  min-height: 250px;
}
.preloader {
  position: absolute;
  z-index: 20;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.preloader__modal {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.preloader__modal .modal-content {
  border: 0;
}
.preloader.top {
  -ms-flex-align: start;
      align-items: flex-start;
}
.preloader.fixed {
  position: fixed;
  z-index: 99;
}
.preloader.no-paddings {
  left: 15px;
  width: calc(100% - 30px);
}
.preloader .preloader-inner {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  height: 80%;
  max-height: 90px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 24px;
  text-align: center;
}

.reports__charts_title {
  margin-top: 16px;
  padding-left: 40px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  font-size: 16px;
}
.reports__charts_title .download-link {
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #3AB0D8;
  border-bottom-color: var(--primary-color, #3AB0D8);
}

.rating-reports button {
  padding: 0 10px !important;
}
.rating-reports button span {
  white-space: nowrap;
}
.rating-reports__modal-dialog {
  width: 70%;
  max-width: 70%;
}
@media screen and (max-width: 576px) {
  .rating-reports__modal-dialog {
    width: 95%;
    max-width: 95%;
  }
}
.rating-reports__modal-dialog .custom-tooltip {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 3px;
  border: 1px solid #D3D8E0;
}
.rating-reports__modal-dialog .react-numeric-input input {
  height: 42px;
}
.rating-reports__modal-dialog .report-section {
  display: contents;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(211, 216, 224, 0.5);
  margin-bottom: 50px;
}
.rating-reports__modal-dialog .report-section .scale-label {
  color: rgb(136, 132, 216);
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.rating-reports__modal-dialog .report-section hr {
  border-top: none;
  width: 100%;
  height: 1px;
  background: transparent;
  border-bottom: 1px solid rgba(211, 216, 224, 0.5);
}

.table-tariffs input[type=number]::-webkit-outer-spin-button,
.table-tariffs input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.table-tariffs input[type=number],
.table-tariffs input[type=number]:hover,
.table-tariffs input[type=number]:focus {
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: textfield;
}
.table-tariffs .dsg-container {
  /*height: 500px !important;*/
}
.table-tariffs .cell-required .dsg-cell-header-container span:after {
  content: "*";
  color: #ff7777;
}

.invalid-feedback {
  display: block;
}
.invalid-feedback:empty {
  display: none;
}

small {
  color: rgba(145, 152, 167, 0.5);
  vertical-align: middle;
}
small .btn-link {
  font-size: 13px;
}

.label.label-danger {
  color: #fff;
}

.close-btn, .close {
  width: 21px;
  height: 21px;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  opacity: 1;
  background: #f45d5d url("../img/icons/close-btn.png") 50% 50% no-repeat;
}
.close-btn.bg, .close.bg {
  width: 25px;
  height: 25px;
  border-radius: 12px;
  background-image: url("../img/icons/close-btn@2x.png");
}

.close:hover {
  opacity: 1;
}
.close:active {
  border: 0;
}

.modal-header .close {
  padding: 0;
  margin-right: -2px;
  margin-top: 2px;
}

.close > span {
  display: none;
}

.btn {
  border-radius: 7px;
  padding: 0.4rem 0.75rem;
  border-color: transparent;
}

.btn:focus, .btn.focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn.btn-full {
  width: 100%;
}
.btn.btn-secondary, .btn.btn-outline-primary {
  color: #313131;
  background-color: transparent;
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
}
.btn.btn-primary-new {
  border-radius: 3px;
  padding: 0px 40px;
  height: 48px;
  color: #fff;
  border-style: none;
  background-color: #3AB0D8 !important;
}
.btn.btn-primary-new:hover {
  background-color: #42caff !important;
}
.btn.btn-primary-new.no-padding {
  padding: 0.4rem 0.75rem;
}
.btn.btn-outline-primary-new {
  border-radius: 3px;
  padding: 0px 40px;
  height: 48px;
  color: #3AB0D8;
  border: 1px solid #3AB0D8;
}
@media screen and (max-width: 576px) {
  .btn.btn-outline-primary-new {
    min-height: 40px;
    height: auto !important;
  }
}
.btn.btn-outline-primary-new.height-auto {
  min-height: 40px;
  height: auto !important;
}
.btn.btn-outline-primary-new:hover {
  color: #42caff;
  border: 1px solid #42caff;
}
.btn.btn-outline-primary-new.active {
  color: #fff !important;
  border: 1px solid #3AB0D8 !important;
  background: #3AB0D8 !important;
}
.btn.btn-outline-primary-new.no-focus:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn.btn-outline-primary-new.no-padding {
  padding: 0.4rem 0.75rem;
}
.btn.btn-success-new {
  background-color: #28b862;
  border-radius: 3px;
  padding: 0px 40px;
  height: 48px;
  color: #fff;
  border-style: none;
}
.btn.btn-success-new:hover {
  background-color: #62CFA8;
}
.btn.btn-success-new.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn.btn-primary {
  color: #fff;
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
}
.btn.btn-primary:hover {
  background-color: #1e7998;
  background-color: var(--primary-darken-color, #1e7998);
  border-color: #1e7998;
  border-color: var(--primary-darken-color, #1e7998);
}
.btn.btn-success {
  color: #fff;
  background-color: #28b862;
}
.btn.btn-success:hover {
  background-color: #186d3a;
  border-color: #186d3a;
}
.btn.btn-info {
  color: #fff;
  background-color: #3AB0D8;
  background-color: var(--primary-active-color, #3AB0D8);
}
.btn.btn-info:hover {
  background-color: #1e7998;
  background-color: var(--primary-active-darken-color, #1e7998);
  border-color: #1e7998;
  border-color: var(--primary-active-darken-color, #1e7998);
}
.btn.btn-info a {
  color: #fff;
  font-weight: normal;
}
.btn.btn-link {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  text-decoration: none;
  padding: 0;
  vertical-align: middle;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #3AB0D8;
  border-bottom-color: var(--primary-color, #3AB0D8);
  border-radius: 0;
}
.btn.btn-link:hover {
  border-bottom-color: transparent;
}
.btn.btn-change {
  color: #fff;
  text-decoration: none;
  background-color: #3AB0D8;
  padding: 10px;
  margin-top: auto;
  width: 100%;
  border-radius: 3px;
  font-size: 11px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  /* or 145% */
  text-transform: uppercase;
}
.btn.btn-change:hover {
  background-color: #3AB0D8;
}
.btn.btn-container-box {
  background-color: white;
  border: 1px solid #c6cad2;
  color: #c6cad2;
  margin: 0 5px;
}
.btn.btn-container-box:hover {
  border-width: 1px;
  border-style: solid;
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
  color: #000;
}
.btn.btn-container-box.active {
  border-width: 1px;
  border-style: solid;
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
  color: #fff;
}
.btn.btn-filter {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
  border-radius: 0;
  border: none;
  padding: 0;
  margin: 0;
  height: 16px;
  background-color: transparent;
  opacity: 0.5;
}
.btn.btn-filter.active {
  opacity: 1;
}
.btn.btn-filter:hover {
  opacity: 1;
}
.btn.btn-remove {
  background: url("../img/icons/remove.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}
.btn.btn-remove:hover {
  opacity: 0.8;
}
.btn.btn-remove:not(:disabled):not(.disabled):active {
  opacity: 0.8;
  background-image: url("../img/icons/remove.png");
}
.btn.btn-remove-crud-list {
  background: url("../img/icons/remove-crud.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}
.btn.btn-remove-crud-list:hover {
  background-image: url("../img/icons/remove-crud_hover.png");
}
.btn.btn-remove-crud-list:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/remove-crud_hover.png");
}
.btn.btn-view-crud-list {
  background: url("../img/icons/view.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}
.btn.btn-view-crud-list:hover {
  background: url("../img/icons/view_hover.png") no-repeat center;
}
.btn.btn-view-crud-list:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/view_hover.png");
}
.btn.btn-update-crud-list {
  background: url("../img/icons/refresh.svg") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}
.btn.btn-update-crud-list:hover {
  background: url("../img/icons/refresh.svg") no-repeat center;
}
.btn.btn-update-crud-list:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/refresh.svg");
}
.btn.btn-email-crud-list {
  background: url("../img/icons/email.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}
.btn.btn-email-crud-list:hover {
  background: url("../img/icons/email.png") no-repeat center;
  background-color: #28b862;
}
.btn.btn-email-crud-list:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/email.png");
}
.btn.btn-download-crud-list {
  background: url("../img/icons/view.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.btn.btn-download-crud-list:hover {
  background: url("../img/icons/view_hover.png") no-repeat center;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.btn.btn-download-crud-list:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/view_hover.png");
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.btn.btn-upload-crud-list {
  background: url("../img/icons/view.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.btn.btn-upload-crud-list:hover {
  background: url("../img/icons/view_hover.png") no-repeat center;
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.btn.btn-upload-crud-list:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/view_hover.png");
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.btn.btn-remove-crud-list-mobile {
  background: url("../img/icons/remove-crud-mobile.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}
.btn.btn-remove-crud-list-mobile:hover {
  background-image: url("../img/icons/remove-crud-mobile_hover.png");
}
.btn.btn-remove-crud-list-mobile:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/remove-crud-mobile_hover.png");
}
.btn.btn-update-crud-list-mobile {
  background: url("../img/icons/refresh.svg") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}
.btn.btn-update-crud-list-mobile:hover {
  background: url("../img/icons/refresh.svg") no-repeat center;
}
.btn.btn-update-crud-list-mobile:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/refresh.svg");
}
.btn.btn-view-crud-list-mobile {
  background: url("../img/icons/view_mobile.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
}
.btn.btn-view-crud-list-mobile:hover {
  background: url("../img/icons/view-mobile_hover.png") no-repeat center;
}
.btn.btn-view-crud-list-mobile:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/view-mobile_hover.png");
}

.btn-controls {
  margin-top: 20px;
}
.btn-controls button {
  margin-right: 10px;
}

.tbl-btn {
  margin-left: 8px;
  width: 48px;
  height: 48px;
  border-radius: 3px;
  color: #C4C4C4 !important;
  border: 1px solid #D3D8E0 !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.tbl-btn.active {
  color: #fff !important;
  background-color: #3AB0D8 !important;
  border: 1px solid #42caff !important;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  padding: 0.225rem 0.5rem;
  padding-left: 0.75rem;
  padding-right: 1.5rem;
}

.input-group > .f-control:not(:first-child) .Select-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #3AB0D8;
}
.Select-input {
  padding-left: 4px;
}
.Select--multi .Select-value {
  display: -ms-flexbox;
  display: flex;
}
.Select--multi .Select-input {
  margin-left: 3px;
}
.Select--multi .Select-multi-value-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.Select--multi.has-value .Select-input {
  display: -ms-flexbox !important;
  display: flex !important;
  height: 22px;
  margin-top: 6px;
}
.Select--multi.has-value .Select-multi-value-wrapper {
  padding-bottom: 6px;
}
.Select > .Select-control {
  height: auto;
  padding: 0.225rem 0.5rem;
  border-color: #c6cad2;
  border-radius: 3px;
}
.Select > .Select-control:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.Select .Select-arrow-zone {
  width: 17px;
  background: url("../img/icons/select-arrow.png") center no-repeat;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding-left: 5px;
}
.Select .Select-arrow-zone .Select-arrow {
  border-width: 0 !important;
}
.Select.is-open > .Select-control {
  border-radius: 3px;
}
.Select.is-focused .Select-control {
  border-color: #3AB0D8;
}
.Select.is-focused .Select-arrow-zone {
  background-image: url("../img/icons/blue/select-arrow-focused.svg");
}
.Select.is-focused.color-yellow .Select-arrow-zone {
  background-image: url("../img/icons/yellow/select-arrow-focused.svg");
}
.Select .Select-menu-outer {
  margin-top: 10px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-width: 0;
  -webkit-box-shadow: 0px 6px 32px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0px 6px 32px 0 rgba(0, 5, 68, 0.25);
  z-index: 1000;
  border-radius: 20px;
}
.Select .Select-option:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.Select .Select-option.is-focused {
  background-color: #3AB0D8;
  color: #fff;
}
.Select .Select-option.is-focused:not(.is-focused) {
  background-color: #fff;
  color: #333;
}
.Select .Select-option.is-selected {
  background-color: #3AB0D8;
  color: #333;
}
.Select .Select-option.is-selected:hover {
  background-color: #3AB0D8;
  color: #fff;
}

.low-height-select {
  height: 38px !important;
}
.low-height-select .Select-control .Select-value {
  line-height: 22px !important;
  padding: 0.5rem 1.5rem 0.5rem 0.75rem !important;
}
.low-height-select .Select-input {
  height: 30px;
}

.Select5V__placeholder, .Select5V--single > .Select5V__control .Select5V-value {
  /*padding: $select-padding;
  padding-left: 0.75rem;
  padding-right: 1.5rem;*/
}

.input-group > .f__control:not(:first-child) .Select5V__control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Select5V.--is-focused:not(.--is-open) > .Select5V__control {
  border-color: #3AB0D8 !important;
}
.Select5V__input {
  padding-left: 4px;
}
.Select5V__control {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  height: 48px;
  border-radius: 3px;
  border: 1px solid #d3d8e0;
}
.Select5V__multi .Select5V__value {
  display: -ms-flexbox;
  display: flex;
}
.Select5V__multi .Select5V__input {
  margin-left: 3px;
}
.Select5V__multi .Select5V-multi-value-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.Select5V__multi.--has-value .Select5V-input {
  display: -ms-flexbox !important;
  display: flex !important;
  height: 22px;
  margin-top: 6px;
}
.Select5V__multi.--has-value .Select5V-multi-value-wrapper {
  padding-bottom: 6px;
}
.Select5V > .Select5V__control {
  height: auto;
  padding: 0.225rem 0.5rem;
  border-color: #c6cad2;
  border-radius: 3px;
}
.Select5V > .Select5V__control:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.Select5V .Select5V__arrow-zone {
  width: 17px;
  background: url("../img/icons/select-arrow-new.svg") center no-repeat;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding-left: 5px;
}
.Select5V .Select5V__arrow-zone .Select5V-arrow {
  border-width: 0 !important;
}
.Select5V.__control--is-open > .Select5V__control {
  border-radius: 3px;
}
.Select5V.__control--is-focused > .Select5V__control {
  border-color: #3AB0D8 !important;
}
.Select5V.__control--is-focused .Select5V__dropdown-indicator {
  background-image: url("../img/icons/select-arrow-down-new.svg");
}
.Select5V.__control--is-focused.color-yellow .Select5V-arrow-zone {
  background-image: url("../img/icons/yellow/select-arrow-focused.svg");
}
.Select5V .Select5V-menu-outer {
  margin-top: 10px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-width: 0;
  -webkit-box-shadow: 0px 6px 32px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0px 6px 32px 0 rgba(0, 5, 68, 0.25);
  z-index: 1000;
  border-radius: 20px;
}

.Select5V__indicator-separator {
  display: none;
}

.Select5V__indicators {
  width: auto;
  margin-right: 8px;
}

.Select5V__dropdown-indicator {
  width: 17px;
  background: url("../img/icons/select-arrow-new.svg") center no-repeat;
  padding-left: 10px !important;
}
.Select5V__dropdown-indicator svg {
  display: none;
}

.Select5V__control--is-focused .Select5V__dropdown-indicator {
  background-image: url("../img/icons/select-arrow-down-new.svg");
}

.Select5V__value-container {
  padding: 0.5rem 1.5rem 0.5rem 0.75rem !important;
  /*margin-left: 3px;*/
}

.Select5V__option:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.Select5V__option:focus, .Select5V__option--is-selected {
  background-color: #3AB0D8;
  color: #fff;
}
.Select5V__option.--is-focused:not(.--is-focused) {
  background-color: #fff;
  color: #333;
}
.Select5V__option:hover {
  background-color: #3AB0D8 !important;
  color: #fff;
}
.Select5V__option--is-focused {
  background-color: #3AB0D8 !important;
  color: #333;
}
.Select5V__option--is-focused:hover {
  background-color: #3AB0D8 !important;
  color: #fff;
}

.Select5V.no-border > .Select5V__control {
  border-color: #e8ebed !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0;
}

.Select5V.small .Select5V__value-container, .Select5V.crud-select .Select5V__value-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.Select5V.small .Select5V__control, .Select5V.crud-select .Select5V__control {
  height: 42px;
}

.crud-select .Select5V__value-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.crud-select .Select5V__control {
  height: 42px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.Select5V__multi-value {
  padding: 3px !important;
  color: #007eff;
  background-color: rgba(0, 126, 255, 0.08) !important;
  border-color: rgba(0, 126, 255, 0.24) !important;
  border-width: 1px;
  border-style: solid;
}

.Select5V__multi-value__label {
  color: #007eff !important;
  padding-bottom: 0 !important;
  line-height: 85%;
}

.Select5V__multi-value__remove {
  /*border-left: 1px solid rgba(0, 126, 255, 0.24) !important;*/
}

h1, h2, h3, h4, h5 {
  color: #313131;
  font-weight: normal;
}

h1 {
  font-size: 20px;
}

.page-header {
  margin-top: 31px;
  margin-bottom: 26px;
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
}
.page-header h1 {
  margin: 0;
  padding: 14px 21px;
  font-size: 21px;
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
.page-header h1 > .page-header__total {
  font-size: 15px;
  color: #9198a7;
}

h2, h5.modal-title {
  font-size: 17px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 13px;
}

.form-control {
  /* Firefox 19+ */
  /* Firefox 18- */
}
.form-control::-webkit-input-placeholder {
  color: rgba(145, 152, 167, 0.5);
}
.form-control::-moz-placeholder {
  color: rgba(145, 152, 167, 0.5);
}
.form-control:-moz-placeholder {
  color: rgba(145, 152, 167, 0.5);
}
.form-control:-ms-input-placeholder {
  color: rgba(145, 152, 167, 0.5);
}

.form-group {
  margin-bottom: 13px;
}

.form-group-last {
  margin-bottom: 0;
}

.form-control {
  padding: 0.5rem 0.75rem;
}
.form-control.bg {
  padding: 0.6rem 1rem;
}
.form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
}

input[type=text], input[type=password], textarea, .form-control {
  border-color: #c6cad2;
  border-radius: 3px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.input-icon {
  width: 17px;
  height: 17px;
  margin: 13px 10px;
  display: inline-block;
  position: absolute;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.input-icon.email {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/email.svg");
}
.input-icon.email.color-yellow {
  background-image: url("../img/icons/yellow/email.svg");
}
.input-icon.lock {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/lock.svg");
}
.input-icon.lock.color-yellow {
  background-image: url("../img/icons/yellow/lock.svg");
}

.input-icon + input, .input-icon + .form-group input {
  padding-left: 38px;
  content: "";
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pass-container {
  position: relative;
}

.pass-icon {
  width: 17px;
  height: 17px;
  margin: 13px 10px;
  display: inline-block;
  right: 0;
  position: absolute;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.pass-icon.eye {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/eye.svg");
}
.pass-icon.eye.color-yellow {
  background-image: url("../img/icons/yellow/eye.svg");
}

.pass-icon.eye-disable {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/eye-disable.svg");
}
.pass-icon.eye-disable.color-yellow {
  background-image: url("../img/icons/yellow/eye-disable.svg");
}

.pass-icon + input {
  padding-right: 38px;
  content: "";
}

.input-group {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.form-group .f-label {
  color: rgba(145, 152, 167, 0.5);
  font-size: 14px;
  margin-bottom: 5px;
}
.form-group .f-control {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
}
.form-group .input-group-text {
  -ms-flex-pack: end;
      justify-content: flex-end;
  text-align: right;
}
.form-group .input-group-text {
  min-width: 240px;
}
.form-group .right-addon .input-group-text {
  padding: 0;
  min-width: 0;
}
.form-group .input-group-append .btn {
  border-radius: 1.5px;
}

.f-group {
  display: -ms-flexbox;
  display: flex;
}
.f-group .f-element.f-element-number input {
  border-radius: 0;
}
.f-group .f-element.f-element-select .Select-control {
  border-radius: 0;
}
.f-group .f-element.f-element-select .Select-menu-outer {
  width: auto !important;
  right: 0 !important;
  left: auto !important;
  white-space: nowrap;
}
.f-group .f-element:first-child.f-element-number input {
  border-radius: 4px 0 0 4px;
}
.f-group .f-element:first-child.f-element-select .Select-control {
  border-radius: 4px 0 0 4px;
}
.f-group .f-element:last-child.f-element-number input {
  border-radius: 0 4px 4px 0;
}
.f-group .f-element:last-child.f-element-select .Select-control {
  border-radius: 0 4px 4px 0;
}

.f-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.f-inline label {
  margin-bottom: 0;
}
.f-inline .form-group {
  margin-bottom: 0;
}
.f-inline .f-element {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.f-inline .f-control {
  margin-bottom: 0;
}

.radio-btn .form-check-label {
  margin: 5px 10px 5px 10px;
}

.form-check {
  padding-left: 0;
}

.form-check-input {
  position: static;
  margin-left: 0;
}

.size-input .f-group, .hatch-size-input .f-group, .coordinate-input .f-group {
  width: 100%;
}
.size-input .form-group, .hatch-size-input .form-group, .coordinate-input .form-group {
  margin-bottom: 0;
  max-height: 42px;
}

.nowrap-input-left-addon {
  max-width: 215px;
  white-space: inherit;
}

.wrap-input-left-addon {
  max-width: 215px;
  white-space: normal;
}

.form-check-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.form-check-label span {
  -ms-flex-order: 2;
      order: 2;
}
.form-check-label .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  -ms-flex-order: 1;
      order: 1;
}
.form-check-label .form-check-input:checked ~ .checkmark:after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 4px;
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
}
.form-check-label .form-check-input:disabled ~ .checkmark {
  opacity: 0.3;
}
.form-check-label .form-check-input:disabled + span {
  opacity: 0.5;
}
.form-check-label .checkmark {
  -ms-flex-order: 0;
      order: 0;
  position: relative;
  margin-right: 10px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
  background-color: #fff;
}

.form-checkbox-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.form-checkbox-label span {
  -ms-flex-order: 2;
      order: 2;
}
.form-checkbox-label .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  -ms-flex-order: 1;
      order: 1;
}
.form-checkbox-label .form-check-input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  width: 7px;
  height: 12px;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 0;
}
.form-checkbox-label .checkmark {
  -ms-flex-order: 0;
      order: 0;
  position: relative;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
  background-color: #fff;
}

.pane {
  padding: 13px 13px 17px;
  -webkit-box-shadow: 0px 6px 32px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0px 6px 32px 0 rgba(0, 5, 68, 0.25);
  background-color: #fff;
  border-radius: 6px;
  width: 262px;
}
.pane.pane-bg {
  width: 350px;
}
.pane.pane-hg {
  width: 500px;
}
.pane.pane-sm {
  width: 200px;
}
.pane.pane-xs {
  width: 150px;
}
.pane.pane-primary {
  margin: 35px auto 0;
}
.pane .pane-header {
  padding-bottom: 10px;
}
.pane .close-btn {
  float: right;
}

.alert {
  padding: 0.6rem 1rem;
  line-height: 19px;
  font-size: 15px;
}

.alert-danger {
  background-color: #ff7777;
  border-color: transparent;
  color: #fff;
}

.modal-dialog {
  -webkit-box-shadow: 0 6px 32px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 6px 32px 0 rgba(0, 5, 68, 0.25);
}
.modal-dialog .modal-header {
  min-height: 55px;
}

.modal-backdrop.show {
  opacity: 0.3;
}

.contactModal .input-group-text {
  min-width: 100px;
}

.f-element-select.has-danger .Select-control {
  border-color: #f45d5d;
}
.f-element-select.has-danger .Select-control .Select-placeholder {
  color: #f45d5d;
}

.f-element-address.has-danger .geosuggest__input {
  border-color: #f45d5d;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #f45d5d;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.f-element-address.has-danger .geosuggest__input::-webkit-input-placeholder {
  color: #f45d5d;
}
.f-element-address.has-danger .geosuggest__input::-moz-placeholder {
  color: #f45d5d;
}
.f-element-address.has-danger .geosuggest__input:-moz-placeholder {
  color: #f45d5d;
}
.f-element-address.has-danger .geosuggest__input:-ms-input-placeholder {
  color: #f45d5d;
}

.f-element-number.has-danger span input {
  border: 1px solid #f45d5d !important;
  color: #f45d5d;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.f-element-number.has-danger span input::-webkit-input-placeholder {
  color: #f45d5d;
}
.f-element-number.has-danger span input::-moz-placeholder {
  color: #f45d5d;
}
.f-element-number.has-danger span input:-moz-placeholder {
  color: #f45d5d;
}
.f-element-number.has-danger span input:-ms-input-placeholder {
  color: #f45d5d;
}

.f-element-text.has-danger input {
  border: 1px solid #f45d5d !important;
  color: #f45d5d;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.f-element-text.has-danger input::-webkit-input-placeholder {
  color: #f45d5d;
}
.f-element-text.has-danger input::-moz-placeholder {
  color: #f45d5d;
}
.f-element-text.has-danger input:-moz-placeholder {
  color: #f45d5d;
}
.f-element-text.has-danger input:-ms-input-placeholder {
  color: #f45d5d;
}

.f-element-date.has-danger input {
  border: 1px solid #f45d5d !important;
  color: #f45d5d;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.f-element-date.has-danger input::-webkit-input-placeholder {
  color: #f45d5d;
}
.f-element-date.has-danger input::-moz-placeholder {
  color: #f45d5d;
}
.f-element-date.has-danger input:-moz-placeholder {
  color: #f45d5d;
}
.f-element-date.has-danger input:-ms-input-placeholder {
  color: #f45d5d;
}
.f-element-date.has-warning input {
  border: 1px solid #ffc107 !important;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.f-element-date.has-warning input::-webkit-input-placeholder {
  color: #f45d5d;
}
.f-element-date.has-warning input::-moz-placeholder {
  color: #f45d5d;
}
.f-element-date.has-warning input:-moz-placeholder {
  color: #f45d5d;
}
.f-element-date.has-warning input:-ms-input-placeholder {
  color: #f45d5d;
}

.table-custom-prices {
  border: 1px solid #c6cad2;
  border-radius: 3px;
}
.table-custom-prices .select-wrapper, .table-custom-prices .row-cell, .table-custom-prices .f-element-number {
  height: 38px !important;
}
.table-custom-prices .select-wrapper.textarea, .table-custom-prices .row-cell.textarea, .table-custom-prices .f-element-number.textarea {
  height: auto !important;
}
.table-custom-prices .select-wrapper .Select-control .Select-value, .table-custom-prices .row-cell .Select-control .Select-value, .table-custom-prices .f-element-number .Select-control .Select-value {
  line-height: 22px !important;
  padding: 0.5rem 1.5rem 0.5rem 0.75rem !important;
}
.table-custom-prices .select-wrapper .Select-input, .table-custom-prices .row-cell .Select-input, .table-custom-prices .f-element-number .Select-input {
  height: 30px;
}
.table-custom-prices .f-element {
  margin-bottom: 0 !important;
}
.table-custom-prices .row-table {
  padding: 10px 5px;
}
@media screen and (max-width: 768px) {
  .table-custom-prices .row-table .col-12 {
    padding-left: 0;
  }
}
.table-custom-prices .row-table.is-error .select-wrapper, .table-custom-prices .row-table.is-error .row-cell {
  height: auto !important;
}
.table-custom-prices .row-table:nth-child(odd) {
  background-color: rgba(198, 202, 210, 0.1);
}
.table-custom-prices .table-header .row-cell {
  height: auto;
}

.table-custom-prices-mobile {
  border-radius: 3px;
}
.table-custom-prices-mobile .f-element {
  margin-bottom: 0 !important;
}
.table-custom-prices-mobile .row-table {
  border: 1px solid #c6cad2;
  border-radius: 3px;
  margin-top: 20px;
  padding: 10px 10px;
}
.table-custom-prices-mobile .row-table.no-border {
  border: none;
}
.table-custom-prices-mobile .row-table:last-child {
  margin-top: 0;
  border: none;
  background: transparent;
}
@media screen and (max-width: 768px) {
  .table-custom-prices-mobile .row-table .row-cell {
    padding-bottom: 10px;
    padding-left: 0;
  }
}
.table-custom-prices-mobile .table-header .row-cell {
  height: auto;
}

.error-tab {
  background-color: #f45d5d;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  border: 1px solid #d9534f;
  padding: 3px;
  font-size: 12px;
  margin-left: 10px;
}

.cssEditor .rse-StyleEditor-root {
  background: white;
  min-height: 200px;
}
.cssEditor .btn-view-container {
  position: absolute;
  z-index: 99;
  right: 20px;
  display: -ms-flexbox;
  display: flex;
  margin: 5px;
}
.cssEditor .btn-view-container .btn-view-code, .cssEditor .btn-view-container .btn-view-text {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
}
.cssEditor .btn-view-container .btn-view-code.active, .cssEditor .btn-view-container .btn-view-text.active {
  color: #3AB0D8;
}
.cssEditor .textarea-code textarea {
  font-family: Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  text-align: left;
  color: black;
  min-height: 200px;
}

.htmlEditor .rsw-editor {
  background: white;
}
.htmlEditor .rsw-editor .rsw-toolbar {
  background: white;
}
.htmlEditor .rsw-editor .rsw-toolbar .rsw-btn:hover {
  color: #3AB0D8;
}
.btn.btn-attach-file {
  line-height: 40px;
  border-radius: 3px;
  padding: 0px 40px;
  max-width: 215px;
  min-width: 215px;
  height: 40px;
  color: #313131;
  border-style: none;
  background-color: #e5e5e5;
}
.btn.btn-attach-file:hover {
  background-color: #c0c0c0;
}
.btn.btn-primary-new {
  height: 40px;
}
.btn.btn-outline-primary-new {
  height: 40px;
  background-color: transparent;
  border: 1px solid #3AB0D8;
  color: #3AB0D8;
}
.btn.btn-outline-primary-new:hover {
  color: white;
  background-color: #3AB0D8;
}
.btn.btn-download {
  padding: 5px;
  display: block;
  height: 30px;
  line-height: 20px;
  background-color: transparent;
  color: #28b862;
  max-width: 30px;
  font-size: 20px;
  min-width: 30px;
  border-radius: 3px;
  border: none;
}
.btn.btn-download:hover {
  color: white;
  background-color: #28b862;
}
.btn.btn-replace {
  padding: 5px;
  display: block;
  height: 30px;
  line-height: 20px;
  background-color: transparent;
  color: #3AB0D8;
  max-width: 30px;
  font-size: 20px;
  min-width: 30px;
  border-radius: 3px;
  border: none;
}
.btn.btn-replace:hover {
  color: white;
  background-color: #3AB0D8;
}
.btn.btn-delete {
  padding: 5px;
  font-size: 20px;
  height: 30px;
  line-height: 20px;
  display: block;
  background-color: transparent;
  color: #f45d5d;
  max-width: 30px;
  min-width: 30px;
  border-radius: 3px;
  border: none;
}
.btn.btn-delete:hover {
  color: white;
  background-color: #f45d5d;
}

.input-file-label {
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  text-align: left;
}

.form-group .input-group-text {
  min-width: 215px;
}

.table-data {
  margin-top: 20px;
  background-color: #fff;
  font-weight: normal;
}
.table-data thead tr th {
  border-top: none;
  border-bottom: 1px solid #dee2e6;
  padding: 14px 4px;
  color: #a2aabb;
  font-size: 13px;
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
}
.table-data tbody tr {
  min-height: 40px;
}
.table-data tbody tr th {
  border: none;
  padding: 8px 4px;
  color: #a2aabb;
  font-size: 13px;
  text-align: left;
  vertical-align: middle;
}

.actions-templates {
  max-width: 180px;
  display: -ms-flexbox;
  display: flex;
}

.row-template:hover .actions-templates {
  display: -ms-flexbox;
  display: flex;
}

@font-face {
  font-family: PFDinDisplayPro-Regular; /* Имя шрифта */
  src: url(../fonts/PFDinDisplayPro-Regular/PFDinDisplayPro-Regular.ttf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: Roboto; /* Имя шрифта */
  src: url(../fonts/Roboto-Regular.ttf); /* Путь к файлу со шрифтом */
}
html {
  scroll-behavior: smooth;
}

:root {
  --primary-active-color: #3AB0D8;
  --primary-active-darken-color: #1e7998;
  --primary-bg-color: #276A9B;
  --primary-color: #3AB0D8;
  --primary-darken-color: #1e7998;
  --primary-hover-color: #66a6ff;
  --icons-postfix: ;
}

body {
  font-family: Roboto;
  background-color: #f5f5f5;
  color: #313131;
}
body .Select,
body button,
body a {
  cursor: pointer;
}
body input,
body a {
  outline: none;
}
body input:focus, body input:hover, body input:active,
body a:focus,
body a:hover,
body a:active {
  outline: none;
}
body .is-focused.Select-control {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
body h4 {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
body .main-layout {
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
body .main-layout .header,
body .main-layout .footer {
  z-index: 2;
}
body .main-layout .header.menu-close,
body .main-layout .footer.menu-close {
  padding-left: 60px;
}
body .main-layout .header.menu-open,
body .main-layout .footer.menu-open {
  padding-left: 255px;
}
body .main-layout .main {
  -ms-flex-positive: 1;
      flex-grow: 1;
  z-index: 1;
}
body .main-layout .main.menu-close {
  padding-left: 60px;
}
body .main-layout .main.menu-close .single-col.col-12 {
  padding-left: 0;
  padding-right: 0;
}
body .main-layout .main.menu-open {
  padding-left: 255px;
}
body .main-layout .main .nav.nav-tabs .nav-item {
  color: #3AB0D8;
  color: var(--primary-color, #3AB0D8);
}
body .main-layout .g-flash-messages {
  position: fixed;
  z-index: 1500;
  top: 8px;
  min-width: 300px;
  right: 25px;
}
body .main-layout .page-item.active .page-link {
  z-index: auto;
}
body .main-layout_widget {
  height: auto;
  display: block;
  -ms-flex-direction: unset;
      flex-direction: unset;
}
body .main-layout_widget .main {
  -ms-flex-positive: unset;
      flex-grow: unset;
}
body .main-layout_widget .container {
  max-width: unset !important;
}
body .flag-required:after {
  content: " *";
  color: red;
  font-weight: bold;
}
body .col-md-114 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
}
body .col-md-118 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
}

.swap-button {
  margin-bottom: 10px;
  margin-top: -52px;
  z-index: 100;
}

.wizard__direction-block_header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}

.fb-btn {
  margin-right: 0.5rem !important;
  margin-top: 0.25rem !important;
  color: #1a25ab;
}

.m-vk {
  margin-right: 0.5rem !important;
  margin-left: -3.5px !important;
}

.tracking_body {
  padding: 24px 21px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
}

.id_title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #1a25ab;
}

.track-block {
  margin-top: 1vw;
  margin-bottom: 28px;
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0 1px 3px 0 rgba(0, 5, 68, 0.25);
  cursor: pointer;
}
.track-block:hover {
  -webkit-box-shadow: 0 0 2px 2px #1a25ab;
          box-shadow: 0 0 2px 2px #1a25ab;
}

.track-country {
  font-size: 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 0.5vw;
}
.track-country:nth-child(2) {
  margin-bottom: 1.2vw;
}

.track-country-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
}

.plane-label-for-eta {
  position: absolute;
  bottom: 22px;
  color: red;
}

.track-type-icon {
  color: #1a25ab;
  font-size: 2rem;
  margin-right: 10px;
  text-align: center;
}

.order-form-body {
  padding: 24px 21px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  margin-bottom: 28px;
  border: 1px solid #dee2e6;
  border-top: 0;
}

.order-tabs-body {
  padding: 24px 21px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  margin-bottom: 28px;
  border-left: 1px solid #dee2e6;
}

.btn-upload-crud-list {
  background: url("../img/icons/view.png") no-repeat center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
  cursor: pointer;
  margin-right: 0.5rem !important;
}
.btn-upload-crud-list:hover {
  background: url("../img/icons/view_hover.png") no-repeat center;
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
  cursor: pointer;
}
.btn-upload-crud-list:not(:disabled):not(.disabled):active {
  background-image: url("../img/icons/view_hover.png");
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
  cursor: pointer;
}

.contacts-title {
  color: #1a25ab;
  font-weight: 500;
  font-size: 1.5rem;
}

.button-style-edit {
  color: #000;
  font-size: 2vw;
  cursor: pointer;
}
.button-style-edit:hover {
  color: #1a25ab;
}

.button-style-delete {
  color: #000;
  font-size: 2vw;
  margin-left: 20px;
  cursor: pointer;
}
.button-style-delete:hover {
  color: #f45d5d;
}

.header-button-style-back {
  color: white;
  font-size: 39px;
  cursor: pointer;
  margin-right: 2vw;
}
.header-button-style-edit {
  color: white;
  font-size: 39px;
  cursor: pointer;
}
.header-button-style-alarm {
  color: white;
  font-size: 39px;
  cursor: pointer;
}
.span-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.div-block {
  display: -ms-flexbox;
  display: flex;
}

.span-list-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.empty-place {
  width: 45px;
}

.logistic-modal .form-control:focus {
  border-color: #4996FF;
}
.logistic-modal.content {
  max-width: 100% !important;
}
.logistic-modal.content .date-tracking input {
  height: 35px;
  max-width: 225px;
}
@media screen and (max-width: 991px) {
  .logistic-modal.content .date-tracking .date-fact {
    padding-left: 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .logistic-modal.content .date-tracking .date-fact {
    padding-left: 0 !important;
  }
}
.logistic-modal__header-item {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #333;
  margin-bottom: 26px;
  margin-top: 26px;
}
.logistic-modal__btn-manage {
  margin-top: 30px !important;
}
.logistic-modal__btn-manage .btn-primary {
  background-color: #4996FF;
  border-radius: 3px;
}
.logistic-modal__btn-manage .btn-primary:hover {
  background-color: white;
  color: #4996FF;
  border-color: #4996FF;
}
.logistic-modal__btn-manage .btn-secondary {
  border-color: #4996FF !important;
  color: #4996FF !important;
  border-radius: 3px;
}
.logistic-modal__btn-manage .btn-secondary:hover {
  background-color: #4996FF !important;
  color: white !important;
}
.logistic-modal__btn-manage .col-12 {
  padding-right: 15px !important;
}
@media screen and (max-width: 991px) {
  .logistic-modal__btn-manage .col-12 {
    padding-right: 0 !important;
  }
}
.logistic-modal__text-input {
  /*max-width: 538px;*/
  font-size: 14px;
}
.logistic-modal__text-input input {
  font-size: 14px;
}
.logistic-modal__text-input input:focus {
  border-color: #4996FF;
}
@media screen and (max-width: 768px) {
  .logistic-modal__text-input {
    max-width: 100%;
  }
}
.logistic-modal__text-area textarea.form-control {
  min-height: 104px;
  /*max-width: 538px;*/
  font-size: 14px;
}
.logistic-modal__text-area textarea.form-control:focus {
  border-color: #4996FF;
}
@media screen and (max-width: 768px) {
  .logistic-modal__text-area textarea.form-control {
    max-width: 100%;
  }
}
.logistic-modal__delivery_block {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.logistic-modal__delivery_block .item-padding {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
@media screen and (max-width: 991px) {
  .logistic-modal__delivery_block .item-padding:first-child {
    padding-left: 0 !important;
  }
  .logistic-modal__delivery_block .item-padding:last-child {
    padding-left: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .logistic-modal__delivery_block .item-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.logistic-modal__delivery_block p {
  margin-bottom: 0.3rem;
  font-size: 16px;
  color: #333;
}
.logistic-modal__delivery_block input {
  font-size: 14px;
}
.logistic-modal__delivery_block input:focus {
  border-color: #4996FF;
}
.logistic-modal__table-cargo .col-12, .logistic-modal__table-cargo .col-6 {
  padding: 0 0 0 15px !important;
}
.logistic-modal__table-cargo .col-12:last-child, .logistic-modal__table-cargo .col-6:last-child {
  padding-right: 15px !important;
}
@media screen and (max-width: 768px) {
  .logistic-modal__table-cargo .col-12, .logistic-modal__table-cargo .col-6 {
    padding-left: 0 !important;
    padding-right: 15px !important;
  }
  .logistic-modal__table-cargo .col-12:nth-child(2n+1), .logistic-modal__table-cargo .col-6:nth-child(2n+1) {
    padding-right: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .logistic-modal__table-cargo {
    border: 1px solid rgba(211, 216, 224, 0.5);
    padding: 23px 8px;
  }
}
.logistic-modal__table-cargo .f-element-number {
  width: 100%;
}
.logistic-modal__table-cargo .form-group {
  margin-bottom: 0px;
}
@media screen and (min-width: 768px) {
  .logistic-modal__table-cargo_cargo-block-container .pl-small-0 {
    padding-left: 15px;
  }
  .logistic-modal__table-cargo_cargo-block-container .pr-small-0 {
    padding-right: 15px;
  }
  .logistic-modal__table-cargo_cargo-block-container .px-small-0 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 768px) {
  .logistic-modal__table-cargo_cargo-block-container .pl-small-0 {
    padding-left: 0;
  }
  .logistic-modal__table-cargo_cargo-block-container .pr-small-0 {
    padding-right: 0;
  }
  .logistic-modal__table-cargo_cargo-block-container .px-small-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
.logistic-modal__table-cargo_cargo-block-container .number-cargo {
  height: 42px;
  min-width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-color: rgba(211, 216, 224, 0.2);
  font-size: 14px;
  color: #333;
  text-align: center;
  line-height: 42px;
}
@media screen and (max-width: 768px) {
  .logistic-modal__table-cargo_cargo-block-container .cargo-container-field {
    -ms-flex-item-align: end;
        align-self: flex-end;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .logistic-modal__table-cargo_cargo-block-container .cargo-container-field.vehicle {
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 576px) {
  .logistic-modal__table-cargo_cargo-block-container .cargo-container-field.size {
    padding-right: 0 !important;
    padding-left: 15px !important;
  }
}
.logistic-modal__table-cargo_cargo-block-container .cargo-container-field p {
  margin-bottom: 0.3rem;
}
.logistic-modal__table-cargo_cargo-block-container .cargo-container-field .Select-value {
  font-size: 14px;
}
.logistic-modal__table-cargo_cargo-block-container .cargo-container-field input {
  font-size: 14px;
  min-height: 42px;
}
.logistic-modal__table-cargo_cargo-block-container .cargo-container-field input:focus {
  border-color: #4996FF;
}
.logistic-modal__document-block {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.logistic-modal__document-block .document-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  margin-right: 12px;
  color: #333333;
}
@media screen and (max-width: 768px) {
  .logistic-modal__document-block .document-label {
    line-height: 24px;
    margin-bottom: 0;
  }
}
.logistic-modal__document-block .document-copy {
  margin-left: 24px;
  display: -ms-flexbox;
  display: flex;
  background: rgba(73, 150, 255, 0.1);
  border-radius: 3px;
  cursor: pointer;
  height: 40px;
}
@media screen and (max-width: 768px) {
  .logistic-modal__document-block .document-copy {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: 0;
    margin-bottom: 1rem;
  }
}
.logistic-modal__document-block .document-copy:hover {
  background: rgba(73, 150, 255, 0.2);
}
.logistic-modal__document-block .document-copy .icon-copy {
  margin-left: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 24px;
  width: 24px;
  background-image: url("../../assets/img/orders/copy-icon.svg");
}
.logistic-modal__document-block .document-copy .text-copy {
  margin: 12px 12px 12px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}
.logistic-modal__inputs {
  width: 100%;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}
.logistic-modal__inputs .input-group-text {
  min-width: 80px;
}
.logistic-modal__inputs .input-group > .f-control:not(:first-child) .react-numeric-input input {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  min-height: 42px;
  font-size: 16px;
}
.logistic-modal__inputs .Select-value {
  font-size: 16px;
}
.logistic-modal__inputs_fact-price {
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
}
.logistic-modal__inputs .input-price .react-numeric-input {
  max-width: 170px;
}
.logistic-modal__inputs .input-price input:focus + b, .logistic-modal__inputs .input-price input:focus + b + b {
  border-color: #4996FF;
}
.logistic-modal__inputs .input-price input:focus + b i, .logistic-modal__inputs .input-price input:focus + b + b i {
  border-bottom-color: #4996FF;
  border-top-color: #4996FF;
}
.logistic-modal__inputs .input-price input:focus + b:hover, .logistic-modal__inputs .input-price input:focus + b + b:hover {
  background-color: #4996FF;
}
.logistic-modal__inputs .input-price input:focus + b:hover i, .logistic-modal__inputs .input-price input:focus + b + b:hover i {
  border-bottom-color: white;
  border-top-color: white;
}
.logistic-modal__inputs .input-price input:focus {
  border-color: #4996FF;
}
.logistic-modal__inputs .input-price input:focus .f-element-number .react-numeric-input b {
  border-left: 1px solid #4996FF;
}
.logistic-modal__inputs .input-price input:focus .f-element-number .react-numeric-input b + b {
  border-top: 1px solid #4996FF;
}
@media screen and (min-width: 576px) {
  .logistic-modal__inputs .input-price input {
    min-width: 140px;
  }
}
.logistic-modal__inputs .input-price .input-group-text {
  -ms-flex-pack: start;
      justify-content: flex-start;
  padding-left: 0;
  padding-right: 10px;
  font-size: 16px;
  background-color: transparent;
  border: none;
}
@media screen and (min-width: 576px) {
  .logistic-modal__inputs .select-money {
    min-width: 100%;
    margin-left: 30px;
  }
}

.logistic-tab .tracking-header {
  margin-bottom: 20px;
}
.logistic-tab .tracking-header input:focus {
  border-color: #4996FF;
}
.logistic-tab .tracking-header span {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #333333;
}
.logistic-tab .btn-secondary {
  border-color: #4996FF !important;
  color: #4996FF;
  border-radius: 3px !important;
}
.logistic-tab .btn-secondary:hover {
  background-color: #4996FF !important;
  color: white !important;
}
.logistic-tab .btn-secondary .tracking-tab-btn {
  padding: 0 10px;
}

.tracking-modal-container .tracking-modal-row {
  display: -ms-flexbox;
  display: flex;
}
.tracking-modal-container .tracking-modal-row__icon-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
}
.tracking-modal-container .tracking-modal-row__icon-container .icon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.tracking-modal-container .tracking-modal-row__icon-container .icon.current {
  width: 32px;
  height: 32px;
  background-color: #4996FF;
}
.tracking-modal-container .tracking-modal-row__icon-container .icon.current .point {
  background-image: url("../../assets/img/orders/tracking/point-white.svg") !important;
}
.tracking-modal-container .tracking-modal-row__icon-container .icon.current .calendar {
  background-image: url("../../assets/img/orders/tracking/calendar-white.svg") !important;
}
.tracking-modal-container .tracking-modal-row__icon-container .icon.current .payment {
  background-image: url("../../assets/img/orders/tracking/payment-white.svg") !important;
}
.tracking-modal-container .tracking-modal-row__icon-container .icon .icon-data {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  position: absolute;
}
.tracking-modal-container .tracking-modal-row__icon-container .icon .icon-data .point {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/orders/tracking/point.svg");
}
.tracking-modal-container .tracking-modal-row__icon-container .icon .icon-data .point.active {
  background-image: url("../../assets/img/orders/tracking/point-active.svg");
}
.tracking-modal-container .tracking-modal-row__icon-container .icon .icon-data .calendar {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/orders/tracking/calendar.svg");
}
.tracking-modal-container .tracking-modal-row__icon-container .icon .icon-data .calendar.active {
  background-image: url("../../assets/img/orders/tracking/calendar-active.svg");
}
.tracking-modal-container .tracking-modal-row__icon-container .icon .icon-data .payment {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/orders/tracking/payment.svg");
}
.tracking-modal-container .tracking-modal-row__icon-container .icon .icon-data .payment.active {
  background-image: url("../../assets/img/orders/tracking/payment-active.svg");
}
.tracking-modal-container .tracking-modal-row__icon-container .bottom-line {
  width: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 20px;
      flex: 1 1 20px;
  border: 1px dashed #D3D8E0;
}
.tracking-modal-container .tracking-modal-row__icon-container .bottom-line.active {
  border: 1px dashed #62CFA8;
}
.tracking-modal-container .tracking-modal-row__title {
  font-size: 24px;
  line-height: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 100%;
      flex: 1 1 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  color: #333;
}
.tracking-modal-container .tracking-modal-row__item {
  -ms-flex: 1 1 100%;
      flex: 1 1 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: 13px;
  min-height: 72px;
  margin-bottom: 8px;
  padding: 0 0 17px;
}
.tracking-modal-container .tracking-modal-row__item .input-group {
  width: auto !important;
}
.tracking-modal-container .tracking-modal-row__item .tracking-item-date {
  display: block !important;
}
.tracking-modal-container .tracking-modal-row__item .tracking-item-date input {
  font-size: 14px !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAzCAYAAAAtg6DjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAANpJREFUeNrsmjEKwzAMRe3SPc5BMmbo/SGFdmsv0BvYPYGr0YW4sTfJfR8EIfxAHpaUP8Rvt4drUC6uZ6mkyXNZl/Tr5U/uDwQkkIZ0lgqdz0zaPNf78xAydkK+rHmYyZFm0jV8fNVKgsDufZnTTLsCCaRtyFCU1+SRBeN7t2tNsWEDa/PQrsOHgZq8Vk8tDNCuQAJJGCAMEAaYScIA7QokkEDqh8xFBU0eSTyBkyQMfGsurt9GPN2QSauHxAMkkIQBwgDtShggDNCueycZrQEc/XLGdh1JHwEGACeAcSh4UHmmAAAAAElFTkSuQmCC) no-repeat 100%;
  background-size: 14px 15px !important;
  background-position-x: 95%;
}
.tracking-modal-container .tracking-modal-row__item .tracking-item-date input {
  width: 100%;
  height: 25px !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 1px #3d464d;
  box-shadow: 0 0 1px #3d464d;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 0 10px;
}
.tracking-modal-container .tracking-modal-row__item .tracking-item-date.form-group {
  line-height: 30px !important;
  margin-bottom: 8px !important;
}
.tracking-modal-container .tracking-modal-row__item .card-item {
  margin-top: 26px;
  border: 1px solid #D3D8E0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 6px;
  color: #333;
  line-height: 42px;
  padding-bottom: 16px;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .tracking-modal-container .tracking-modal-row__item .card-item {
    padding-bottom: 20px;
  }
}
.tracking-modal-container .tracking-modal-row__item .card-item.current {
  background: rgba(73, 150, 255, 0.05);
  border: 1px solid rgba(73, 150, 255, 0.9);
}
.tracking-modal-container .tracking-modal-row__item .card-item.completed {
  background-color: rgba(98, 207, 168, 0.05);
  border: 1px solid rgba(98, 207, 168, 0.9);
}
.tracking-modal-container .tracking-modal-row__item .card-item .mobile-header {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  line-height: 14px;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
}
@media screen and (max-width: 768px) {
  .tracking-modal-container .tracking-modal-row__item .card-item {
    line-height: 24px;
  }
  .tracking-modal-container .tracking-modal-row__item .card-item .mobile-header {
    margin-top: 4px;
    line-height: 38px;
  }
}
.tracking-modal-container .tracking-modal-row__item .card-item input:focus {
  border-color: #4996FF;
}
@media screen and (max-width: 991px) {
  .tracking-modal-container .tracking-modal-row__item .card-item .col-12 {
    padding: 0 15px 0 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .tracking-modal-container .tracking-modal-row__item .card-item .col-12 {
    padding: 0 7.5px 0 7.5px !important;
  }
}
.tracking-modal-container .tracking-modal-row__item .card-item .actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: right;
      justify-content: right;
}
.tracking-modal-container .tracking-modal-row__item .card-item .actions .fa {
  line-height: 30px;
  font-size: 20px;
  color: #bdc0c9;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .tracking-modal-container .tracking-modal-row__item .card-item .actions .fa {
    line-height: 34px;
  }
}
.tracking-modal-container .tracking-modal-row__item .card-item .actions .fa:last-child {
  margin-left: 40px;
}
.tracking-modal-container .tracking-modal-row__item .card-item .actions .fa.fa-eraser.disabled {
  color: rgba(189, 192, 201, 0.5);
}
.tracking-modal-container .tracking-modal-row__item .card-item .header-date-columns {
  line-height: 24px;
  margin-top: 4px;
  text-align: center;
}
.tracking-modal-container .tracking-modal-row__item .card-item .no-status {
  margin-top: 32px;
}
@media screen and (max-width: 768px) {
  .tracking-modal-container .tracking-modal-row__item .card-item .no-status {
    margin-top: 20px;
  }
}
.tracking-modal-container .tracking-modal-row__item .card-item .status {
  display: none;
  margin-top: 12px !important;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 2px;
}
@media screen and (min-width: 991px) {
  .tracking-modal-container .tracking-modal-row__item .card-item .status {
    margin-left: 12px !important;
  }
}
.tracking-modal-container .tracking-modal-row__item .card-item .status.current {
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(73, 150, 255, 0.2);
  color: #4996FF;
}
.tracking-modal-container .tracking-modal-row__item .card-item .status.completed {
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(98, 207, 168, 0.2);
  color: #62CFA8;
}

.contacts-first-block {
  width: 75%;
  margin-top: 16px;
}
.contacts-first-block .input-group-text {
  min-width: 150px;
}

.contacts-second-block {
  width: 45%;
  margin-top: 16px;
}
.contacts-second-block .input-group-text {
  min-width: 150px;
}

.search-bar {
  height: 39px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 4px 4px 4px 26px;
  background-color: #fff;
}

.order-form-header {
  background-color: #1a25ab;
  padding: 6px 21px;
  border-radius: 6px 6px 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.order-form-title {
  display: -ms-flexbox;
  display: flex;
  font-size: 28px;
  color: #fff;
  margin-top: 10px;
  margin-right: 2vw;
}

.order-form-info-button {
  height: 50px;
  margin-top: 5px;
  width: 170px;
}

.order-form-cargo-reference textarea {
  resize: none;
  width: 350px;
  height: 50px;
  margin-top: -5px;
  margin-left: 2vw;
}

.new-doc-btn {
  color: #fff;
  background-color: #1a25ab;
  cursor: pointer;
  border-radius: 7px;
  padding: 0.4rem 0.75rem;
  border-color: transparent;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
}

.logistic-modal {
  max-width: 780px;
}

.manager-row {
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  border-bottom: 1px solid #c6cad2;
  padding: 6px;
}

.manager-row__edit-icon {
  font-size: 26px;
  cursor: pointer;
  z-index: 100;
}
.manager-row__edit-icon:hover {
  color: #1a25ab;
}

.bar {
  height: 10px;
  background-color: white;
  border-bottom: 1px solid #c6cad2;
}

.bar-progress {
  height: 10px;
  background-color: #1a25ab;
  border-radius: 4px 4px 0 0;
}

.bar-progreess-for-manager {
  height: 10px;
  background-color: #1a25ab;
}

.manage-all {
  font-size: 43px;
  color: #1a25ab;
  cursor: pointer;
  margin-left: 6px;
}

.logistic-block__header {
  height: 42px;
  padding: 6px 21px;
  background-color: #1a25ab;
  border-radius: 6px 6px 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 14px;
}

.logistic-block__title {
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  color: #fff;
  margin-right: 2vw;
}

.logistic-block__edit {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.logistic-block__to {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.logistic-block__expand {
  color: #fff;
  font-size: 36px;
  cursor: pointer;
}

.cities-header-block {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  height: 100px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 20px;
  margin-bottom: 10px;
}

.cities-text-block {
  background-color: #fff;
  padding: 20px;
}

.cities-text-block__small-container {
  height: 130px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.cities-text-block__big-container {
  height: 280px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.cities-text-block__link {
  color: blue;
  text-decoration: underline;
}

.tracking-tab__container {
  display: -ms-flexbox;
  display: flex;
}

.tracking-tab__block-date {
  -ms-flex-positive: 1;
      flex-grow: 1;
  margin-right: 40px;
}

.tracking-tab__block-info {
  -ms-flex-positive: 2;
      flex-grow: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.tracking-tab__block-docs {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 4px 8px;
}

.tracking-tab__block-driver {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 4px 8px;
}

.calculations-warning-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}

.alert-warning {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  background: rgba(248, 175, 36, 0.05);
  border: 1px solid #F8AF24;
  border-left-width: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  color: #333333;
}
@media screen and (max-width: 768px) {
  .alert-warning {
    margin-top: 10px;
  }
}
.alert-warning .d-contents {
  display: contents;
}
.alert-warning:has(.row) {
  display: block;
}
.alert-warning:has(.row) .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.alert-warning:has(button) {
  /*span{
    line-height: 38px;
  }*/
}
.alert-warning .close {
  background: url("../img/icon-alert-close.svg") no-repeat center transparent;
}
.alert-warning .warning {
  background: url("../img/icon-warning.svg") no-repeat center transparent;
  width: 24px;
  height: 24px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 30px;
      flex: 0 0 30px;
  margin-right: 18px;
}
.alert-warning button {
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .alert-warning button {
    margin-right: auto;
    margin-left: 47px;
  }
}
.alert-warning > button {
  -ms-flex: 0 0 30px;
      flex: 0 0 30px;
}

.error-container {
  background-color: white;
  border-radius: 8px;
  padding: 14px;
  width: 80%;
}

#root {
  height: 100%;
}

pre {
  font-family: PFDinDisplayPro-Regular;
  font-size: initial;
}

.pre-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.nowrap {
  white-space: nowrap;
}

@media screen and (min-width: 1199px) {
  .w-lg-auto {
    width: auto !important;
  }
  .wizard .container {
    padding: 0 !important;
  }
  .wizard__direction-block_location {
    min-height: 32px;
    padding-bottom: 4px;
  }
  .contact_us_block {
    margin-left: auto;
  }
  .phone-block {
    display: none;
  }
  /*.container {
  	padding:0;
  }*/
}
@media screen and (min-width: 1445px) {
  .container {
    padding: 0;
  }
}
@media screen and (max-width: 1199px) {
  .header .phone-link {
    display: none;
  }
  .header .phone-block {
    display: block;
  }
  .wizard__direction-block_info-hubs label {
    height: auto;
    padding-bottom: 0;
  }
  .wizard__direction-block_info-hubs label::after {
    content: none;
  }
  .footer__menu li a {
    font-size: 18px;
  }
  .footer__subscribe_title {
    font-size: 14px;
  }
}
@media screen and (max-width: 1100px) {
  .phone-block {
    margin-right: 18px;
  }
  .header__buttons {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .header__logo {
    margin-right: auto;
  }
  .header__contact-info, .header__buttons, .header__buttons_contact-us {
    display: none;
  }
  .wizard__direction-block_country_error {
    height: auto;
  }
  .wizard__direction-block_location {
    /*height: 90px;*/
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .wizard__direction-block_location .btn-group .btn.btn-secondary {
    width: 50% !important;
  }
  .wizard__direction-block_location .btn-group:nth-child(2) {
    margin-top: 8px;
  }
  .wizard__cargo-from hr {
    display: none;
  }
  .wizard__cargo-general-block .col-12 {
    margin: 5px 0;
  }
  .calculations-list .scroll-block {
    max-height: unset;
    min-height: unset;
    overflow-y: auto;
    margin-top: 28px;
    margin-bottom: 120px;
  }
  .calculations-list .scrollBtn {
    bottom: 0;
  }
  .calculations-list .scrollBtn__wrapper {
    right: auto;
    left: 45px;
    bottom: 63px;
  }
  .calculations-list .scrollBtn__bot {
    bottom: -35px;
  }
  .calculations-list__calculation_table_title {
    margin-bottom: 10px;
  }
  .calculations-list__calculation_table_title .cell-with-icon {
    font-size: 15px;
  }
  .calculations-list__order-info {
    margin-bottom: 28px;
  }
  .calculations-list.offsetScroll .calculations-list__filter,
  .calculations-list.offsetScroll .scroll-block {
    margin-right: 0;
  }
  .order-form-title {
    font-size: 23px;
    margin-top: 20px;
  }
  .order-card-header {
    display: block;
    padding-top: 20px;
  }
  .order-cargo-title {
    margin-top: -4px;
  }
  .order-main-info-comment-column, .order-main-info-comment-date {
    display: block;
  }
  .order-main-info-comment-date:last-child {
    margin-left: 8px;
  }
  .order-menu-block {
    margin: 24px 0 auto 18px;
  }
}
@media screen and (min-width: 767px) {
  .crud-modal .size-input .input-group {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .crud-modal .hatch-size-input .input-group {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .crud-modal .coordinate-input .input-group {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}
@media screen and (max-width: 767px) {
  .main-layout .header__buttons {
    -ms-flex-pack: end;
        justify-content: end;
  }
  .main-layout .header__buttons_contact-us button {
    height: 40px;
    padding: 0;
  }
  .main-layout .header__dropdown {
    margin-right: 0;
  }
  .main-layout .header__menu-btn {
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 0;
    background: url("../img/menu.png") no-repeat center transparent;
  }
  .main-layout .header__dropdown .arrow {
    display: none;
  }
  .main-layout .wizard__cargo-container-block .col-12 {
    margin: 5px 0;
  }
  .main-layout .calculations-list__calculations_loading {
    padding-bottom: 132px;
  }
  .main-layout .calculations-list__calculations {
    padding-bottom: 28px;
  }
  .main-layout .calculations-list__calculations_loading {
    padding-bottom: 132px;
  }
  .main-layout .calculations-list__calculation_path_location-block .location {
    font-size: 14px;
  }
  .main-layout .calculations-list__calculation_path_location-block .integrator {
    font-size: 12px;
    margin-left: 10px;
  }
  .main-layout .calculations-list__calculation_path_location-block .integrator.left-line {
    border-left: 1px solid #c6cad2;
  }
  .main-layout .calculations-list__calculation_price .price .cost {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .main-layout .calculations-list__calculation_price button.btn {
    margin-top: 12px !important;
  }
  .main-layout .calculations-list__empty_msg {
    padding: 0 10%;
  }
  .main-layout .reports .recharts-wrapper {
    width: 500px;
  }
  .main-layout .reports .recharts-wrapper .recharts-surface {
    width: 500px;
  }
  .main-layout .reports .recharts-wrapper .recharts-legend-wrapper {
    width: 500px !important;
  }
  .main-layout .reports .recharts-wrapper .recharts-tooltip-wrapper {
    -webkit-transform: translate(200px, 26px) !important;
        -ms-transform: translate(200px, 26px) !important;
            transform: translate(200px, 26px) !important;
  }
  .wizard__cargo-from .total-weight {
    font-size: 14px;
  }
  .wizard__cargo-from_header {
    padding: 0 0 0 8px;
  }
  .wizard__cargo-from_header_location_row {
    padding-left: 0;
  }
  .wizard__cargo-from .wizard__cargo-general-block {
    margin-top: 16px;
  }
  .wizard__cargo-from .wizard__cargo-general-block .btn.add-container-btn {
    margin-top: 0px;
  }
  .wizard__cargo-from .wizard__cargo-container-block {
    margin-top: 16px;
  }
  .wizard__cargo-from_header_title {
    margin-bottom: 10px;
  }
  .wizard__cargo-from_header_location {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary {
    margin-bottom: 0;
  }
  .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary:first-child {
    width: 40% !important;
  }
  .wizard__cargo-from_header_location_row .btn-group .btn.btn-secondary:last-child {
    width: 60% !important;
  }
  .wizard__cargo-from_header_location_row .btn-group:last-child {
    margin-top: 8px;
  }
  .wizard__direction-block_last {
    margin-top: 16px;
  }
  .wizard__direction-block_last .swap-button-block {
    margin-left: 38%;
    margin-top: -110px;
  }
  .wizard__direction-block_location .btn-group .btn.btn-secondary {
    width: 50% !important;
  }
  .wizard__direction-block_location .btn-group:nth-child(2) {
    margin-top: 8px;
  }
  .wizard_btn_calculate {
    display: none;
  }
  .alert-warning-wizard {
    padding-right: 25px;
  }
  body .form-group .input-group-text,
  .change-password .form-group .input-group-text {
    min-width: 100px;
    font-size: 12px;
  }
  .crud-modal {
    max-width: 500px;
  }
  .crud-list__inner_pagination {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .crud-list-mobile__inner_actions button {
    display: block;
    width: 100%;
    margin-bottom: 4px;
  }
  .crud-modal .size-input .input-group .f-group {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .crud-modal .hatch-size-input .input-group .f-group {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .crud-modal .f-element-text input.form-control {
    min-width: 200px;
  }
  .footer__logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-order: 1;
        order: 1;
    margin-top: 15px;
  }
  .footer__logo_img {
    margin-right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .main-layout .main {
    padding-left: 0 !important;
  }
  .main-layout .header {
    padding-left: 0 !important;
    height: auto;
    padding: 8px 5px;
  }
  .main-layout .header__logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
        justify-content: flex-start;
    margin-bottom: 0px;
  }
  .main-layout .header__buttons {
    margin-bottom: 0px;
  }
  .main-layout .header__dropdown {
    max-width: 45px;
    height: 45px;
    margin-bottom: 0px;
  }
  .main-layout .header__menu-btn {
    height: 45px;
    margin-bottom: 0px;
  }
  .main-layout .footer {
    padding-left: 0 !important;
  }
  .main-layout .left-menu.hidden .left-menu_nav_btn_toggle {
    display: none;
  }
  .main-layout .left-menu_nav {
    width: 0;
  }
  .main-layout .wizard__direction-block_location {
    color: red;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .main-layout .wizard__cargo-from_header_summary-cargo-info {
    display: none;
  }
  .main-layout .wizard__cargo-from_location {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .main-layout .wizard__cargo-from .total-weight {
    -ms-flex-pack: left;
        justify-content: left;
  }
  .main-layout .wizard__cargo-from_summary_info_mobile {
    display: -ms-flexbox;
    display: flex;
  }
  .main-layout .reports .recharts-wrapper {
    width: 300px;
  }
  .main-layout .reports .recharts-wrapper .recharts-surface {
    width: 300px;
  }
  .main-layout .reports .recharts-wrapper .recharts-legend-wrapper {
    width: 300px !important;
  }
  .main-layout .reports .recharts-wrapper .recharts-tooltip-wrapper {
    -webkit-transform: translate(50px, 26px) !important;
        -ms-transform: translate(50px, 26px) !important;
            transform: translate(50px, 26px) !important;
  }
  .calculations-list__calculations_loading {
    padding-bottom: 174px;
  }
  .crud-modal .container-list .container-unit {
    margin-bottom: 10px;
    border-bottom: 1px solid #c6cad2;
  }
  .crud-modal .container-list .container-unit div[class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
  .crud-modal .input-group-prepend {
    width: 100%;
  }
  .crud-modal .input-group-prepend .input-group-text {
    background-color: transparent;
    border: none;
    padding: 0;
    -ms-flex-pack: left;
        justify-content: left;
    color: rgba(145, 152, 167, 0.5);
    font-size: 13px;
    margin-bottom: 5px;
  }
  .commission__list {
    margin-bottom: 10px;
    border-bottom: 1px solid #c6cad2;
  }
  .commission__integrator-content .input-group-prepend {
    width: 100%;
  }
  .commission__integrator-content .input-group-prepend .input-group-text {
    background-color: transparent;
    border: none;
    padding: 0;
    -ms-flex-pack: left;
        justify-content: left;
    color: rgba(145, 152, 167, 0.5);
    font-size: 13px;
    margin-bottom: 5px;
  }
  body .pane.pane-bg {
    width: 290px;
  }
  body .pane.pane-hg {
    width: 320px;
  }
  body .pane.password-recovery-wr {
    width: auto;
  }
  .header__buttons_language-switcher {
    display: none;
  }
  .header__dropdown {
    padding-right: 0;
  }
  .phone-block {
    max-width: 45px;
    display: block;
  }
}
@media screen and (min-width: 575px) {
  .phone-block {
    display: none;
  }
}
@media screen and (max-width: 380px) {
  body .pane.pane-hg {
    width: 270px;
  }
  .registerModal .input-group > .f-control {
    width: 1%;
  }
}
@media screen and (min-width: 992px) {
  .wizard__cargo-general-block .summary {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .order-main-info-mobile {
    display: block !important;
  }
  .calculations-list.offsetScroll .scroll-block {
    max-height: unset !important;
  }
  .calculations-list .scroll-block {
    max-height: unset !important;
    max-height: unset !important;
    overflow-y: hidden;
  }
  .order-doc-mobile, .order-docs-list-mobile {
    display: block !important;
  }
  .order-doc-table {
    display: none;
  }
  .order-docs-list-mobile tr td:last-child, .order-docs-list-mobile tr th:last-child {
    width: 100%;
  }
  .calculations-list__order-info_body_cargo p {
    display: block;
  }
  .header__buttons_language-switcher_select {
    margin-bottom: 0px;
  }
  .wizard .container {
    padding: 0;
  }
  .wizard .card {
    padding: 0 15px;
  }
  .wizard .card .col-6, .wizard .card .col-9 {
    padding-left: 0;
  }
  .wizard .toggleDropdown {
    padding-left: 0;
  }
  .wizard .toggleDropdown .col-12, .wizard .toggleDropdown .col-6 {
    padding: 0;
  }
  .wizard__direction-block.first {
    margin-right: 0;
    padding: 18px 0;
  }
  .wizard__direction-block_last {
    margin-left: 0;
    padding-left: 0 !important;
  }
  .wizard__direction-block {
    padding: 18px 0;
  }
  .wizard__cargo-general-block .summary {
    border: 1px solid #F3F3F3;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 3px;
  }
  .wizard__cargo-general-block .summaryLg {
    display: none;
  }
  .wizard__cargo-vehicle-block {
    padding: 0 15px 0 23px;
  }
  .wizard__cargo-container-block {
    margin-left: 15px;
  }
  .wizard__cargo-container-block #btnContainer0 {
    margin-left: 0;
  }
  .wizard__cargo-container-block .btn-container-box {
    width: 69px;
  }
  .wizard__cargo-container-block .col-8 {
    padding: 0;
  }
  .wizard__cargo-container-block .container .row .col-12 {
    padding-right: 4px;
  }
  .wizard__cargo-container-block .container .row .col-12 .btn.add-container-btn {
    margin-left: 0;
  }
  .wizard__cargo-from .col-12 {
    padding: 0 !important;
  }
  .wizard__cargo-from_header {
    padding-left: 24px !important;
  }
  .wizard__cargo-from_header_location.col-12 {
    padding-left: 24px !important;
    padding-right: 8px !important;
  }
  .wizard__cargo-from_header_location_row {
    padding: 0 15px;
  }
  .wizard__cargo-from_header_summary {
    display: -ms-flexbox;
    display: flex;
    margin-top: 24px;
  }
  .wizard__cargo-from_header_summary .btn-collapse-cargo {
    margin-left: 12px;
  }
  .wizard__cargo-from_header_summary-cargo-info {
    display: none;
  }
  .wizard__cargo-from_header_title, .wizard__cargo-from_header_button {
    padding-top: 15px;
  }
  .wizard__cargo-from_header.summaryLg {
    display: none;
  }
  .wizard__cargo-from_summary_info_mobile {
    display: -ms-flexbox;
    display: flex;
  }
  .wizard .btn-reset {
    margin-left: 0;
  }
}
/* */
.header__dropdown_text-item.pale {
  color: #8D98A6;
}
.header__dropdown_text-item.user-role {
  margin-bottom: 18px;
}
.header__dropdown_text-item.user-info-item {
  text-align: left;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 0;
}
.header__dropdown_text-item.user-info-item-first {
  margin-top: 18px;
}
.header__dropdown_text-item.user-info-item-last {
  margin-bottom: 18px;
}

.header__dropdown_text-title.user-name {
  color: #333;
  margin-top: 18px;
  margin-bottom: -5px;
}
.header__dropdown_text-title.user-info-item {
  text-align: left;
  padding-left: 32px;
  padding-right: 32px;
}

.account-btns {
  margin: 18px 32px;
}
.account-btns button {
  padding-left: 0;
}
.account-btns * {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
}
.account-btns .fa {
  font-size: 18px;
  font-weight: normal;
}

.user-edit-logo, .user-logout-logo {
  display: inline-block;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-bottom: -4px;
}

.user-edit-logo {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/userEditLogo.svg");
}
.user-edit-logo.color-yellow {
  background-image: url("../img/icons/yellow/userEditLogo.svg");
}

.user-logout-logo {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../img/icons/blue/userLogoutLogo.svg");
}
.user-logout-logo.color-yellow {
  background-image: url("../img/icons/yellow/userLogoutLogo.svg");
}

.cargo-unit-select {
  width: 75px;
}

.header.d-flex.align-items-center .container .row {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.phone-link {
  margin-right: 18px;
  color: #1a25ab;
  text-decoration: none;
}
.phone-link.d-sm-none {
  margin-right: 2px;
}
.phone-link:hover {
  text-decoration: none;
}

.order-sm-0-without-padding {
  padding: 0;
}

.phone-link-icon {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: url("../img/phone-icon.svg");
  margin-top: 6px;
}

.phone-link-icon.white-icon {
  width: 24px;
  height: 24px;
  margin-right: 13px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: url("../img/phone-icon-white.svg");
  margin-top: 6px;
}

@media screen and (min-width: 576px) {
  .phone-block {
    max-width: 45px;
  }
  .phone-link {
    margin-right: 18px;
  }
  .phone-link-icon.white-icon {
    margin-right: 5px;
  }
}
/* === PRELOADER ==== */
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 50%;
  -webkit-animation: lds-default 1.2s linear infinite;
          animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@-webkit-keyframes lds-default {
  0%, 20%, 80%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes lds-default {
  0%, 20%, 80%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
.modal__no-shadow {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.indicator {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  right: 16px;
  top: 16px;
  /*text-transform: uppercase;*/
  /*&__red {
    background: #D00000;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 2px #600000;
  }*/
  /*&__yellow {
    background: #ffc107;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #ad8305;
  }*/
  /*&__green {
    background: #00D000;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #006000;
  }*/
  /* &__gray {
     background: #D0D0D0;
     background-size: 5px 5px;
     width: 24px;
     height: 24px;
     border-radius: 50%;
     border: solid 1px #606060;
   }*/
}
@media screen and (min-width: 900px) {
  .indicator {
    position: absolute;
  }
}
.indicator__item {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  padding: 0.5em;
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0.03em;
  margin-left: 15px;
}
@media screen and (max-width: 900px) {
  .indicator__item:first-child {
    margin-left: 0 !important;
  }
}
.indicator__item:last-child {
  margin-right: 0;
}
.indicator__description {
  /* padding-right: 1em;*/
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
}
.indicator--transparent {
  display: none;
}
.indicator__transparent {
  display: none;
}
.indicator--red {
  background-color: rgba(245, 84, 29, 0.2);
  color: #F5541D;
}
.indicator--yellow {
  background-color: rgba(248, 175, 36, 0.2);
  color: #F8AF24;
}
.indicator--green {
  background-color: rgba(98, 207, 168, 0.2);
  color: #62CFA8;
}
.indicator--gray {
  background-color: rgba(208, 208, 208, 0.2);
  color: #D0D0D0;
}

.agora-actions-btn .status-label {
  padding: 0.5em;
  line-height: 1;
  font-size: 95%;
  border-radius: 7px;
}
@media (max-width: 768px) {
  .agora-actions-btn .status-label {
    padding: 1em;
  }
}

/*.indicator {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 16px;
  top: 16px;
  padding: .5em;
  border-radius:.5em;

  &__item {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }

  &__description {
    padding-right: 1em;
    font-size: 80%;
    font-weight: bold;
  }

  &--transparent {
    display: none;
  }
  &__transparent {
    display: none;
  }

  &--red {
    color: #D00000;
  }
  &__red {
    background: #D00000;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 2px #600000;
  }

  &--yellow {
    color: #ffc107;
  }
  &__yellow {
    background: #ffc107;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #ad8305;
  }

  &--green {
    color: #00D000;
  }
  &__green {
    background: #00D000;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #006000;
  }

  &--gray {
    color: #D0D0D0;
  }
  &__gray {
    background: #D0D0D0;
    background-size: 5px 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #606060;
  }

}*/
.agora-actions-btn .status-label {
  padding: 0.5em;
  line-height: 1;
  font-size: 95%;
  border-radius: 7px;
}
@media (max-width: 768px) {
  .agora-actions-btn .status-label {
    padding: 1em;
  }
}

.crud-import-translates-btn {
  cursor: pointer;
  color: rgba(51, 51, 51, 0.9);
}
.crud-import-translates-btn .fa-16px {
  font-size: 20px;
}
.crud-import-translates-btn .text-btn {
  line-height: 20px;
  text-align: center;
  margin-left: 10px;
}

.crud-import-translates-btn:hover {
  color: #333;
}

.price-with-tooltip:hover .react-tooltip {
  visibility: visible;
}
.price-with-tooltip:has(.react-tooltip) {
  cursor: pointer;
}
.price-with-tooltip .react-tooltip {
  padding: 4px 8px;
  opacity: 1;
  z-index: 999;
  font-size: 14px;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
@media screen and (max-width: 576px) {
  .price-with-tooltip .react-tooltip {
    left: 10px !important;
    top: -30px !important;
  }
  .price-with-tooltip .react-tooltip .react-tooltip-arrow {
    background: transparent;
    border: none !important;
  }
}
.price-with-tooltip .react-tooltip .react-tooltip-arrow {
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

.fix-arrow {
  border-radius: 0.2rem;
}
@media screen and (max-width: 576px) {
  .fix-arrow.no-mobile {
    display: none !important;
  }
}
.fix-arrow .arrow {
  -webkit-transform: translate(0px, 0px) !important;
      -ms-transform: translate(0px, 0px) !important;
          transform: translate(0px, 0px) !important;
}
.fix-arrow .popover-body {
  padding: 0.2rem 0.4rem !important;
}

.tooltip-mobile .tooltip-btn-order {
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  margin-top: calc(0.5rem + 1px);
}
.tooltip-mobile .tooltip-btn-order .outer {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
}
.tooltip-mobile .tooltip-btn-order .inner .body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.tooltip-mobile .tooltip-btn-order .arrow {
  top: calc(-0.5rem - 1px);
}
.tooltip-mobile .tooltip-btn-order .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.tooltip-mobile .tooltip-btn-order .arrow:after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.input-42px input {
  height: 42px !important;
}

.registerModal .alert {
  padding-left: 20px;
}
@media screen and (min-width: 576px) {
  .registerModal .alert {
    padding-left: 40px;
  }
}
.registerModal .pane.pane-primary {
  margin: 35px auto 20px;
}

.modal-maxwidth-990px {
  max-width: 1100px !important;
}

.manual-messages .g-flash-messages {
  position: fixed;
  z-index: 1501;
  top: 8px;
  min-width: 300px;
  right: 25px;
}

.readonly-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
