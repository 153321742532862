.f-element-date.no-border input {
  border: none !important;
}
.f-element-date.error {
  background: rgba(255, 119, 119, 0.2);
}
.f-element-date .react-datepicker-wrapper,
.f-element-date .react-datepicker__input-container {
  width: 100%;
}
.f-element-date .react-datepicker-wrapper input,
.f-element-date .react-datepicker__input-container input {
  font-size: 14px;
  background: url("../../img/icons/date.png") no-repeat center right;
  background-size: 20px 20px;
  background-position-x: 91%;
}
.f-element-date .react-datepicker__current-month {
  text-transform: capitalize;
}
.f-element-date input {
  width: 100%;
  height: 42px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 0 1px #3d464d;
          box-shadow: 0 0 1px #3d464d;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 0 10px;
}
.f-element-date input[disabled] {
  background-color: #f9f9f9;
}
.f-element-date.fullWidth {
  width: 100%;
}
.f-element-date.fullWidth .input-group > div {
  width: 100%;
}

.data-range-picker {
  min-width: 250px;
}
.data-range-picker .DateInput__small {
  width: 80px;
}

.data-range-picker-zh {
  min-width: 280px;
}
.data-range-picker-zh .DateInput__small {
  width: 80px;
  min-width: 110px;
}
