.f-element-address .geosuggest {
  font-size: 14px;
  position: relative;
  width: 100%;
  text-align: left;
}
.f-element-address .geosuggest__input {
  width: 100%;
  height: 48px;
  padding: 0.5rem 0.75rem;
  border: 1px solid rgba(170, 170, 170, 0.8);
  -webkit-transition: border 0.2s, -webkit-box-shadow 0.2s;
  transition: border 0.2s, -webkit-box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 0.25rem;
}
.f-element-address .geosuggest__input:focus {
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
  -webkit-box-shadow: 0 0 0 transparent;
          box-shadow: 0 0 0 transparent;
}
.f-element-address .geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 150px;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  -webkit-box-shadow: 0px 6px 32px 0 rgba(0, 5, 68, 0.25);
          box-shadow: 0px 6px 32px 0 rgba(0, 5, 68, 0.25);
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}
.f-element-address .geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}
.f-element-address .geosuggest__suggests--hidden .geosuggest-suggestions {
  display: none;
}
.f-element-address .geosuggest__item, .f-element-address .geosuggest-item {
  font-size: 14px;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.f-element-address .geosuggest__item:hover, .f-element-address .geosuggest__item:focus, .f-element-address .geosuggest-item:hover, .f-element-address .geosuggest-item:focus {
  background: #f5f5f5;
}
.f-element-address .geosuggest__item--active, .f-element-address .geosuggest-item--active {
  background: #267dc0;
  color: #fff;
}
.f-element-address .geosuggest__item--active:hover, .f-element-address .geosuggest__item--active:focus, .f-element-address .geosuggest-item--active:hover, .f-element-address .geosuggest-item--active:focus {
  background: #ccc;
}
.f-element-address .geosuggest__item__matched-text, .f-element-address .geosuggest-item__matched-text {
  font-weight: bold;
}
.f-element-address .geosuggest-suggestions .geosuggest-label {
  display: none;
}
.f-element-address.bg .geosuggest__input {
  padding: 0.6rem 1rem;
}
