.f-element-number {
  -ms-flex-direction: column;
      flex-direction: column;
}
.f-element-number.bg .react-numeric-input input {
  padding: 0.6rem 1rem;
}
.f-element-number .react-numeric-input {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.f-element-number .react-numeric-input input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #c6cad2;
  -webkit-transition: border 0.2s, -webkit-box-shadow 0.2s;
  transition: border 0.2s, -webkit-box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.f-element-number .react-numeric-input input:focus {
  border-color: #3AB0D8;
  border-color: var(--primary-color, #3AB0D8);
}
.f-element-number .react-numeric-input input[disabled] {
  color: #333 !important;
  background-color: #f9f9f9;
}
.f-element-number .react-numeric-input input:focus + b, .f-element-number .react-numeric-input input:focus + b + b {
  border-left-color: #3AB0D8;
  border-left-color: var(--primary-color, #3AB0D8);
  border-bottom-color: #3AB0D8;
  border-bottom-color: var(--primary-color, #3AB0D8);
  border-top-color: #3AB0D8;
  border-top-color: var(--primary-color, #3AB0D8);
}
.f-element-number .react-numeric-input input:focus + b:hover, .f-element-number .react-numeric-input input:focus + b + b:hover {
  background-color: #3AB0D8;
  background-color: var(--primary-color, #3AB0D8);
}
.f-element-number .react-numeric-input input:focus + b:hover i, .f-element-number .react-numeric-input input:focus + b + b:hover i {
  border-bottom-color: white;
  border-top-color: white;
}
.f-element-number .react-numeric-input input:focus + b i {
  border-bottom-color: #3AB0D8;
  border-bottom-color: var(--primary-color, #3AB0D8);
}
.f-element-number .react-numeric-input input:focus + b + b i {
  border-top-color: #3AB0D8;
  border-top-color: var(--primary-color, #3AB0D8);
}
.f-element-number .react-numeric-input input:focus + b {
  border-bottom-color: #3AB0D8;
  border-bottom-color: var(--primary-color, #3AB0D8);
}
.f-element-number .react-numeric-input b {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 38px;
  height: 50%;
  cursor: pointer;
  border-left: 1px solid #c6cad2;
  background-color: #fff;
  border-top-right-radius: 3px;
}
.f-element-number .react-numeric-input b i {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -3px;
  margin-left: -5px;
  border-bottom: 6px solid #c6cad2;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  -webkit-transition: border-color 0.2s 0.02s;
  transition: border-color 0.2s 0.02s;
}
.f-element-number .react-numeric-input b + b {
  top: auto;
  bottom: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-top: 1px solid #c6cad2;
}
.f-element-number .react-numeric-input b + b i {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -3px;
  margin-left: -5px;
  border-top: 6px solid #c6cad2;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: none;
}
.f-element-number.react-numeric-input-no-hidden .react-numeric-input {
  overflow: unset;
}
.f-element-number.hide-spinner .react-numeric-input b {
  display: none;
}

.input-group > .f-control:not(:first-child) .react-numeric-input input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.f-element-number .f-control {
  margin-bottom: 0;
  max-height: 42px;
}
