.preloader {
  position: absolute;
  z-index: 20;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.preloader__modal {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.preloader__modal .modal-content {
  border: 0;
}
.preloader.top {
  -ms-flex-align: start;
      align-items: flex-start;
}
.preloader.fixed {
  position: fixed;
  z-index: 99;
}
.preloader.no-paddings {
  left: 15px;
  width: calc(100% - 30px);
}
.preloader .preloader-inner {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  height: 80%;
  max-height: 90px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 24px;
  text-align: center;
}
